/* eslint-disable*/
import { Button, Checkbox, Form, Menu } from "antd";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { FaEye } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { updateVisibleColumns } from "../../../../../../app/features/Lookups/lookup.slice";
import {
  getAssetsByProject
} from "../../../../../../app/features/projectDetails/projectDetail.slice";
import DataTable from "../../../../../../components/Layout/Table/DataTable";
// import { ASSETS_PAGE_SIZE } from "../../../../../../utils/helpers";
import PATH from "../../../../../../utils/path";
// import {updateVisibleColumns,} from "../../../../../../app/features/Lookups/lookup.slice";
import { ASSETS_PAGE_SIZE, PROJECTS_PAGE_SIZE } from "../../../../../../utils/helpers";
import { ImCross } from "react-icons/im";
import { FaCheck } from "react-icons/fa";
import { AssetDto } from "reia-rest-client";
import { RiFileExcel2Line } from "react-icons/ri";
import { exportExcelFileAssets } from "../../../../../../app/features/settings/settings.slice";

export default function AssetsTable({
  // sethideTableColumns,
  activeKey,
  searchAsset,
  projectDetails,
  columns, setColumns,
  // tableColumns,
  // hideableColumns,
  editingKey,
  assetsHideColumns,
  EditableCell,
  // EditableRow,
  // notifyToaster,
  hideFooter,
  selectedRowKeys,
  setSelectedRowKeys,
  // importExcelFileAssetsLoading,
  // exportExcelFileAssetsLoading,
  projectDetailFilter,
  setProjectDetailFilter,
  form,
  mergedColumns,
  assets
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const param = useParams();
  const ProjectId = param.projectId;
  const PortfolioId = param.portfolioId;
  const assignedUserId = param.userId;

  // const [moduleIdValue, setModuleIdValue] = useState("");
  // const [moduleTabIdValue, setModuleTabIdValue] = useState("");
  const {
    // assetsByProject: assets,
    isLoading,
    projectDetailFilters,
  } = projectDetails;

  const pageNumbers =
    projectDetailFilter.pagination.page * projectDetailFilter.pagination.size -
    projectDetailFilter.pagination.size +
    1;

  const nextPageNumber =
    projectDetailFilter.pagination.page * projectDetailFilter.pagination.size >
      assets?.totalElements
      ? assets?.totalElements
      : projectDetailFilter.pagination.page *
      projectDetailFilter.pagination.size;

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  // const [form] = Form.useForm();
  // const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [addUpdateUserModal, setAddUpdateUserModal] = useState(false);
  const [addMergeUserModal, setAddMergeUserModal] = useState(false);
  const [deleteUserModal, setDeleteUserModal] = useState(false);
  const [toggle, setToggle] = useState([]);
  const [moduleIdValue, setModuleIdValue] = useState("");
  const [moduleTabIdValue, setModuleTabIdValue] = useState("");
  // const {assetsByProject: assets, isLoading, projectDetailFilters } = projectDetails
  const { importExcelFileAssetsLoading, exportExcelFileAssetsLoading } = useSelector(state => state.settings)
  const [currentPage, setCurrentPage] = useState(1)
  const [currentPageSize, setCurrentPageSize] = useState(ASSETS_PAGE_SIZE)

  // const pageNumbers =
  //     currentPage * currentPageSize - currentPageSize + 1;

  // const nextPageNumber =
  //     currentPage * currentPageSize > assets?.totalElements ? assets?.totalElements
  //         : currentPage * currentPageSize

  // const onSelectChange = (newSelectedRowKeys) => {
  //     setSelectedRowKeys(newSelectedRowKeys);
  // };
  const closeAddUpdateUserModal = () => {
    setAddUpdateUserModal(false);
  };
  const closeAddMergeUserModal = () => {
    setAddMergeUserModal(false);
  };
  const closeDeleteUserModal = () => {
    setDeleteUserModal(false);
  };

  // const rowSelection = {
  //     selectedRowKeys,
  //     onChange: onSelectChange,
  // };

  const moveToNext = () => {
    setEditingKey("");
    loadAssets();
  };
  const moveToNext2 = () => {
    setEditingKey("");
    let hideBtn = document.querySelectorAll(".hide-btn")[0];
    let hideBtn2 = document.querySelectorAll(".hide-btn2")[0];
    hideBtn.disabled = false;
    hideBtn2.disabled = false;
    loadAssets();
  };

  // const moveToNext2 = () => {
  //   let hideBtn = document.querySelectorAll(".hide-btn")[0];
  //   let hideBtn2 = document.querySelectorAll(".hide-btn2")[0];
  //   const finalData = {
  //     projectId: +ProjectId,
  //     portfolioId: PortfolioId,
  //     page: projectDetailFilter?.pagination?.page,
  //     size: projectDetailFilter?.pagination.size,
  //     search: searchAsset?.search || "",
  //   };
  //   hideBtn.disabled = false;
  //   hideBtn2.disabled = false;
  //   dispatch(getAssetsByProject(finalData));
  // };

  // const MyCheckbox = ({ value, name, isChecked, handleChange }) => {
  //   return (
  //     <Checkbox
  //       value={value}
  //       name={name}
  //       checked={isChecked}
  //       onChange={handleChange}
  //     >
  //       {name}
  //     </Checkbox>
  //   );
  // };

  const loadAssets = (projectDetailFilter, searchAsset) => {
    const finalData = {
      projectId: +ProjectId,
      portfolioId: PortfolioId,
      assignedToUserId: assignedUserId !== null ? assignedUserId : null,
      page: projectDetailFilter?.pagination?.page,
      size: projectDetailFilter?.pagination.size,
      search: searchAsset?.search || "",
      assetId: projectDetailFilters?.assetId
        ? projectDetailFilters.assetId
        : searchAsset?.assetId || "",
      countryId: projectDetailFilters?.countryId
        ? projectDetailFilters.countryId
        : searchAsset?.countryId || "",
      stateId: projectDetailFilters?.stateId
        ? projectDetailFilters.stateId
        : searchAsset?.stateId || "",
      cityId: projectDetailFilters?.cityId
        ? projectDetailFilters.cityId
        : searchAsset?.cityId || "",
      street: projectDetailFilters?.street
        ? projectDetailFilters.street
        : searchAsset?.street || "",
      riskClassId: projectDetailFilters?.riskClassId
        ? projectDetailFilters.riskClassId
        : searchAsset?.riskClassId || "",
      upsidePotentialId: projectDetailFilters?.upsidePotentialId
        ? projectDetailFilters.upsidePotentialId
        : searchAsset?.upsidePotentialId || "",
      capexRequirementId: projectDetailFilters?.capexRequirementId
        ? projectDetailFilters.capexRequirementId
        : searchAsset?.capexRequirementId || "",
      expectedLiquidityId: projectDetailFilters?.expectedLiquidityId
        ? projectDetailFilters.expectedLiquidityId
        : searchAsset?.expectedLiquidityId || "",
      managementRequirementId: projectDetailFilters?.managementRequirementId
        ? projectDetailFilters.managementRequirementId
        : searchAsset?.managementRequirementId || "",
      ownershipId: projectDetailFilters?.ownershipId
        ? projectDetailFilters.ownershipId
        : searchAsset?.ownershipId || "",
      inspectionTypeId: projectDetailFilters?.inspectionTypeId
        ? projectDetailFilters.inspectionTypeId
        : searchAsset?.inspectionTypeId || "",
      selection: projectDetailFilters?.selection
        ? projectDetailFilters.selection
        : searchAsset?.selection || "",
    };
    setProjectDetailFilter(projectDetailFilter);
    dispatch(getAssetsByProject({ finalData }));
  };

  const changePath = (record) => {
    if (PATH.PROJECT_DETAILS.replace(":projectId", ProjectId) === location.pathname) {
      return navigate(`/projects/${ProjectId}/project-detail/${record.id}/asset-detail`);
    }
    else if (PATH.PORTFOLIO_DETAILS.replace(":projectId", ProjectId).replace(":portfolioId", PortfolioId) === location.pathname) {
      return navigate(`/projects/${ProjectId}/project-detail/${PortfolioId}/portfolio-detail/${record.id}/asset-detail`);
    }
    else {
      return navigate(`/user-management/${param.userId}/user-details/${ProjectId}/project-detail/${record.id}/asset-detail`);
    }
  };

  const navigateRow = (record) => {
    // const path = record; // to avoid es list error
    // console.log(record, 'navigaterow')
    return {
      onClick: () => {
        editingKey === "" && changePath(record); // to avoid es lint error
      }, // click row
    };
  };

  useEffect(() => {
    if (assetsHideColumns) {
      const columnsToKeep = assetsHideColumns
        ?.filter((item) => item.isChecked)
        .map((item) => item.value);
      const filteredArray = columns?.filter((column) => {
        const dataIndexArray = Array.isArray(column.dataIndex)
          ? column.dataIndex
          : [column.dataIndex];
        const shouldKeepColumn = !dataIndexArray?.some((dataIndex) =>
          columnsToKeep?.includes(dataIndex)
        );
        return shouldKeepColumn;
      });
      setColumns(filteredArray);
    }
  }, []);

  const formData = assets?.content ? assets.content : [];
  return (
    <div>
      <div className="basic-data">
        <Form form={form} component={false}>
          <DataTable
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            rowClassName={() => "editable-row"}
            columns={mergedColumns}
            dataSource={formData}
            pagination={{
              current: currentPage,
              pageSize: currentPageSize,
              total: assets?.totalElements,
              showSizeChanger: false,
              disabled: false,
              onChange: (page, pageSize) => {
                  setCurrentPage(page);
                  setCurrentPageSize(pageSize);
              },
          }}
            className="antd-table-striped"
            rowSelection={hideFooter ? null : rowSelection}
            loading={
              isLoading ||
              importExcelFileAssetsLoading ||
              exportExcelFileAssetsLoading
            }
            rowKey={(record) => record.id}
            onRow={navigateRow}
            scroll={{
              x: "800px",
              y: "calc(100vh - 406px)",
            }}
          />
        </Form>
      </div>
      {assets?.content?.length > 0 && (
        <>
          <div className="table-count">
            <h5 className="table-count-content">
              Showing {pageNumbers} to {nextPageNumber} of{" "}
              {assets?.totalElements} entries
            </h5>
          </div>
        </>
      )}
    </div>
  );
}

AssetsTable.propTypes = {
  sethideTableColumns: PropTypes.node.isRequired,
  activeKey: PropTypes.node.isRequired,
  searchAsset: PropTypes.node.isRequired,
  closeModal: PropTypes.node.isRequired,
  projectDetails: PropTypes.object.isRequired,
  tableColumns: PropTypes.array.isRequired,
  hideableColumns: PropTypes.array.isRequired,
  EditableCell: PropTypes.func.isRequired,
  notifyToaster: PropTypes.func.isRequired,
  editingKey: PropTypes.string.isRequired,
  isEditing: PropTypes.func.isRequired,
  prepareEditValues: PropTypes.func.isRequired,
  prepareUpdatedAsset: PropTypes.func.isRequired,
};
