import { Card, Col, Row, Switch, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import IMAGES from "../../../../../../../../assets/images";
import { getFormattedDecimalNumber, getFormattedEnglishNumber } from "../../../../../../../../utils/digitHelper";
import { getPerSpaceValuePortfolio, getPortfolioAccCosts, getPortfolioAssetValue, getPortfolioYieldProfileAtSaleCalculations, getPortfolioYieldProfileCalculations } from "../../../../../../../../utils/portfolioHelper";

function VeAssetValues() {
  const dispatch = useDispatch();
  const [isSwitch, setIsSwitch] = useState(false);
  const [accValues, setAccValues] = useState(null);
  const [oldAccValues, setOldAccValues] = useState(null);
  const { portfolio, calculationDetailsByPortfolio, isLoading, isSuccess, calculationModified, dcfResultsModified, portfolioValueMismatched, autoSaveCalc, portfolioDCFParams } = useSelector((state) => state.portfolio);
  const { discountRate, exitCapRate } = portfolioDCFParams;
  const assetValue = getPortfolioAssetValue(calculationDetailsByPortfolio?.calculationDetailDtos, dcfResultsModified);
  const oldAssetValue = getPortfolioAssetValue(calculationDetailsByPortfolio?.calculationDetailDtos);
  const terminalValue = getPortfolioAssetValue(calculationDetailsByPortfolio?.calculationDetailDtos, dcfResultsModified, true);
  const oldTerminalValue = getPortfolioAssetValue(calculationDetailsByPortfolio?.calculationDetailDtos, true);

  const unsavedValues = !autoSaveCalc && portfolioValueMismatched;

  const switchChange = (checked) => {
    setIsSwitch(checked);
  };

  useEffect(() => {
    const { grossValue, netValue, landTransferValue, agentCostsValue, notaryCostsValue, totalAcquisitionCostsValue } = getPortfolioAccCosts(calculationDetailsByPortfolio?.calculationDetailDtos, dcfResultsModified, isSwitch)
    const grossCapitalValue = getPerSpaceValuePortfolio(grossValue, calculationDetailsByPortfolio?.calculationDetailDtos)
    const landTransferTax = landTransferValue / netValue * 100;
    const agentCosts_Sale = agentCostsValue / netValue * 100;
    const notaryCosts = notaryCostsValue / netValue * 100;
    setAccValues({
      grossValue, netValue, grossCapitalValue, landTransferValue, landTransferTax, agentCosts_Sale, agentCostsValue, notaryCosts, notaryCostsValue, totalAcquisitionCostsValue
    })

  }, [calculationDetailsByPortfolio, dcfResultsModified, isSwitch])

  useEffect(() => {
    const { grossValue, netValue, landTransferValue, agentCostsValue, notaryCostsValue, totalAcquisitionCostsValue } = getPortfolioAccCosts(calculationDetailsByPortfolio?.calculationDetailDtos, isSwitch)
    const grossCapitalValue = getPerSpaceValuePortfolio(grossValue, calculationDetailsByPortfolio?.calculationDetailDtos)
    const landTransferTax = landTransferValue / netValue * 100;
    const agentCosts_Sale = agentCostsValue / netValue * 100;
    const notaryCosts = notaryCostsValue / netValue * 100;
    setOldAccValues({
      grossValue, netValue, grossCapitalValue, landTransferValue, landTransferTax, agentCosts_Sale, agentCostsValue, notaryCosts, notaryCostsValue, totalAcquisitionCostsValue
    })

  }, [calculationDetailsByPortfolio, dcfResultsModified, isSwitch])

  //*****************Finding Yiel Profile
  const { yielCurrentNIY, yielCurrentGIY, yielPotentialNIY, yielPotentialGIY, yielMarketNIY, yielMarketGIY } = getPortfolioYieldProfileCalculations(calculationDetailsByPortfolio, dcfResultsModified, isSwitch)

  //*****************Finding Yiel Profile @ sale
  const { yielSaleCurrentNIY, yielSaleCurrentGIY, yielSalePotentialNIY, yielSalePotentialGIY, yielSaleMarketNIY, yielSaleMarketGIY } = getPortfolioYieldProfileAtSaleCalculations(calculationDetailsByPortfolio, dcfResultsModified, isSwitch)


  return (
    <div>
      <Card bordered={false} className="assets_card">
        {/* <div className="d-flex justify-content-between assets_card__header mb-2"> */}
        <Row className="assets_card__header mb-2">
          <Col xs={24} sm={8}>
            <h3 className="text-black d-flex align-items-center" style={{ gap: '10px' }}>
              <Tooltip title={isSwitch ? "Asset Value" : "Asset Value @ Exit"} color="#00215b" key="#00215b">
                <Switch
                  checked={isSwitch}
                  onChange={switchChange}
                />
              </Tooltip>
              <span>{isSwitch ? "Terminal" : "Asset"} Value</span>
            </h3>
          </Col>
          <Col xs={24} sm={8} className="d-flex justify-content-center">
            <h3 className="text-black">
              {/* {
                isSwitch ? 534 : 65
                // getPerSpaceValuePortfolio(getPortfolioAssetValue(calculationDetailsByPortfolio?.calculationDetailDtos, dcfResultsModified), calculationDetailsByPortfolio?.calculationDetailDtos)
                // 'getPerSpaceValue(newNetTerminalValue, calculation.assetHVLParams, Object.values(calculation.calculationDetail.rentRolls))'
                // : 'getPerSpaceValue(newNetAssetValue, calculation.assetHVLParams, Object.values(calculation.calculationDetail.rentRolls))'
              } */}
              {getFormattedEnglishNumber(accValues?.grossCapitalValue)} € /sqm</h3>
          </Col>
          <Col xs={24} sm={8} className="d-flex justify-content-end">
            <h3 className="text-black">{isSwitch ? getFormattedEnglishNumber(terminalValue) : getFormattedEnglishNumber(assetValue)

            } €</h3>
          </Col>
        </Row>
        {/* </div> */}
        <div style={{ borderBottom: '1px solid #B0BACD', paddingTop: unsavedValues ? "5px" : '12px', paddingBottom: unsavedValues ? "12px" : '15px' }}>
          <div className="d-flex align-items-center justify-content-between">
            <div style={{ flexBasis: '30%' }}>
              <h6 className="holding_text">Gross Value {isSwitch ? "@ Exit" : ""}</h6>
              <h6 className="holding_text">Acquisition Costs {isSwitch ? "@ Exit" : ""}</h6>
              <h6 className="holding_text">Net Value {isSwitch ? "@ Exit" : ""}</h6>
            </div>
            {unsavedValues &&
              <div className="d-flex align-items-center justify-content-between"
                style={{ backgroundColor: "#E6E9EF", flexBasis: '36%', padding: "4px 12px", border: '.5px dashed #8A99B4', borderRadius: '4px' }}
              >
                <div>
                  <img src={IMAGES.DANGER} alt="" />
                  <p className="mb-0" style={{ color: '#FF7228' }}>Current </p>
                  <p className="mb-0" style={{ color: '#FF7228' }}>Changes</p>
                </div>
                <div>
                  <h6 className="holding_text text-right" >
                    {getFormattedEnglishNumber(oldAccValues?.grossValue)}
                  </h6>
                  <h6 className="holding_text text-right" >
                    {getFormattedEnglishNumber(oldAccValues?.totalAcquisitionCostsValue)}
                  </h6>
                  <h6 className="holding_text text-right" >{isSwitch ? getFormattedEnglishNumber(oldTerminalValue) : getFormattedEnglishNumber(oldAssetValue)

                  }
                  </h6>
                </div>
              </div>
            }
            <div style={{ flexBasis: '30%' }}>
              <h6 className="holding_text text-right" >
                {getFormattedEnglishNumber(accValues?.grossValue)} €</h6>
              <h6 className="holding_text text-right" >
                {getFormattedEnglishNumber(accValues?.totalAcquisitionCostsValue)} €</h6>
              <h6 className="holding_text text-right" >
                {isSwitch ? getFormattedEnglishNumber(terminalValue) : getFormattedEnglishNumber(assetValue)} €</h6>
            </div>
          </div>
        </div>
        {
          isSwitch ?
            <Row className="pt-3" style={{ paddingBottom: '2.8rem' }}>
              <Col xs={24} sm={8}>
                <div>
                  <h4 className="mb-0 text-black pb-2">
                    Yield Profile @ Sale
                  </h4>
                  <h5 className="mb-0 font-weight-normal" style={{ color: '#797979' }}>Net Initial Yield in %</h5>
                  <h5 className="mb-0 font-weight-normal " style={{ paddingTop: '0.75rem', color: '#797979' }}>Gross Initial Yield in %</h5>
                  <h5 className="mb-0 font-weight-normal " style={{ paddingTop: '0.75rem', color: '#797979' }}>Gross Multiplier</h5>
                </div>
              </Col>
              <Col xs={24} sm={16} className="d-flex justify-content-end" style={{ gap: '10px', flexWrap: 'wrap' }}>
                <div className="d-flex" style={{ gap: "1rem" }}>
                  <div>
                    <h4 className="mb-0 text-black pb-2">Current</h4>
                    <div style={styles.percentageBox}>
                      {getFormattedDecimalNumber(yielSaleCurrentNIY * 100)}
                    </div>
                    <div className="pt-2"><div style={styles.percentageBox}>
                      {getFormattedDecimalNumber(yielSaleCurrentGIY * 100)}
                    </div></div>
                    <div className="pt-2"><div style={styles.percentageBox}>
                      {getFormattedDecimalNumber(1 / yielSaleCurrentGIY)}
                    </div></div>
                  </div>
                  <div>
                    <h4 className="mb-0 text-black pb-2">Potential</h4>
                    <div style={styles.percentageBox}>
                      {getFormattedDecimalNumber(yielSalePotentialNIY * 100)}
                    </div>
                    <div className="pt-2"><div style={styles.percentageBox}>
                      {getFormattedDecimalNumber(yielSalePotentialGIY * 100)}
                    </div></div>
                    <div className="pt-2"><div style={styles.percentageBox}>
                      {getFormattedDecimalNumber(1 / yielSalePotentialGIY)}
                    </div></div>
                  </div>
                  <div>
                    <h4 className="mb-0 text-black pb-2">Market</h4>
                    <div style={styles.percentageBox}>
                      {getFormattedDecimalNumber(yielSaleMarketNIY * 100)}
                    </div>
                    <div className="pt-2"><div style={styles.percentageBox}>
                      {getFormattedDecimalNumber(yielSaleMarketGIY * 100)}
                    </div></div>
                    <div className="pt-2"><div style={styles.percentageBox}>
                      {getFormattedDecimalNumber(1 / yielSaleMarketGIY)}
                    </div></div>
                  </div>
                </div>
                <div>
                  <h5 style={{ paddingBottom: '1.6rem' }}></h5>
                  <div className="d-flex align-items-center" style={{ gap: "14px" }}>
                    <h5 className="mb-0" style={{ color: '#797979' }}>IRR in %</h5>
                    <div className="d-flex align-items-center" style={{ gap: '4px' }}>
                      <div style={styles.percentageBox}>
                        12.00
                      </div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center pt-2" style={{ gap: "10px" }}>
                    <h5 className="mb-0" style={{ color: '#797979' }}>CoC  in %</h5>
                    <div className="d-flex align-items-center" style={{ gap: '4px' }}>
                      <div style={styles.percentageBox}>
                        12.00
                      </div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center pt-2" style={{ gap: "17px" }}>
                    <h5 className="mb-0" style={{ color: '#797979' }}>EM in %</h5>
                    <div className="d-flex align-items-center" style={{ gap: '4px' }}>
                      <div style={styles.percentageBox}>
                        12.00
                      </div>
                    </div>
                  </div>
                </div>
              </Col>

            </Row>
            :
            <Row className="pt-3" style={{ paddingBottom: '2.8rem' }}>
              <Col xs={24} sm={8}>
                <div>
                  <h4 className="mb-0 text-black pb-2">
                    Yield Profile
                  </h4>
                  <h5 className="mb-0 font-weight-normal" style={{ color: '#797979' }}>Net Initial Yield in %</h5>
                  <h5 className="mb-0 font-weight-normal " style={{ paddingTop: '0.75rem', color: '#797979' }}>Gross Initial Yield in %</h5>
                  <h5 className="mb-0 font-weight-normal " style={{ paddingTop: '0.75rem', color: '#797979' }}>Gross Multiplier</h5>
                </div>
              </Col>
              <Col xs={24} sm={16} className="d-flex justify-content-end" style={{ gap: "10px", flexWrap: 'wrap' }}>
                <div className="d-flex" style={{ gap: "1rem" }}>
                  <div>
                    <h4 className="mb-0 text-black pb-2">Current</h4>
                    <div style={styles.percentageBox}>
                      {getFormattedDecimalNumber(yielCurrentNIY * 100)}
                    </div>
                    <div className="pt-2"><div style={styles.percentageBox}>
                      {getFormattedDecimalNumber(yielCurrentGIY * 100)}
                    </div></div>
                    <div className="pt-2"><div style={styles.percentageBox}>
                      {getFormattedDecimalNumber(1 / yielCurrentGIY)}
                    </div></div>
                  </div>
                  <div>
                    <h4 className="mb-0 text-black pb-2">Potential</h4>
                    <div style={styles.percentageBox}>
                      {getFormattedDecimalNumber(yielPotentialNIY * 100)}
                    </div>
                    <div className="pt-2"><div style={styles.percentageBox}>
                      {getFormattedDecimalNumber(yielPotentialGIY * 100)}
                    </div></div>
                    <div className="pt-2"><div style={styles.percentageBox}>
                      {getFormattedDecimalNumber(1 / yielPotentialGIY)}
                    </div></div>
                  </div>
                  <div>
                    <h4 className="mb-0 text-black pb-2">Market</h4>
                    <div style={styles.percentageBox}>
                      {getFormattedDecimalNumber(yielMarketNIY * 100)}
                    </div>
                    <div className="pt-2"><div style={styles.percentageBox}>
                      {getFormattedDecimalNumber(yielMarketGIY * 100)}
                    </div></div>
                    <div className="pt-2"><div style={styles.percentageBox}>
                      {getFormattedDecimalNumber(1 / yielMarketGIY)}
                    </div></div>
                  </div>
                </div>
                <div>
                  <h5 style={{ paddingBottom: '1.6rem' }}></h5>
                  <div className="d-flex align-items-center" style={{ gap: "14px" }}>
                    <h5 className="mb-0" style={{ color: '#797979' }}>IRR in %</h5>
                    <div className="d-flex align-items-center" style={{ gap: '4px' }}>
                      <div style={styles.percentageBox}>
                        12.00
                      </div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center pt-2" style={{ gap: "10px" }}>
                    <h5 className="mb-0" style={{ color: '#797979' }}>CoC in %</h5>
                    <div className="d-flex align-items-center" style={{ gap: '4px' }}>
                      <div style={styles.percentageBox}>
                        12.00
                      </div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center pt-2" style={{ gap: "17px" }}>
                    <h5 className="mb-0" style={{ color: '#797979' }}>EM in %</h5>
                    <div className="d-flex align-items-center" style={{ gap: '4px' }}>
                      <div style={styles.percentageBox}>
                        12.00
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
        }
      </Card>
    </div >
  );
}

export default VeAssetValues;

const styles = {
  percentageBox: {
    background: "#E6E9EF",
    borderRadius: "4px",
    border: '.5px solid #8A99B4',
    // padding: '2px 15px'
    display: 'flex',
    height: '1.5rem',
    width: '4.5rem',
    justifyContent: 'center',
    alignItems: 'center',
  }
}