import { Card, Checkbox, Col, Input, Row, Tooltip, Typography } from "antd";
import IncDecCounter from "../../../../../../../../components/Layout/IncDecCounter";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DCFOutput } from "reia-dcf-client";
import { getFormattedDecimalNumber, getFormattedEnglishNumber, getFormattedInt } from "../../../../../../../../utils/digitHelper";
import { getPerSpaceValue, getTerminalRentTypeFromI18nKey, getYeildProfileAtSaleCalculations, getYeildProfileCalculations } from "../../../../../../../../utils/dcfHelper";
import IMAGES from "../../../../../../../../assets/images";
import { Switch } from 'antd';

function VeAssetValue({ calculationDetail, allDataLoaded }) {
  const dispatch = useDispatch();
  const [newGrossTerminalValue, setNewGrossTerminalValue] = useState();
  const [newNetTerminalValue, setNewNetTerminalValue] = useState();
  const [newLandTransferValue, setNewLandTransferValue] = useState();
  const [newAgentCostsValue, setNewAgentCostsValue] = useState();
  const [newNotaryCostsExitValue, setNewNotaryCostsExitValue] = useState();
  const [oldGrossTerminalValue, setOldGrossTerminalValue] = useState();
  const [oldNetTerminalValue, setOldNetTerminalValue] = useState();
  const [oldLandTransferValue, setOldLandTransferValue] = useState();
  const [oldAgentCostsValue, setOldAgentCostsValue] = useState();
  const [oldNotaryCostsExitValue, setOldNotaryCostsExitValue] = useState();
  const calculation = useSelector((state) => state.calculation);
  console.log(calculation, 'calculation')
  const { assetDCFResult, assetDCFParams } = calculation;
  const { landTransferTaxExit, agentCostsExit, notaryCostsExit, landTransferTax, agentCosts_Sale, notaryCosts, terminalValueType, syncWithAnalysisDate } = assetDCFParams;
  //*****************Gross and Net capital/terminal value (Acquisition cost)
  const newGrossAssetValue = assetDCFResult?.dcfResult?.assetCashFlow?.grossAssetValue;
  const newTotalAccCostsValue = assetDCFResult?.dcfResult?.assetCashFlow?.totalAcquisitionCosts;
  const newNetAssetValue = assetDCFResult?.dcfResult?.assetCashFlow?.netAssetValue;

  const oldGrossAssetValue = calculationDetail.assetDCFResult?.dcfResult?.assetCashFlow?.grossAssetValue;
  const oldTotalAccCostsValue = calculationDetail.assetDCFResult?.dcfResult?.assetCashFlow?.totalAcquisitionCosts;
  const oldNetAssetValue = calculationDetail.assetDCFResult?.dcfResult?.assetCashFlow?.netAssetValue;

  // console.log(calculation, 'calculation')
  const newTerminalType = terminalValueType
    ? getTerminalRentTypeFromI18nKey(terminalValueType?.key)
    : 0;

  const oldTerminalType = calculationDetail?.assetDCFParams?.terminalValueType
    ? getTerminalRentTypeFromI18nKey(
      calculationDetail?.assetDCFParams?.terminalValueType?.key
    )
    : 0;

  //*****************Gross and Net capital/terminal value @ sale

  //old calculations
  useEffect(() => {
    const accCostPercentage =
      calculationDetail?.assetDCFParams?.landTransferTaxExit +
      calculationDetail?.assetDCFParams?.agentCostsExit +
      calculationDetail?.assetDCFParams?.notaryCostsExit;
    let netValue;
    if (oldTerminalType === 1) {
      const grossValue =
        calculationDetail?.assetDCFResult?.dcfResult?.assetCashFlow
          ?.terminalValue;
      netValue = (grossValue * 100) / (100 + accCostPercentage);
      setOldGrossTerminalValue(grossValue);
      setOldNetTerminalValue(netValue);
    } else {
      netValue =
        calculationDetail?.assetDCFResult?.dcfResult?.assetCashFlow
          ?.terminalValue;
      setOldNetTerminalValue(netValue);
      setOldGrossTerminalValue((netValue * (100 + accCostPercentage)) / 100);
    }

    setOldLandTransferValue(
      (netValue * calculationDetail?.assetDCFParams?.landTransferTaxExit) / 100
    );
    setOldNotaryCostsExitValue(
      (netValue * calculationDetail?.assetDCFParams?.notaryCostsExit) / 100
    );
    setOldAgentCostsValue(
      (netValue * calculationDetail?.assetDCFParams?.agentCostsExit) / 100
    );
  }, [
    calculationDetail?.assetDCFParams?.landTransferTaxExit,
    calculationDetail?.assetDCFParams?.notaryCostsExit,
    calculationDetail?.assetDCFParams?.agentCostsExit,
    calculationDetail?.assetDCFResult?.dcfResult?.assetCashFlow?.terminalValue,
  ]);

  // new calculations
  const setAssetDCFParams = (params) => {
    dispatch({ type: "calculation/setAssetDCFParams", payload: params })
  }

  useEffect(() => {

    if (syncWithAnalysisDate) {
      setAssetDCFParams({ landTransferTaxExit: landTransferTax, notaryCostsExit: notaryCosts, agentCostsExit: agentCosts_Sale })
    }

  }, [syncWithAnalysisDate, landTransferTax, notaryCosts, agentCosts_Sale])

  useEffect(() => {
    const accCostPercentage = landTransferTaxExit + agentCostsExit + notaryCostsExit;
    let netValue;
    if (newTerminalType === 1) {
      const grossValue = calculation.assetDCFResult?.dcfResult?.assetCashFlow?.terminalValue;
      netValue = grossValue * 100 / (100 + accCostPercentage)
      setNewGrossTerminalValue(grossValue)
      setNewNetTerminalValue(netValue)
    }
    else {
      netValue = calculation.assetDCFResult?.dcfResult?.assetCashFlow?.terminalValue;
      setNewNetTerminalValue(netValue)
      setNewGrossTerminalValue(netValue * (100 + accCostPercentage) / 100)
    }

    setNewLandTransferValue(netValue * landTransferTaxExit / 100)
    setNewNotaryCostsExitValue(netValue * notaryCostsExit / 100)
    setNewAgentCostsValue(netValue * agentCostsExit / 100)

  }, [landTransferTaxExit, notaryCostsExit, agentCostsExit, calculation.assetDCFResult?.dcfResult?.assetCashFlow?.terminalValue])

  //*****************Finding Yiel Profile
  const { yielMarketGIY, yielMarketNIY, yielPotentialGIY, yielPotentialNIY, yielCurrentGIY, yielCurrentNIY } = getYeildProfileCalculations(calculationDetail)

  //*****************Finding Yiel @ sale Profile
  const { yielSaleMarketGIY, yielSaleMarketNIY, yielSalePotentialGIY, yielSalePotentialNIY, yielSaleCurrentGIY, yielSaleCurrentNIY } = getYeildProfileAtSaleCalculations(calculationDetail)


  const [isSwitch, setIsSwitch] = useState(false);
  const switchChange = (checked) => {
    setIsSwitch(checked);
  };

  const unsavedValues = !calculation?.autoSaveCalc && calculation?.assetValueMismatched
  return (
    <div>
      <Card bordered={false} className="assets_card">
        {/* <div className="d-flex justify-content-between assets_card__header mb-2"> */}
        <Row className="assets_card__header mb-2">
          <Col xs={24} sm={8}>
            <h3 className="text-black d-flex align-items-center" style={{ gap: '10px' }}>
              <Tooltip title={isSwitch ? "Asset Value" : "Asset Value @ Exit"} color="#00215b" key="#00215b">
                <Switch
                  checked={isSwitch}
                  onChange={switchChange}
                />
              </Tooltip>
              <span>{isSwitch ? "Terminal" : "Asset"} Value</span>
            </h3>
          </Col>
          <Col xs={24} sm={8} className="d-flex justify-content-center">
            <h3 className="text-black">
              {
                isSwitch ?
                  getPerSpaceValue(newNetTerminalValue, calculation.assetHVLParams, Object.values(calculation.calculationDetail.rentRolls))
                  : getPerSpaceValue(newNetAssetValue, calculation.assetHVLParams, Object.values(calculation.calculationDetail.rentRolls))
              }
              € /sqm</h3>
          </Col>
          <Col xs={24} sm={8} className="d-flex justify-content-end">
            {/* <h3 className="text-black">{getFormattedEnglishNumber(newNetAssetValue)} €</h3> */}
            <h3 className="text-black">{isSwitch ? getFormattedEnglishNumber(newNetTerminalValue) : getFormattedEnglishNumber(newNetAssetValue)} €</h3>
          </Col>
        </Row>
        {/* </div> */}
        <div style={{ borderBottom: '1px solid #B0BACD', paddingTop: unsavedValues ? "5px" : '12px', paddingBottom: unsavedValues ? "12px" : '15px' }}>
          <div className="d-flex align-items-center justify-content-between">
            <div style={{ flexBasis: '30%' }}>
              <h6 className="holding_text">Gross Value {isSwitch ? "@ Exit" : ""}</h6>
              <h6 className="holding_text">Acquisition Costs {isSwitch ? "@ Exit" : ""}</h6>
              <h6 className="holding_text">Net Value {isSwitch ? "@ Exit" : ""}</h6>
            </div>
            {unsavedValues &&
              <div className="d-flex align-items-center justify-content-between"
                style={{ backgroundColor: "#E6E9EF", flexBasis: '36%', padding: "4px 12px", border: '.5px dashed #8A99B4', borderRadius: '4px' }}
              >
                <div>
                  <img src={IMAGES.DANGER} alt="" />
                  <p className="mb-0" style={{ color: '#FF7228' }}>Current </p>
                  <p className="mb-0" style={{ color: '#FF7228' }}>Changes</p>
                </div>
                <div>
                  <h6 className="holding_text text-right" >{isSwitch ? getFormattedEnglishNumber(oldGrossTerminalValue) : getFormattedEnglishNumber(oldGrossAssetValue)}</h6>
                  <h6 className="holding_text text-right" >{isSwitch ? getFormattedEnglishNumber(oldLandTransferValue + oldAgentCostsValue + oldNotaryCostsExitValue) : getFormattedEnglishNumber(oldTotalAccCostsValue)}</h6>
                  <h6 className="holding_text text-right" >{isSwitch ? getFormattedEnglishNumber(oldNetTerminalValue) : getFormattedEnglishNumber(oldNetAssetValue)}</h6>
                </div>
              </div>
            }
            <div style={{ flexBasis: '30%' }}>
              <h6 className="holding_text text-right" >{isSwitch ? getFormattedEnglishNumber(newGrossTerminalValue) : getFormattedEnglishNumber(newGrossAssetValue)} €</h6>
              <h6 className="holding_text text-right" >{isSwitch ? getFormattedEnglishNumber(newLandTransferValue + newAgentCostsValue + newNotaryCostsExitValue) : getFormattedEnglishNumber(newTotalAccCostsValue)} €</h6>
              <h6 className="holding_text text-right" >{isSwitch ? getFormattedEnglishNumber(newNetTerminalValue) : getFormattedEnglishNumber(newNetAssetValue)} €</h6>
            </div>
          </div>
        </div>
        {
          isSwitch ?
            // <div
            //   className="pt-3"
            //   style={{
            //     display: "flex",
            //     gap: "20px",
            //     alignItems: "end",
            //     justifyContent: "space-between",
            //     paddingRight: "5px",
            //   }}
            // >
            <Row className="pt-3" style={{}}>
              <Col xs={24} sm={8}>
                <div>
                  <h4 className="mb-0 text-black pb-2">
                    Yield Profile @ Sale
                  </h4>
                  <h5 className="mb-0 font-weight-normal" style={{ color: '#797979' }}>Net Initial Yield in %</h5>
                  <h5 className="mb-0 font-weight-normal " style={{ paddingTop: '0.75rem', color: '#797979' }}>Gross Initial Yield in %</h5>
                  <h5 className="mb-0 font-weight-normal " style={{ paddingTop: '0.75rem', color: '#797979' }}>Gross Multiplier</h5>
                </div>
              </Col>
              <Col xs={24} sm={16} className="d-flex justify-content-end" style={{ gap: '10px', flexWrap: 'wrap' }}>
                <div className="d-flex" style={{ gap: "1rem" }}>
                  <div>
                    <h4 className="mb-0 text-black pb-2">Current</h4>
                    <div style={styles.percentageBox}>{getFormattedDecimalNumber(calculationDetail?.assetKPIs?.netInitialYieldTerminalCurrentRent)}</div>
                    <div className="pt-2"><div style={styles.percentageBox}>{getFormattedDecimalNumber(calculationDetail?.assetKPIs?.grossInitialYieldTerminalCurrentRent)} </div></div>
                    <div className="pt-2"><div style={styles.percentageBox}>{getFormattedDecimalNumber(calculationDetail?.assetKPIs?.multipleInitialYieldTerminalCurrentRent)}</div></div>
                  </div>
                  <div>
                    <h4 className="mb-0 text-black pb-2">Potential</h4>
                    <div style={styles.percentageBox}>{getFormattedDecimalNumber(calculationDetail?.assetKPIs?.netInitialYieldTerminalPotentialRent)}</div>
                    <div className="pt-2"><div style={styles.percentageBox}> {getFormattedDecimalNumber(calculationDetail?.assetKPIs?.grossInitialYieldTerminalPotentialRent)}</div></div>
                    <div className="pt-2"><div style={styles.percentageBox}>{getFormattedDecimalNumber(calculationDetail?.assetKPIs?.multipleInitialYieldTerminalPotentialRent)}</div></div>
                  </div>
                  <div>
                    <h4 className="mb-0 text-black pb-2">Market</h4>
                    <div style={styles.percentageBox}>{getFormattedDecimalNumber(calculationDetail?.assetKPIs?.netInitialYieldTerminalMarketRent)}</div>
                    <div className="pt-2"><div style={styles.percentageBox}>{getFormattedDecimalNumber(calculationDetail?.assetKPIs?.grossInitialYieldTerminalMarketRent)}</div></div>
                    <div className="pt-2"><div style={styles.percentageBox}>{getFormattedDecimalNumber(calculationDetail?.assetKPIs?.multipleInitialYieldTerminalMarketRent)}</div></div>
                  </div>
                </div>
                <div>
                  <h5 style={{ paddingBottom: '1.6rem' }}></h5>
                  <div className="d-flex align-items-center" style={{ gap: "14px" }}>
                    <h5 className="mb-0" style={{ color: '#797979' }}>IRR in %</h5>
                    <div className="d-flex align-items-center" style={{ gap: '4px' }}>
                      <div style={styles.percentageBox}>
                        12.00
                      </div>
                      {/* <span>%</span> */}
                    </div>
                  </div>
                  <div className="d-flex align-items-center pt-2" style={{ gap: "10px" }}>
                    <h5 className="mb-0" style={{ color: '#797979' }}>CoC  in %</h5>
                    <div className="d-flex align-items-center" style={{ gap: '4px' }}>
                      <div style={styles.percentageBox}>
                        12.00
                      </div>
                      {/* <span>%</span> */}
                    </div>
                  </div>
                  <div className="d-flex align-items-center pt-2" style={{ gap: "17px" }}>
                    <h5 className="mb-0" style={{ color: '#797979' }}>EM in %</h5>
                    <div className="d-flex align-items-center" style={{ gap: '4px' }}>
                      <div style={styles.percentageBox}>
                        12.00
                      </div>
                      {/* <span>%</span> */}
                    </div>
                  </div>
                </div>
              </Col>
              {/* <Col xs={24} sm={8} className="d-flex justify-content-end">
                <div>
                  <h5 style={{ paddingBottom: '1.6rem' }}></h5>
                  <div className="d-flex align-items-center" style={{ gap: "14px" }}>
                    <Typography>IRR</Typography>
                    <div className="d-flex align-items-center" style={{ gap: '4px' }}>
                      <div style={styles.percentageBox}>
                        12.00
                      </div>
                      <span>%</span>
                    </div>
                  </div>
                  <div className="d-flex align-items-center pt-1" style={{ gap: "10px" }}>
                    <Typography>CoC</Typography>
                    <div className="d-flex align-items-center" style={{ gap: '4px' }}>
                      <div style={styles.percentageBox}>
                        12.00
                      </div>
                      <span>%</span>
                    </div>
                  </div>
                  <div className="d-flex align-items-center pt-1" style={{ gap: "17px" }}>
                    <Typography>EM</Typography>
                    <div className="d-flex align-items-center" style={{ gap: '4px' }}>
                      <div style={styles.percentageBox}>
                        12.00
                      </div>
                      <span>%</span>
                    </div>
                  </div>
                </div>
              </Col> */}
              {/* </div> */}
            </Row>
            :
            // <div
            //   className="pt-3"
            //   style={{
            //     display: "flex",
            //     gap: "20px",
            //     alignItems: "end",
            //     justifyContent: "space-between",
            //     paddingRight: "5px",
            //   }}
            // >
            <Row className="pt-3" style={{}}>
              <Col xs={24} sm={8}>
                <div>
                  <h4 className="mb-0 text-black pb-2">
                    Yield Profile
                  </h4>
                  <h5 className="mb-0 font-weight-normal" style={{ color: '#797979' }}>Net Initial Yield in %</h5>
                  <h5 className="mb-0 font-weight-normal " style={{ paddingTop: '0.75rem', color: '#797979' }}>Gross Initial Yield in %</h5>
                  <h5 className="mb-0 font-weight-normal " style={{ paddingTop: '0.75rem', color: '#797979' }}>Gross Multiplier</h5>
                </div>
              </Col>
              <Col xs={24} sm={16} className="d-flex justify-content-end" style={{ gap: "10px", flexWrap: 'wrap' }}>
                {/* <Row> */}
                <div className="d-flex" style={{ gap: "1rem" }}>
                  <div>
                    <h4 className="mb-0 text-black pb-2">Current</h4>
                    <div style={styles.percentageBox}>{getFormattedDecimalNumber(calculationDetail?.assetKPIs?.netInitialYieldCurrentRent)}</div>
                    <div className="pt-2"><div style={styles.percentageBox}>{getFormattedDecimalNumber(calculationDetail?.assetKPIs?.grossInitialYieldCurrentRent)} </div></div>
                    <div className="pt-2"><div style={styles.percentageBox}>{getFormattedDecimalNumber(calculationDetail?.assetKPIs?.multipleInitialYieldCurrentRent)}</div></div>
                  </div>
                  <div>
                    <h4 className="mb-0 text-black pb-2">Potential</h4>
                    <div style={styles.percentageBox}>{getFormattedDecimalNumber(calculationDetail?.assetKPIs?.netInitialYieldPotentialRent)}</div>
                    <div className="pt-2"><div style={styles.percentageBox}> {getFormattedDecimalNumber(calculationDetail?.assetKPIs?.grossInitialYieldPotentialRent)}</div></div>
                    <div className="pt-2"><div style={styles.percentageBox}>{getFormattedDecimalNumber(calculationDetail?.assetKPIs?.multipleInitialYieldPotentialRent)}</div></div>
                  </div>
                  <div>
                    <h4 className="mb-0 text-black pb-2">Market</h4>
                    <div style={styles.percentageBox}>{getFormattedDecimalNumber(calculationDetail?.assetKPIs?.netInitialYieldMarketRent)}</div>
                    <div className="pt-2"><div style={styles.percentageBox}>{getFormattedDecimalNumber(calculationDetail?.assetKPIs?.grossInitialYieldMarketRent)}</div></div>
                    <div className="pt-2"><div style={styles.percentageBox}>{getFormattedDecimalNumber(calculationDetail?.assetKPIs?.multipleInitialYieldMarketRent)}</div></div>
                  </div>
                </div>
                {/* <Col xs={24} sm={8} className="d-flex justify-content-end"> */}
                <div>
                  <h5 style={{ paddingBottom: '1.6rem' }}></h5>
                  <div className="d-flex align-items-center" style={{ gap: "14px" }}>
                    <h5 className="mb-0" style={{ color: '#797979' }}>IRR in %</h5>
                    <div className="d-flex align-items-center" style={{ gap: '4px' }}>
                      <div style={styles.percentageBox}>
                        12.00
                      </div>
                      {/* <span>%</span> */}
                    </div>
                  </div>
                  <div className="d-flex align-items-center pt-2" style={{ gap: "10px" }}>
                    <h5 className="mb-0" style={{ color: '#797979' }}>CoC in %</h5>
                    <div className="d-flex align-items-center" style={{ gap: '4px' }}>
                      <div style={styles.percentageBox}>
                        12.00
                      </div>
                      {/* <span>%</span> */}
                    </div>
                  </div>
                  <div className="d-flex align-items-center pt-2" style={{ gap: "17px" }}>
                    <h5 className="mb-0" style={{ color: '#797979' }}>EM in %</h5>
                    <div className="d-flex align-items-center" style={{ gap: '4px' }}>
                      <div style={styles.percentageBox}>
                        12.00
                      </div>
                      {/* <span>%</span> */}
                    </div>
                  </div>
                </div>
                {/* </Col> */}
                {/* </Row> */}
              </Col>
              {/* <Col xs={24} sm={8} className="d-flex justify-content-end">
                <div>
                  <h5 style={{ paddingBottom: '1.6rem' }}></h5>
                  <div className="d-flex align-items-center" style={{ gap: "14px" }}>
                    <Typography>IRR</Typography>
                    <div className="d-flex align-items-center" style={{ gap: '4px' }}>
                      <div style={styles.percentageBox}>
                        12.00
                      </div>
                      <span>%</span>
                    </div>
                  </div>
                  <div className="d-flex align-items-center pt-1" style={{ gap: "10px" }}>
                    <Typography>CoC</Typography>
                    <div className="d-flex align-items-center" style={{ gap: '4px' }}>
                      <div style={styles.percentageBox}>
                        12.00
                      </div>
                      <span>%</span>
                    </div>
                  </div>
                  <div className="d-flex align-items-center pt-1" style={{ gap: "17px" }}>
                    <Typography>EM</Typography>
                    <div className="d-flex align-items-center" style={{ gap: '4px' }}>
                      <div style={styles.percentageBox}>
                        12.00
                      </div>
                      <span>%</span>
                    </div>
                  </div>
                </div>
              </Col> */}
              {/* </div> */}
            </Row>
        }
      </Card>
    </div >
  );
}

export default VeAssetValue;

const styles = {
  percentageBox: {
    background: "#E6E9EF",
    borderRadius: "4px",
    border: '.5px solid #8A99B4',
    // padding: '2px 15px'
    display: 'flex',
    height: '1.5rem',
    width: '4.5rem',
    justifyContent: 'center',
    alignItems: 'center',
  }
}