import {CalculationDetailDto} from "reia-rest-client";
import {DCFParameters} from "reia-rest-client";
import {DCFOutput} from "reia-dcf-client";
import {getFinancingLTV} from "./dcfHelper";


export const DCF_FINANCING_TYPE_I18N_KEY = "core-data.financingTypes.bullet-loan"
export const DCF_FINANCING_LTV = "0.5"
export const DCF_FINANCING_INTEREST_RATE = "1.5"
export const DCF_FINANCING_SWAP_RATE = "2.0"
export const DCF_FINANCING_AMORTIZATION = "0"
export const DCF_FINANCING_TARGET_VALUE = "0"
export const DCF_FINANCING_PREPAYMENT_FEE = "0"