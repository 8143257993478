/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable */
import { Card, Checkbox, Input, Form, Table, notification, Button, InputNumber } from "antd";
import { useDispatch, useSelector } from "react-redux";
import React, { useContext, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { AssetDto } from "reia-rest-client";
import { getFormattedDecimalNumber } from "../../../../../../../../utils/digitHelper";

const EditableContext = React.createContext(null);
function EditableRow({ index, ...props }) {
  const [form] = Form.useForm();
  return (
    <Form size="small" form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
}
function EditableCell({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);
  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };
  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({
        ...record,
        ...values,
      });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };
  let childNode = children;
  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
      >
        <InputNumber controls={false} ref={inputRef} type="number" onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        role="presentation"
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }
  return <td {...restProps}>{childNode}</td>;
}

function ErvEditableCell({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleErvSave,
  ...restProps
}) {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);
  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };
  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleErvSave({
        ...record,
        ...values,
      });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };
  let childNode = children;
  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
      >
        <InputNumber controls={false} ref={inputRef} type="number" onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        role="presentation"
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }
  return <td {...restProps}>{childNode}</td>;
}

function VeInflationAndERVGrowth(props) {
  const { mode } = props;
  const dispatch = useDispatch();
  const { durationInMonths, inflation, ervGrowth, syncWithInflation } = useSelector((state) => state.calculation.assetDCFParams);
  const { asset }: { asset: AssetDto } = useSelector((state) => state.assets);
  const setAssetDCFParams = (params) => {
    dispatch({ type: "calculation/setAssetDCFParams", payload: params })
  }

  const startYear: number = moment(asset?.analysisDate).year();
  const endYear: number = startYear + durationInMonths / 12;
  let year, inflationsYear = [];
  let maxYear = endYear - startYear > 9 ? startYear + 9 : endYear - 1;

  // console.log(endYear, startYear)
  for (year = startYear; year <= maxYear; year++) {
    inflationsYear.push(year)
  }

  const initialInflationData = {}

  inflationsYear.forEach(year => initialInflationData[year] = 6.5);

  const inflationColumns = inflationsYear.map((year, index) => {
    return {
      title: year,
      dataIndex: index,
      key: index,
      align: "center",
      editable: true,
      render: (text) => <div>{getFormattedDecimalNumber(text)}</div>,
    }
  })

  // console.log(inflationColumns, 'inflationColumns')
  // useEffect(() => {
  //   setAssetDCFParams({inflation: [initialInflationData]})
  //   setAssetDCFParams({ervGrowth: [initialInflationData]})
  // }, [durationInMonths,startYear]);
  //

  const inflationData = {};
  inflation.forEach((value, index) => {
    inflationData[index] = value;
  })

  const ervGrowthData = {};
  ervGrowth.forEach((value, index) => {
    ervGrowthData[index] = value;
  })

  useEffect(() => {

    if (syncWithInflation)
      setAssetDCFParams({ ervGrowth: inflation })

  }, [syncWithInflation])

  const { striped } = props;

  const handleSave = (row) => {
    const newData = [...inflation];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setAssetDCFParams({ inflation: Object.values(row) })

    if (syncWithInflation)
      setAssetDCFParams({ ervGrowth: Object.values(row) })
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = inflationColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  const handleErvSave = (row) => {
    const newData = [...ervGrowth];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setAssetDCFParams({ ervGrowth: Object.values(row) })
  };

  const ervComponents = {
    body: {
      row: EditableRow,
      cell: ErvEditableCell,
    },
  };

  const ervColumns = inflationColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleErvSave,
      }),
    };
  });

  return (
    <div className="erv-table">
      <Card className="assets_card inflation-card" style={{}}>
        <div>
          {mode === "both" || mode === "inflation" ? <h3 className="text-black text-table mb-0">Inflation</h3> :
            <React.Fragment>
              <div className="d-flex justify-content-between w-100">
                <div>
                  <h3 className="text-black mb-0 text-table">ERV Growth</h3>
                </div>
                <div>
                  <Checkbox
                    style={{ color: "#6F7173" }}
                    checked={syncWithInflation}
                    onClick={(event) => {
                      setAssetDCFParams({ syncWithInflation: event.target.checked })
                    }}
                  >SYNC WITH INFLATION</Checkbox>
                </div>
              </div>
            </React.Fragment>}

        </div>
        {mode === "both" || mode === "inflation" ? <Table
          components={components}
          rowClassName={(record, index) =>
            striped !== false && index % 2 === 0
              ? "ant-table-row-light"
              : "ant-table-row-level"
          }
          columns={columns}
          dataSource={[inflationData]}
          // bordered
          pagination={false}
          className="calculation-table mt-2"

        /> : <Table
          components={syncWithInflation ? components : ervComponents}
          rowClassName={(record, index) =>
            striped !== false && index % 2 === 0
              ? "ant-table-row-light"
              : "ant-table-row-level"
          }
          columns={syncWithInflation ? columns : ervColumns}
          dataSource={syncWithInflation ? [inflationData] : [ervGrowthData]}
          // bordered
          pagination={false}
          className="calculation-table mt-2"

        />}
        {mode === "both" ?
          <React.Fragment>
            <div className="pt-2 mt-3 d-flex justify-content-between w-100">
              <div>
                <h3 className="text-black mb-0 text-table">ERV Growth</h3>
              </div>
              <div>
                <Checkbox
                  style={{ color: "#6F7173" }}
                  checked={syncWithInflation}
                  onClick={(event) => {
                    setAssetDCFParams({ syncWithInflation: event.target.checked })
                  }}
                >
                  SYNC WITH INFLATION
                </Checkbox>
              </div>
            </div>

            <Table
              components={syncWithInflation ? components : ervComponents}
              rowClassName={(record, index) =>
                striped !== false && index % 2 === 0
                  ? "ant-table-row-light"
                  : "ant-table-row-level"
              }
              columns={syncWithInflation ? columns : ervColumns}
              dataSource={syncWithInflation ? [inflationData] : [ervGrowthData]}
              // bordered
              pagination={false}
              className="calculation-table mt-2"

            /> </React.Fragment> : ""}
      </Card>
    </div>
  );
}

export default VeInflationAndERVGrowth;
VeInflationAndERVGrowth.propTypes = {
  striped: PropTypes.node.isRequired,
};

EditableRow.propTypes = {
  index: PropTypes.node.isRequired,
};

EditableCell.propTypes = {
  title: PropTypes.node.isRequired,
  editable: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  dataIndex: PropTypes.node.isRequired,
  record: PropTypes.node.isRequired,
  handleSave: PropTypes.node.isRequired,
};

ErvEditableCell.propTypes = {
  title: PropTypes.node.isRequired,
  editable: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  dataIndex: PropTypes.node.isRequired,
  record: PropTypes.node.isRequired,
  handleErvSave: PropTypes.node.isRequired,
};
