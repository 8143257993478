/* eslint-disable*/
import { Button, Checkbox, Col, Menu, notification, Row } from "antd";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import DataTable from "../../../../../../components/Layout/Table/DataTable";
import PATH from "../../../../../../utils/path";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllPortfolio,
  deletePortfolio,
} from "../../../../../../app/features/Portfolio/portfolio.slice";
import DeleteUserModal from "../../../../../../components/Layout/DeleteModel/DeleteUserModal";
import { MdDelete } from "react-icons/md";
import { getAssetsByProject } from "../../../../../../app/features/projectDetails/projectDetail.slice";
import { PORTFOLIO_PAGE_SIZE, PROJECTS_PAGE_SIZE } from "../../../../../../utils/helpers";

export default function PortfolioDetails({
  // sethideTableColumns,
  showPortfolio,
  selectedRowKeys,
  setSelectedRowKeys,
  allPortfolio,
  isLoading, portfolioColumns, setPortfolioColumns,
  projectDetailFilter, setProjectDetailFilter, filteredPortfolioColumns, portfolioHideColumns
}) {
  const dispatch = useDispatch();
  const param = useParams();
  const ProjectId = param.projectId;
  const PortfolioId = param.portfolioId;
  // const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [deleteUserModal, setDeleteUserModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1)
  const [currentPageSize, setCurrentPageSize] = useState(PORTFOLIO_PAGE_SIZE)
  // const { allPortfolio, isLoading } = useSelector((state) => state.portfolio);
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const pageNumbers =
    currentPage * currentPageSize - currentPageSize + 1;

  const nextPageNumber =
    currentPage * currentPageSize > allPortfolio?.totalElements ? allPortfolio?.totalElements
      : currentPage * currentPageSize

  const initialColState = [
    {
      id: 1,
      title: "PID",
      onCell: (data) => ({
        className: data.type === 1 ? "red-border" : "blue-border",
      }),
      dataIndex: "id",
    },
    {
      id: 2,
      title: "Portfolio Name",
      dataIndex: "portfolioName",
    },
    {
      id: 3,
      title: "Total Area",
      dataIndex: "totalArea",
    },
    {
      id: 4,
      title: "Total Income",
      dataIndex: "totalIncome",
    },
    {
      id: 5,
      title: "Total Value",
      dataIndex: "totalValue",
    },
    {
      id: 6,
      title: "WALT",
      dataIndex: "walt",
    },
    {
      id: 7,
      title: "Vacancy",
      dataIndex: "vacancy",
    },
    {
      id: 8,
      title: "NIY",
      dataIndex: "niyMultiple",
    },
    {
      id: 9,
      title: "Multiple",
      dataIndex: "multiple",
    },
  ];

  const navigate = useNavigate();
  const changePath = (record) => {
    if (
      PATH.PROJECT_DETAILS.replace(":projectId", +ProjectId) ===
      location.pathname
    ) {
      // return navigate(PATH.PORTFOLIO_DETAILS);
      return navigate(
        `/projects/${ProjectId}/project-detail/${record.id}/portfolio-detail`, { state: allPortfolio }
      );
    } else {
      return navigate(
        `/user-management/${param.userId}/user-details/${ProjectId}/project-detail/${record.id}/asset-detail`
      );
    }
  };
  const navigateRow = (record) => {
    return {
      onClick: () => {
        changePath(record); // to avoid es lint error
      }, // click row
    };
  };

  // const [columns, setColumns] = useState(initialColState);

  // const handleReset = () => {
  //   setColumns(initialColState);
  //   // setCheckList([]);
  // };

  // const handleChange = (e) => {
  //   if (e.target.checked) {
  //     setColumns((current) =>
  //       current.filter((val) => val.dataIndex !== e.target.value)
  //     );
  //   } else {
  //     setColumns((current) =>
  //       [
  //         ...current,
  //         { id: e.target.id, title: e.target.name, dataIndex: e.target.value },
  //       ].sort((a, b) => a.id - b.id)
  //     );
  //   }
  // };

  // const basicDatahideTableColumns = (
  //   <Menu
  //     items={[
  //       {
  //         key: 1,
  //         type: "group",
  //         label: (
  //           <div>
  //             <h4 style={{ color: "#000" }}>Hide table columns</h4>
  //           </div>
  //         ),
  //       },
  //       {
  //         key: 10,
  //         label: (
  //           <Checkbox
  //             value="portfolio"
  //             name="Portfolio Name"
  //             id={2}
  //             // checked={checkList.includes("valDate")}
  //             onChange={handleChange}
  //           >
  //             Portfolio Name
  //           </Checkbox>
  //         ),
  //       },
  //       {
  //         key: 2,
  //         label: (
  //           <Checkbox
  //             value="totalArea"
  //             name="Total Area"
  //             id={2}
  //             // checked={checkList.includes("valDate")}
  //             onChange={handleChange}
  //           >
  //             Total Area
  //           </Checkbox>
  //         ),
  //       },
  //       {
  //         key: 3,
  //         label: (
  //           <Checkbox
  //             value="totalIncome"
  //             name="Total Income"
  //             id={3}
  //             // checked={checkList.includes("projectNo")}
  //             onChange={handleChange}
  //           >
  //             Total Income
  //           </Checkbox>
  //         ),
  //       },
  //       {
  //         key: 4,
  //         label: (
  //           <Checkbox
  //             value="totalValue"
  //             name="Total Value"
  //             id={4}
  //             // checked={checkList.includes("projectName")}
  //             onChange={handleChange}
  //           >
  //             Total Value
  //           </Checkbox>
  //         ),
  //       },
  //       {
  //         key: 5,
  //         label: (
  //           <Checkbox
  //             value="walt"
  //             name="WALT"
  //             id={5}
  //             // checked={checkList.includes("nameOfClient1")}
  //             onChange={handleChange}
  //           >
  //             WALT
  //           </Checkbox>
  //         ),
  //       },
  //       {
  //         key: 6,
  //         label: (
  //           <Checkbox
  //             value="vacancy"
  //             name="Vacancy"
  //             id={6}
  //             // checked={checkList.includes("nameOfClient2")}
  //             onChange={handleChange}
  //           >
  //             Vacancy
  //           </Checkbox>
  //         ),
  //       },
  //       {
  //         key: 7,
  //         label: (
  //           <Checkbox
  //             value="niyMultiple"
  //             name="NIY"
  //             id={7}
  //             // checked={checkList.includes("instruction")}
  //             onChange={handleChange}
  //           >
  //             NIY
  //           </Checkbox>
  //         ),
  //       },
  //       {
  //         key: 9,
  //         label: (
  //           <Checkbox
  //             value="multiple"
  //             name="Multiple"
  //             id={7}
  //             // checked={checkList.includes("instruction")}
  //             onChange={handleChange}
  //           >
  //             multiple
  //           </Checkbox>
  //         ),
  //       },
  //       {
  //         key: 8,
  //         label: (
  //           <div>
  //             <hr style={{ border: "1px solid #F2F3F5" }} />
  //             <Button
  //               className="reset-dropdown-btn"
  //               onClick={() => handleReset()}
  //             >
  //               Reset
  //             </Button>
  //           </div>
  //         ),
  //       },
  //     ]}
  //   />
  // );
  // function notifyToaster(data, condition) {
  //   notification.config({
  //     maxCount: 1,
  //   });
  //   if (condition === "success") {
  //     notification.success({
  //       message: data,
  //     });
  //   } else {
  //     notification.error({
  //       message: data,
  //     });
  //   }
  // }

  const loadPortfolios = () => {
    const finalData = {
      projectId: +ProjectId,
      page: currentPage,
      size: -1,
    };
    dispatch(getAllPortfolio(finalData));
  }

  // const moveToNextDelete = () => {
  //   closeDeleteUserModal();
  //   loadPortfolios()
  // };
  // const deletePortfolioFunct = () => {
  //   const finalData = selectedRowKeys;
  //   dispatch(deletePortfolio({ finalData, moveToNextDelete, notifyToaster }));
  // };

  useEffect(() => {
    loadPortfolios()
  }, [dispatch]);


  useEffect(() => {
    if (portfolioHideColumns) {
      const columnsToKeep = portfolioHideColumns
        ?.filter((item) => item.isChecked)
        .map((item) => item.value);
      const filteredArray = portfolioColumns?.filter((column) => {
        const dataIndexArray = Array.isArray(column.dataIndex)
          ? column.dataIndex
          : [column.dataIndex];
        const shouldKeepColumn = !dataIndexArray?.some((dataIndex) =>
          columnsToKeep?.includes(dataIndex)
        );
        return shouldKeepColumn;
      });
      setPortfolioColumns(filteredArray);
    }
  }, []);
  return (
    <div className="porfolio-detail mt-3">
      <div className="basic-data">
        <DataTable
          columns={filteredPortfolioColumns}
          dataSource={allPortfolio?.content}
          pagination={{
            current: currentPage,
            pageSize: currentPageSize,
            total: allPortfolio?.totalElements,
            showSizeChanger: false,
            disabled: false,
            onChange: (page, pageSize) => {
              setCurrentPage(page);
              setCurrentPageSize(pageSize);
            },
          }}
          className="antd-table-striped"
          onRow={navigateRow}
          rowSelection={rowSelection}
          loading={isLoading}
          rowKey={(record) => record.id}
          scroll={{
            x: "900px",
            y: "calc(100vh - 371px)",
          }}
        />
      </div>
      {allPortfolio?.content?.length > 0 && (
        <>
          <div className="table-count">
            <h5 className="table-count-content">
              Showing {pageNumbers} to {nextPageNumber} of{" "}
              {allPortfolio?.totalElements} entries
            </h5>
          </div>
        </>
      )}
      {/* <DeleteUserModal
        visible={deleteUserModal}
        handleCancel={closeDeleteUserModal}
        user="Portfolio"
        onSubmit={deletePortfolioFunct}
        isLoaded={isLoading}
      /> */}
    </div>
  );
}

PortfolioDetails.propTypes = {
  // sethideTableColumns: PropTypes.node.isRequired,
  showPortfolio: PropTypes.node.isRequired,
};
