/* eslint-disable */
import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import settings from "./settings.service";
import FileDownload from "js-file-download";

const initialState = {
    landTaxes: null, 
    isError: false, 
    isSuccess: false, 
    isLoading: false, 
    statusMessage: "",
    importExcelFileAssetsLoading : false,
    importExcelFileRentRollsLoading : false,
    exportExcelFileAssetsLoading : false,
};

export const getSettingsByCategory = createAsyncThunk("settings/getSettingsByCategory", async ({
                                                                                                   finalData,
                                                                                                   moveToNext,
                                                                                                   notifyToaster
                                                                                               }, thunkAPI) => {
    try {
        const response = await settings.getSettingsByCategory(finalData);
        if (response) {
            if (moveToNext) {
                moveToNext();
            }
        } else {
            notifyToaster(JSON.stringify(response), "error");
        }
        return {key: finalData.category, data: response};
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const importExcelFileRentRolls = createAsyncThunk("settings/importExcelFileRentRolls", async ({
                                                                                       finalData,
                                                                                       moveToNext,
                                                                                       notifyToaster
                                                                                   }, thunkAPI) => {
    try {
        const response = await settings.importRentRoll(finalData);
        if (response.code === 200) {
            if (notifyToaster) {
                notifyToaster(response.message, "success");
            }
            if (moveToNext) {
                moveToNext();
            }
        } else {
            notifyToaster(response.message, "error");
        }
        return {key: finalData.category, data: response};
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        notifyToaster(message, "error");
        return thunkAPI.rejectWithValue(message);
    }
});

export const importExcelFileAssets = createAsyncThunk("settings/importExcelFileAssets", async ({
                                                                                                         finalData,
                                                                                                         moveToNext,
                                                                                                         notifyToaster
                                                                                                     }, thunkAPI) => {
    try {
        const response = await settings.importAssets(finalData);
        if (response.code === 200) {
            if (notifyToaster) {
                notifyToaster(response.message, "success");
            }
            if (moveToNext) {
                moveToNext();
            }
        } else {
            notifyToaster(response.message, "error");
        }
        return {key: finalData.category, data: response};
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        notifyToaster(message, "error");
        return thunkAPI.rejectWithValue(message);
    }
});

export const exportExcelFileAssets = createAsyncThunk("settings/exportExcelFileAssets", async ({
                                                                                                   finalData,
                                                                                                   moveToNext,
                                                                                                   notifyToaster
                                                                                               }, thunkAPI) => {
    try {
        let response;
        if(finalData.assetIds)
        {
            response = await settings.exportAssetsByIds(finalData);    
        }else{
            response = await settings.exportAssets(finalData);
        }
        
        if (response.status === 200) {
            const contentDisposition = response.headers["content-disposition"]
            const filename = contentDisposition ? contentDisposition.split("=")[1] : "Assets.xlsx";
            const FileDownload = require('js-file-download');
            FileDownload(response.data, filename);
        } else {
            notifyToaster(JSON.stringify(response), "error");
        }
        return response;
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        notifyToaster(message, "error");
        return thunkAPI.rejectWithValue(message);
    }
});

export const createSettingsSlice = createSlice({
    name: "settings", initialState, reducers: {
        clearData: (state) => {
            state.isLoading = false;
            state.isError = false;
            state.isSuccess = false;
            state.statusMessage = "";
        },
    }, extraReducers: (builder) => {
        builder
            .addCase(getSettingsByCategory.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getSettingsByCategory.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state[action.payload.key] = action.payload.data;
            })
            .addCase(getSettingsByCategory.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.statusMessage = action.payload;
            })
            .addCase(importExcelFileRentRolls.pending, (state) => {
                state.importExcelFileRentRollsLoading = true;
            })
            .addCase(importExcelFileRentRolls.fulfilled, (state, action) => {
                state.importExcelFileRentRollsLoading = false;
                state.isSuccess = true;
                state.importExcelFileRentRolls = action.payload;
            })
            .addCase(importExcelFileRentRolls.rejected, (state, action) => {
                state.importExcelFileRentRollsLoading = false;
                state.isError = true;
                state.statusMessage = action.payload;
            })
            .addCase(importExcelFileAssets.pending, (state) => {
                state.importExcelFileAssetsLoading = true;
            })
            .addCase(importExcelFileAssets.fulfilled, (state, action) => {
                state.importExcelFileAssetsLoading = false;
                state.isSuccess = true;
                state.importExcelFileAssets = action.payload;
            })
            .addCase(importExcelFileAssets.rejected, (state, action) => {
                state.importExcelFileAssetsLoading = false;
                state.isError = true;
                state.statusMessage = action.payload;
            })
            .addCase(exportExcelFileAssets.pending, (state) => {
                state.exportExcelFileAssetsLoading = true;
            })
            .addCase(exportExcelFileAssets.fulfilled, (state, action) => {
                state.exportExcelFileAssetsLoading = false;
                state.isSuccess = true;
            })
            .addCase(exportExcelFileAssets.rejected, (state, action) => {
                state.exportExcelFileAssetsLoading = false;
                state.isError = true;
                state.statusMessage = action.payload;
            })
    },
});

export const {clearData} = createSettingsSlice.actions;

export default createSettingsSlice.reducer;
