import {RentRollDto} from "reia-rest-client";
import moment, {Moment} from "moment";

import {DCFOutput} from "reia-dcf-client";
import {DCFParameters} from "reia-rest-client";
import {getTerminalRentTypeFromI18nKey} from "./dcfHelper";
import {CalculationDetailDto} from "reia-rest-client";
import {TenantCashFlow} from "reia-dcf-client";
import {AssetKPIsDto} from "reia-rest-client";

//--------------------------------------------- Asset Area Income KPIs -----------------------------------------------//
//--------------------------------------------------------------------------------------------------------------------//
interface AreaAndIncomeDetail {
    totalArea: number,
    let: number,
    vacant: number,
    vacancy: number,
    grossRentalIncomeSqm: number,
    grossRentalIncomeYear: number,
    nonRecoverableCostsPercentage: number,
    nonRecoverableCostsYear: number,
    netOperatingIncomeSqm: number,
    netOperatingIncomeYear: number,
    marketRentalIncomeSqm: number,
    marketRentalIncomeYear: number,
    marketRentalIncomeLetSqm: number,
    marketRentalIncomeLetYear: number,
    walt: number,
    rentalLevel: number
}
interface AreaAndIncomeDetailByRentalType extends AreaAndIncomeDetail{
    rentalType: string
}
interface AreaAndIncomeDetailByUseType extends AreaAndIncomeDetailByRentalType{
    useTypeName: string,
    useType: string
}
interface AreaAndIncomeDetails {
    totalValues: AreaAndIncomeDetail,
    totalRentalTypeValues:  { [key: string]: AreaAndIncomeDetailByRentalType; },
    useTypeValues: { [key: string]: AreaAndIncomeDetailByUseType; }
}

function getAreaIncomeDetailsZeroValue() : AreaAndIncomeDetails {
    return {
        totalArea: 0,
        let: 0,
        vacant: 0,
        vacancy: 0,
        grossRentalIncomeSqm: 0,
        grossRentalIncomeYear: 0,
        nonRecoverableCostsPercentage: 0,
        nonRecoverableCostsYear: 0,
        netOperatingIncomeSqm: 0,
        netOperatingIncomeYear: 0,
        marketRentalIncomeSqm: 0,
        marketRentalIncomeYear: 0,
        marketRentalIncomeLetSqm: 0,
        marketRentalIncomeLetYear: 0,
        walt: 0,
        rentalLevel: 0
    };
}

function addAreaIncomeSummableValues(rentRollDto: RentRollDto, useTypeValues: AreaAndIncomeDetailByUseType, rentalTypeValues : AreaAndIncomeDetailByRentalType, totalValues : AreaAndIncomeDetail) {
    
    const summableRentRollKeyParam = {
        totalArea: "rentalSpace",
        grossRentalIncomeYear: "currentRentPerYear", 
        marketRentalIncomeYear: "marketRentPerYear"} 
    
    Object.entries(summableRentRollKeyParam).forEach( entry => {
        useTypeValues[entry[0]] += rentRollDto[entry[1]]
        rentalTypeValues[entry[0]] += rentRollDto[entry[1]]
        totalValues[entry[0]] += rentRollDto[entry[1]]
    })
    
    const nonRecoverableCosts = rentRollDto.rentalSpace * rentRollDto.nrctMaintenance/100 
        + rentRollDto.marketRentPerYear * rentRollDto.nrctManagement/100 
        + rentRollDto.marketRentPerYear * rentRollDto.nrctOtherCosts/100

    useTypeValues.grossRentalIncomeYear += nonRecoverableCosts
    rentalTypeValues.grossRentalIncomeYear += nonRecoverableCosts
    totalValues.grossRentalIncomeYear += nonRecoverableCosts

    if(rentRollDto.rentRollStatusType.key === "core-data.rentRollStatusTypes.let"){

        useTypeValues.let += rentRollDto.rentalSpace
        rentalTypeValues.let += rentRollDto.rentalSpace
        totalValues.let += rentRollDto.rentalSpace
        
        useTypeValues.marketRentalIncomeLetYear += rentRollDto.marketRentPerYear
        rentalTypeValues.marketRentalIncomeLetYear += rentRollDto.marketRentPerYear
        totalValues.marketRentalIncomeLetYear += rentRollDto.marketRentPerYear
    }
}

function addAreaIncomeRelativeValues(areaAndIncomeValues : AreaAndIncomeDetail) {

    areaAndIncomeValues.vacant = areaAndIncomeValues.totalArea-areaAndIncomeValues.let
    areaAndIncomeValues.vacancy = areaAndIncomeValues.vacant/areaAndIncomeValues.totalArea
    areaAndIncomeValues.grossRentalIncomeSqm = areaAndIncomeValues.grossRentalIncomeYear/12/areaAndIncomeValues.let
    areaAndIncomeValues.nonRecoverableCostsPercentage = areaAndIncomeValues.nonRecoverableCostsYear/areaAndIncomeValues.marketRentalIncomeYear 
    areaAndIncomeValues.netOperatingIncomeYear = areaAndIncomeValues.grossRentalIncomeYear-areaAndIncomeValues.nonRecoverableCostsYear
    areaAndIncomeValues.netOperatingIncomeSqm = areaAndIncomeValues.netOperatingIncomeYear/12/areaAndIncomeValues.let
    areaAndIncomeValues.marketRentalIncomeSqm = areaAndIncomeValues.marketRentalIncomeYear/12/areaAndIncomeValues.let
    areaAndIncomeValues.marketRentalIncomeLetSqm = areaAndIncomeValues.marketRentalIncomeLetYear/12/areaAndIncomeValues.let
    
    //Rental Level: grossRentalSqm / marketRentalIncomeLetSqm / -1
    areaAndIncomeValues.rentalLevel = (areaAndIncomeValues.grossRentalIncomeSqm/areaAndIncomeValues.marketRentalIncomeLetSqm)-1
    
}

function addAreaIncomeWeightedValues(rentRollDto: RentRollDto, useTypeValues: AreaAndIncomeDetailByUseType, rentalTypeValues : AreaAndIncomeDetailByRentalType, totalValues : AreaAndIncomeDetail, analysisDate : Moment) {
    
    //WALT: Mietdauer ab AnalysisDate * currentRentGewichtet
    let remainingTerm: number = 0
    
    if(rentRollDto.leaseEndDate){
        const leaseEndDate: Moment = moment(rentRollDto.leaseEndDate);
        
        if(rentRollDto.rentRollStatusType.key === "core-data.rentRollStatusTypes.let" && leaseEndDate.isSameOrAfter(analysisDate) ){
            remainingTerm = leaseEndDate.diff(analysisDate,"days")/365.25
        }    
    }
    
    const currentRentWeight = rentRollDto.currentRentPerYear/useTypeValues.grossRentalIncomeYear
    const walt = remainingTerm * currentRentWeight

    useTypeValues.walt += walt
    rentalTypeValues.walt += walt * rentRollDto.currentRentPerYear/rentalTypeValues.grossRentalIncomeYear
    totalValues.walt += walt *  rentRollDto.currentRentPerYear/totalValues.grossRentalIncomeYear
    
}

export const getAreaAndIncomeDetails = (rentRolls : RentRollDto[], analysisDate : Moment) : AreaAndIncomeDetails => {
    const areaAndIncomeDetails : AreaAndIncomeDetails = {
        totalValues: getAreaIncomeDetailsZeroValue(),
        totalRentalTypeValues: {},
        useTypeValues: {},
    }
    
    rentRolls.forEach(rentRollDto => {
        const useType = rentRollDto?.useType?.key ? rentRollDto?.useType?.key : "Unknown" 
        const useTypeName = rentRollDto?.useType?.translations?.en ? rentRollDto?.useType?.translations?.en : "Unnamed"
        const rentalType =  rentRollDto?.useType?.values?.unitType ? rentRollDto?.useType?.values?.unitType : "Unknown"
        
        if(!areaAndIncomeDetails.useTypeValues.hasOwnProperty(useType)){
            areaAndIncomeDetails.useTypeValues[useType] = getAreaIncomeDetailsZeroValue();
            areaAndIncomeDetails.useTypeValues[useType].rentalType = rentalType
            areaAndIncomeDetails.useTypeValues[useType].useTypeName = useTypeName
            areaAndIncomeDetails.useTypeValues[useType].useType = useType
        }
        
        if(!areaAndIncomeDetails.totalRentalTypeValues.hasOwnProperty(rentalType)){
            areaAndIncomeDetails.totalRentalTypeValues[rentalType] = getAreaIncomeDetailsZeroValue();
            areaAndIncomeDetails.totalRentalTypeValues[rentalType].rentalType = rentalType
        }

        const useTypeValues: AreaAndIncomeDetailByUseType = areaAndIncomeDetails.useTypeValues[useType] ;
        const rentalTypeValues: AreaAndIncomeDetailByRentalType = areaAndIncomeDetails.totalRentalTypeValues[rentalType];
        
        addAreaIncomeSummableValues(rentRollDto, useTypeValues, rentalTypeValues, areaAndIncomeDetails.totalValues);
    })
    
    addAreaIncomeRelativeValues(areaAndIncomeDetails.totalValues);
    
    Object.values(areaAndIncomeDetails.totalRentalTypeValues).forEach(rentalTypeValues => addAreaIncomeRelativeValues(rentalTypeValues))
    Object.values(areaAndIncomeDetails.useTypeValues).forEach(useTypeValues => addAreaIncomeRelativeValues(useTypeValues))

    rentRolls.forEach(rentRollDto => {
        const useType = rentRollDto?.useType?.key ? rentRollDto?.useType?.key : "Unknown"
        const rentalType =  rentRollDto?.useType?.values?.unitType ? rentRollDto?.useType?.values?.unitType : "Unknown"
        const useTypeValues: AreaAndIncomeDetailByUseType = areaAndIncomeDetails.useTypeValues[useType]
        const rentalTypeValues: AreaAndIncomeDetailByRentalType = areaAndIncomeDetails.totalRentalTypeValues[rentalType]

        addAreaIncomeWeightedValues(rentRollDto, useTypeValues, rentalTypeValues, areaAndIncomeDetails.totalValues, analysisDate);
    })
    
    return areaAndIncomeDetails;
}


//--------------------------------------------- Asset KPIs -----------------------------------------------------------//
//--------------------------------------------------------------------------------------------------------------------//

function addAssetKpiSummableValues(rentRollDto: RentRollDto, assetKPIs: AssetKPIsDto) {

    const summableRentRollKeyParam = {
        totalArea: "rentalSpace"
    }

    Object.entries(summableRentRollKeyParam).forEach( entry => {
        assetKPIs[entry[0]] += rentRollDto[entry[1]]
    })

    if(rentRollDto.rentRollStatusType.key === "core-data.rentRollStatusTypes.let"){
        assetKPIs.let += rentRollDto.rentalSpace
    }else{
        assetKPIs.vacant += rentRollDto.rentalSpace
    }
}

function addAssetKpiWeightedValues(rentRollDto: RentRollDto, assetKPIs: AssetKPIsDto, analysisDate : Moment, dcfResult: DCFOutput) {
    
    //WALT: Mietdauer ab AnalysisDate * currentRentGewichtet
    let remainingTerm: number = 0

    if(rentRollDto.leaseEndDate){
        const leaseEndDate: Moment = moment(rentRollDto.leaseEndDate);
        if(rentRollDto.rentRollStatusType.key === "core-data.rentRollStatusTypes.let" && leaseEndDate.isSameOrAfter(analysisDate) ){
            remainingTerm = leaseEndDate.diff(analysisDate,"days")/365.25
        }
    }

    const tenantCashFlow: TenantCashFlow = dcfResult.tenantCashFlows.find(cashFlow => ""+rentRollDto.id === cashFlow.rentRollId)
    if(tenantCashFlow)
    {
        const currentRentWeight = tenantCashFlow.grossRentalIncome[0]/dcfResult.assetCashFlow.totalGrossRentalIncome[0]
        const walt = remainingTerm * currentRentWeight
        if(!isNaN(walt))
        assetKPIs.walt += walt    
    }
}

function calcYieldValues (assetKpis:AssetKPIsDto, dcfResult:DCFOutput) {
    
    if(dcfResult?.assetCashFlow) {
        const assetCashFlow = dcfResult.assetCashFlow
        
            assetKpis.netInitialYieldCurrentRent= (assetCashFlow.totalNetOperatingIncome[0] * 12) / assetCashFlow.grossAssetValue
            assetKpis.grossInitialYieldCurrentRent= (assetCashFlow.totalGrossRentalIncome[0] * 12) / assetCashFlow.netAssetValue

            assetKpis.netInitialYieldPotentialRent= ((assetCashFlow.totalPotentialRent[0] -
                (assetCashFlow.totalNonRecs[0] + assetCashFlow.otherIncomeCosts_beforeNOI[0])) * 12) / assetCashFlow.grossAssetValue
            assetKpis.grossInitialYieldPotentialRent= (assetCashFlow.totalPotentialRent[0] * 12) / assetCashFlow.netAssetValue

            assetKpis.netInitialYieldMarketRent= ((assetCashFlow.totalMArketRent[0] -
                (assetCashFlow.totalNonRecs[0] + assetCashFlow.otherIncomeCosts_beforeNOI[0])) * 12) / assetCashFlow.grossAssetValue
            assetKpis.grossInitialYieldMarketRent= (assetCashFlow.totalMArketRent[0] * 12) / assetCashFlow.netAssetValue

            assetKpis.netInitialYieldTerminalCurrentRent= (assetCashFlow.totalNetOperatingIncome?.at(-1) * 12) / assetKpis.grossTerminalAssetValue
            assetKpis.grossInitialYieldTerminalCurrentRent= (assetCashFlow.totalGrossRentalIncome?.at(-1) * 12) / assetKpis.netTerminalAssetValue

            assetKpis.netInitialYieldTerminalPotentialRent= ((assetCashFlow.totalPotentialRent?.at(-1) -
                (assetCashFlow.totalNonRecs?.at(-1) + assetCashFlow.otherIncomeCosts_beforeNOI?.at(-1))) * 12) / assetKpis.grossTerminalAssetValue
            assetKpis.grossInitialYieldTerminalPotentialRent= (assetCashFlow.totalPotentialRent?.at(-1) * 12) / assetKpis.netTerminalAssetValue

            assetKpis.netInitialYieldTerminalMarketRent= ((assetCashFlow.totalMArketRent?.at(-1) -
                (assetCashFlow.totalNonRecs?.at(-1) + assetCashFlow.otherIncomeCosts_beforeNOI?.at(-1))) * 12) / assetKpis.grossTerminalAssetValue
            assetKpis.grossInitialYieldTerminalMarketRent= (assetCashFlow.totalMArketRent?.at(-1) * 12) / assetKpis.netTerminalAssetValue

            assetKpis.multipleInitialYieldCurrentRent = 1/assetKpis.grossInitialYieldCurrentRent
            assetKpis.multipleInitialYieldMarketRent = 1/assetKpis.grossInitialYieldMarketRent
            assetKpis.multipleInitialYieldPotentialRent = 1/assetKpis.grossInitialYieldPotentialRent
            
            assetKpis.multipleInitialYieldTerminalCurrentRent = 1/assetKpis.grossInitialYieldTerminalCurrentRent
            assetKpis.multipleInitialYieldTerminalMarketRent =1/assetKpis.grossInitialYieldTerminalMarketRent
            assetKpis.multipleInitialYieldTerminalPotentialRent =1/assetKpis.grossInitialYieldTerminalPotentialRent
    }
}

export const calculateAssetKpis = (calculationDetail : CalculationDetailDto, dcfResult: DCFOutput, assetDCFParams: DCFParameters) :AssetKPIsDto => {
    
    const analysisDate : Moment = calculationDetail.analysisDate ? moment(calculationDetail.analysisDate) : null
    const rentRolls : RentRollDto[] = calculationDetail.rentRolls ? Object.values(calculationDetail.rentRolls) : null
    
    if(dcfResult?.assetCashFlow && analysisDate && rentRolls)
    {
        console.log("Calc assetKPIs")
        const accCostPercentage: number =
            assetDCFParams?.landTransferTaxExit +
            assetDCFParams?.agentCostsExit +
            assetDCFParams?.notaryCostsExit;

        const terminalType = assetDCFParams?.terminalValueType ? getTerminalRentTypeFromI18nKey(assetDCFParams?.terminalValueType?.key) : 1;

        let grossTerminalAssetValue;
        let netTerminalAssetValue;
        if (terminalType === 1) {
            grossTerminalAssetValue = dcfResult?.assetCashFlow?.terminalValue;
            netTerminalAssetValue = (grossTerminalAssetValue * 100) / (100 + accCostPercentage);

        } else if (terminalType === 2) {
            netTerminalAssetValue = dcfResult?.assetCashFlow?.terminalValue;
            grossTerminalAssetValue = (netTerminalAssetValue * (100 + accCostPercentage)) / 100;
        }

        const assetKpis : AssetKPIsDto = {
            totalArea: 0,
            grossRentalIncome: dcfResult.assetCashFlow.totalGrossRentalIncome[0],
            grossAssetValue: dcfResult.assetCashFlow.grossAssetValue,
            netAssetValue: dcfResult.assetCashFlow.netAssetValue,
            grossTerminalAssetValue: grossTerminalAssetValue,
            netTerminalAssetValue: netTerminalAssetValue,
            grossAssetValueSqm: 0,
            netAssetValueSqm: 0,
            grossTerminalAssetValueSqm: 0,
            netTerminalAssetValueSqm: 0,
            walt: 0,
            let: 0,
            vacant: 0,
            vacancy: 0,
            netInitialYieldCurrentRent: 0,
            netInitialYieldMarketRent: 0,
            netInitialYieldPotentialRent: 0,
            grossInitialYieldCurrentRent: 0,
            grossInitialYieldMarketRent: 0,
            grossInitialYieldPotentialRent: 0,
            multipleInitialYieldCurrentRent: 0,
            multipleInitialYieldMarketRent: 0,
            multipleInitialYieldPotentialRent: 0,
            netInitialYieldTerminalCurrentRent: 0,
            netInitialYieldTerminalMarketRent: 0,
            netInitialYieldTerminalPotentialRent: 0,
            grossInitialYieldTerminalCurrentRent: 0,
            grossInitialYieldTerminalMarketRent: 0,
            grossInitialYieldTerminalPotentialRent: 0,
            multipleInitialYieldTerminalCurrentRent: 0,
            multipleInitialYieldTerminalMarketRent: 0,
            multipleInitialYieldTerminalPotentialRent: 0,
        };

        rentRolls.forEach((rentRollDto:RentRollDto) => addAssetKpiSummableValues(rentRollDto,assetKpis))
        rentRolls.forEach((rentRollDto:RentRollDto) => addAssetKpiWeightedValues(rentRollDto,assetKpis,analysisDate,dcfResult))
        calcYieldValues(assetKpis,dcfResult)
        
        //Relative values
        assetKpis.vacancy = assetKpis.vacant/assetKpis.totalArea
        assetKpis.grossAssetValueSqm = assetKpis.grossAssetValue/assetKpis.let
        assetKpis.grossTerminalAssetValueSqm = assetKpis.grossTerminalAssetValue/assetKpis.let
        assetKpis.netAssetValueSqm = assetKpis.netAssetValue/assetKpis.let
        assetKpis.netTerminalAssetValueSqm = assetKpis.netTerminalAssetValue/assetKpis.let
        
        return assetKpis
    }
    
    return null;
} 