/* eslint-disable react/require-default-props */
import { Button, Input, InputNumber } from "antd";
import { useEffect, useState } from "react";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import PropTypes from "prop-types";

function IncDecCounter(props) {
  const { className, value, min, max, incDecStep, setIncDecValue, label, placeholder } = props;
  const [num, setNumState] = useState(value);

  useEffect(() => {
    setNumState(Number(value))
  }, [value]);

  const incNum = () => {
    if (num <= max) {
      persistNewValue(num + incDecStep);
    }
  };

  const decNum = () => {
    if (num >= min) {
      persistNewValue(num - incDecStep);
    }
  };

  const onChange = (newValue) => {
    console.log("ONCHANGE")
    console.log(newValue.target.value)
    if(newValue.target.value === "" || newValue.target.value === undefined || newValue.target.value === null || newValue.target.value.length === 0){
      persistNewValue(null);
    }
    else if(!isNaN(newValue.target.value)){
      const newNumber = parseInt(newValue.target.value)
      if (newNumber >= min && newNumber <= max)
        persistNewValue(newNumber);
    }
  };

  const persistNewValue = (newValue) => {
    console.log(newValue)
    setNumState(newValue)
    if (setIncDecValue !== null) {
      setIncDecValue(Number(newValue));
    }
  }

  return (
    <div
      className={`counter-field p-0 ${props.disabled ? "counter-field-disabled" : ""} d-flex align-items-center justify-content-between ${className}`}
    >
      <Button
        disabled={props.disabled}
        icon={<AiOutlineMinus />}
        onClick={decNum}
        className="dec-num-btn"
      />
      <div className="incDec__inner">
        <Input placeholder={placeholder} disabled={props.disabled} className="noBorder" style={{ color: '#fff' }} value={num} onChange={onChange} />
        <span>{value}</span>
      </div>
      <Button
        disabled={props.disabled}
        icon={<AiOutlinePlus />}
        onClick={incNum}
        className="inc-num-btn"
      />
    </div>
  );
}

export default IncDecCounter;

IncDecCounter.propTypes = {
  className: PropTypes.string.isRequired,
  value: PropTypes.number,
  label: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  incDecStep: PropTypes.number,
  disabled: PropTypes.bool,
  setIncDecValue: PropTypes.func,
};
IncDecCounter.defaultProps = {
  label: "",
  className: "",
  incDecStep: 1,
  value: 0,
  min: -999,
  max: 999,
  disabled: false,
  setIncDecValue: null
};
