import { Card, Col, Input, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import IncDecCounter from "../../../../../../../../components/Layout/IncDecCounter";
import { getFormattedDecimalNumber, getFormattedEnglishNumber, getFormattedInt } from "../../../../../../../../utils/digitHelper";
import {
  getPerSpaceValuePortfolio,
  getPortfolioAccCosts,
} from "../../../../../../../../utils/portfolioHelper";

function VeAcquisitionCosts(props) {
  const { calculationDetailsByPortfolio, dcfResultsModified } = useSelector((state) => state.portfolio);
  const [accValues, setAccValues] = useState(null)
  useEffect(() => {

    const { grossValue, netValue, landTransferValue, agentCostsValue, notaryCostsValue, totalAcquisitionCostsValue } = getPortfolioAccCosts(calculationDetailsByPortfolio?.calculationDetailDtos, dcfResultsModified, props.terminalValue)

    const grossCapitalValue = getPerSpaceValuePortfolio(grossValue, calculationDetailsByPortfolio?.calculationDetailDtos)
    const landTransferTax = landTransferValue / netValue * 100;
    const agentCosts_Sale = agentCostsValue / netValue * 100;
    const notaryCosts = notaryCostsValue / netValue * 100;


    setAccValues({
      grossValue, netValue, grossCapitalValue, landTransferValue, landTransferTax, agentCosts_Sale, agentCostsValue, notaryCosts, notaryCostsValue, totalAcquisitionCostsValue
    })

  }, [calculationDetailsByPortfolio, dcfResultsModified])

  return (
    <div>
      <Card bordered={false} className="assets_card">
        <Row gutter={24} className="align-items-center pb-3 assets_card__header">
          <Col xs={10}>
            <h3 className="text-black mb-0">{"Acquisition Costs" + (props.terminalValue ? "  @Exit" : "")}</h3>
          </Col>
          <Col xs={4}>
            <h3 className="text-black mb-0">{getFormattedDecimalNumber(accValues?.landTransferTax + accValues?.agentCosts_Sale + accValues?.notaryCosts)} %</h3>
          </Col>
          <Col xs={7}>
            <h4 className="mb-0 font-weight-normal" style={{
              color: '#797979'
            }}>Steps</h4>
          </Col>
          <Col xs={3} className="d-flex justify-content-end"></Col>
        </Row>
        <hr className="mt-0 border-top-0 border-2" />
        <Row gutter={24} className="pt-2 d-flex justify-content-between">
          <Col xs={props.showDrawer ? 6 : 8} className="align-items-center my-2">
            <h4 className="mb-0 text-black-50 inner_progress">Transfer Tax in %</h4>
          </Col>
          <Col xs={6}>
            <IncDecCounter
              value={getFormattedDecimalNumber(accValues?.landTransferTax) || 0}
              disabled={true}
              className="m-0"
              label="%"
              min={0} max={100} incDecStep={0.5}
            />
          </Col>
          <Col xs={6}>
            <Input
              style={{
                border: "1px solid #B7B7B7",
                padding: "12px",
                width: '110px'
              }}
              type='number'
              value={50}
              disabled={true}
            />
          </Col>
          <Col
            xs={props.showDrawer ? 6 : 4}
            className="d-flex align-items-center my-2 justify-content-end"
          >
            <h4 className="mb-0 text-black-50">{getFormattedEnglishNumber(accValues?.landTransferValue)} €</h4>
          </Col>
        </Row>
        <Row gutter={24} className="pt-2 d-flex justify-content-between">
          <Col xs={props.showDrawer ? 6 : 8} className="align-items-center my-2">
            <h4 className="mb-0 text-black-50 inner_progress">Agent Costs in %</h4>
          </Col>
          <Col xs={6}>
            <IncDecCounter
              value={getFormattedDecimalNumber(accValues?.agentCosts_Sale) || 0}
              disabled={true}
              className="m-0"
              label="%"
              min={0} max={100} incDecStep={0.5}
            />
          </Col>
          <Col xs={6}>
            <Input
              style={{
                border: "1px solid #B7B7B7",
                padding: "12px",
                width: '110px'
              }}
              type='number'
              value={50}
              disabled={true}
            />
          </Col>
          <Col
            xs={props.showDrawer ? 6 : 4}
            className="d-flex align-items-center my-2 justify-content-end"
          >
            <h4 className="mb-0 text-black-50">{getFormattedEnglishNumber(accValues?.agentCostsValue)} €</h4>
          </Col>
        </Row>
        <Row gutter={24} className="pt-2 d-flex justify-content-between">
          <Col xs={props.showDrawer ? 6 : 8} className="align-items-center my-2">
            <h4 className="mb-0 text-black-50 inner_progress">Notary Costs in %</h4>
          </Col>
          <Col xs={6}>
            <IncDecCounter
              value={getFormattedDecimalNumber(accValues?.notaryCosts) || 0}
              disabled={true}
              className="m-0"
              label="%"
              min={0} max={100} incDecStep={0.5}
            />
          </Col>
          <Col xs={6}>
            <Input
              style={{
                border: "1px solid #B7B7B7",
                padding: "12px",
                width: '110px'
              }}
              type='number'
              value={50}
              disabled={true}
            />
          </Col>
          <Col
            xs={props.showDrawer ? 6 : 4}
            className="d-flex align-items-center my-2 justify-content-end"
          >
            <h4 className="mb-0 text-black-50">{getFormattedEnglishNumber(accValues?.notaryCostsValue)} €</h4>
          </Col>
        </Row>
        {/* <Row gutter={24} className="pt-2 mb-2 d-flex justify-content-between">
          <Col xs={8} className="align-items-center my-2">
            <h4 className="mb-0 text-black">Total acuisition Costs</h4>
          </Col>
          <Col
            xs={8}
            className="d-flex align-items-center my-2 justify-content-center"
          >
            <h4 className="mb-0 text-black">{accValues?.landTransferTax + accValues?.agentCosts_Sale + accValues?.notaryCosts} %</h4>
          </Col>
          <Col
            xs={8}
            className="d-flex align-items-center my-2 justify-content-end"
          >
            <h4 className="mb-0 text-black">{getFormattedInt(accValues?.totalAcquisitionCostsValue)} €</h4>
          </Col>
        </Row>
        <Row
          gutter={24}
          className="d-flex justify-content-between"
          style={{ "background-color": "#F2F4F7", borderRadius: "5px" }}
        >
          <Col xs={8} className="align-items-center my-2">
            <h4 className="mb-0 text-blue">{"Gross " + (props.terminalValue ? "Terminal" : "Portfolio") + " Value"}</h4>
          </Col>
          <Col
            xs={8}
            className="d-flex align-items-center my-2  justify-content-center"
          >
            <h4 className="mb-0 text-blue">{accValues?.grossCapitalValue}  €/sqm</h4>
          </Col>
          <Col
            xs={8}
            className="d-flex align-items-center my-2 justify-content-end"
          >
            <h4 className="mb-0 text-blue">{getFormattedInt(accValues?.grossValue)} €</h4>
          </Col>
        </Row> */}
      </Card>
    </div>
  );
}

export default VeAcquisitionCosts;
