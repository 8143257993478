/* eslint-disable jsx-a11y/label-has-associated-control */
import { Modal, Col, Row, Input, Button, Form, Select } from "antd";
// import { FiSearch } from "react-icons/fi";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Option } from "antd/lib/mentions";
import { useEffect, useState } from "react";
import {
  resetProjectFilters,
  updateProjectFilters,
} from "../../../../../app/features/projectDetails/projectDetail.slice";
import {
  getAllCities,
  getAllCountry,
  getAllState,
  getByTypeDropdown,
} from "../../../../../app/features/Lookups/lookup.slice";
// import { useState } from "react";
// import { useState } from "react";
// import { useDispatch } from "react-redux";
// import { useParams } from "react-router-dom";

function SearchForm(props) {
  const { visible, handleCancel, setSearchAsset } = props;
  const {
    country,
    states,
    cities,
    riskClassType,
    priorityType,
    ownershipType,
    InspectionType,
  } = useSelector((state) => state.lookUps);

  const [countryIdData, setCountryIdData] = useState();
  const [stateId, setStateId] = useState();
  const [form] = Form.useForm();
  // const { Search } = Input;
  // const options = [];
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllCountry());
  }, [dispatch]);

  useEffect(() => {
    const finalData = {
      type: "riskClassType",
    };
    dispatch(getByTypeDropdown(finalData));
  }, [dispatch]);

  useEffect(() => {
    const finalData = {
      type: "priorityType",
    };
    dispatch(getByTypeDropdown(finalData));
  }, [dispatch]);

  useEffect(() => {
    const finalData = {
      type: "ownershipType",
    };
    dispatch(getByTypeDropdown(finalData));
  }, [dispatch]);

  useEffect(() => {
    const finalData = {
      type: "InspectionType",
    };
    dispatch(getByTypeDropdown(finalData));
  }, [dispatch]);

  const onFinish = (data) => {
    const finalValue = {
      search: data.search,
      assetId: data.assetId,
      countryId: data.countryId,
      stateId: data.stateId,
      cityId: data.cityId,
      street: data.street,
      riskClassId: data.riskClassId,
      upsidePotentialId: data.upsidePotentialId,
      capexRequirementId: data.capexRequirementId,
      expectedLiquidityId: data.expectedLiquidityId,
      managementRequirementId: data.managementRequirementId,
      ownershipId: data.ownershipId,
      inspectionTypeId: data.inspectionTypeId,
      selection: data.selection,
    };
    setSearchAsset(finalValue);
    handleCancel();
  };

  const handleValues = (e) => {
    dispatch(updateProjectFilters({ key: e.target.id, value: e.target.value }));
  };
  const selectHandleValues = (e, value) => {
    const filterKey = value.name;
    const filterValue = value.value;
    dispatch(updateProjectFilters({ key: filterKey, value: filterValue }));
  };

  const getStates = (e) => {
    // setStateId("");
    selectHandleValues("", { name: "stateId", value: "" });
    selectHandleValues("", { name: "cityId", value: "" });
    form.setFieldValue("stateId", "");
    form.setFieldValue("cityId", "");

    const finalData = {
      countryId: e,
    };
    dispatch(getAllState(finalData));
  };

  const getCities = (e) => {
    selectHandleValues("", { name: "cityId", value: "" });
    form.setFieldValue("cityId", "");
    const finalData = {
      stateId: e,
    };
    dispatch(getAllCities(finalData));
  };

  const onReset = () => {
    form.resetFields();
    dispatch(getAllState(0));
    dispatch(resetProjectFilters());
    dispatch(getAllCities(0));
  };
  return (
    <Modal
      className="search-modals assets "
      open={visible}
      handleCancel={handleCancel}
      onCancel={handleCancel}
      centered
      // width={600}
      // style={{
      //   top: 80,
      //   // left: 115,
      // }}
      footer={false}
    >
      <Form
        onFinish={onFinish}
        autoComplete="off"
        className="add-new-org-class"
        form={form}
        layout="vertical"
      >
        <h2>Filters</h2>
        {/* <Row>
          <Col span={24}>
            <Form.Item
              name="search"
              rules={[
                {
                  required: false,
                  message: "Please input your Admin Name!",
                },
              ]}
            >
              <Input.Search
                className="mr-2"
                placeholder="Search Asset"
                allowClear
              />
            </Form.Item>
            <span className="searchbar-icon">
              <FiSearch size={20} />
            </span>
          </Col>
        </Row> */}
        <br />

        <Row className="row d-flex search-form-filter-row" gutter={16}>
          <Col xs={24} sm={12} lg={8} className="mb-2">
            {/* <Cascader
              className="w-100"
              options={options}
              placeholder="Asset Reference"
            /> */}
            <label className="mb-1 d-block">Asset Id</label>
            <Form.Item
              name="assetId"
              rules={[
                {
                  required: false,
                  message: "Please input your asset Id!",
                },
              ]}
            >
              <Input.Search
                className="mr-2"
                placeholder="Search Asset Id"
                allowClear
                onChange={(e) => handleValues(e)}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} lg={8} className="mb-2">
            <label className="mb-1 d-block">Street</label>

            <Form.Item
              name="street"
              rules={[
                {
                  required: false,
                  message: "Please input your street!",
                },
              ]}
            >
              <Input.Search
                className="mr-2"
                placeholder="Street"
                allowClear
                onChange={(e) => handleValues(e)}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} lg={8} className="mb-2">
            <label className="mb-1 d-block">Selection</label>

            <Form.Item
              name="selection"
              rules={[
                {
                  required: false,
                  message: "Please input your Selection!",
                },
              ]}
            >
              <Input.Search
                className="mr-2"
                placeholder="Selection"
                allowClear
                onChange={(e) => handleValues(e)}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} lg={8} className="mb-2">
            <label className="mb-1 d-block">Country </label>

            <Form.Item
              name="countryId"
              rules={[
                { required: false, message: "Please input your Country!" },
              ]}
            >
              <Select
                placeholder="Select Country"
                allowClear
                onClear={() => {
                  selectHandleValues("", { name: "countryId", value: "" });
                }}
                value={countryIdData}
                // onSelect={(e, value) => selectHandleValues(e, value)}
                name="countryId"
                onChange={(e, value) => {
                  setCountryIdData(e);
                  getStates(e);
                  getCities();
                  selectHandleValues(e, value);
                  // getStates(e.target.value);
                }}
              >
                {country?.map((item) => (
                  <Option
                    key={item.countryId}
                    name="countryId"
                    value={item.countryId}
                  >
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} lg={8} className="mb-2">
            <label className="mb-1 d-block">State (Federal) </label>

            <Form.Item
              // label="Federal State"
              name="stateId"
              rules={[
                {
                  required: false,
                  message: "Please input your Federal State!",
                },
              ]}
            >
              <Select
                placeholder="Select State"
                allowClear
                value={stateId}
                onClear={() => {
                  selectHandleValues("", { name: "stateId", value: "" });
                }}
                onChange={(e, value) => {
                  setStateId(e);
                  getCities(e);
                  selectHandleValues(e, value);
                }}
              >
                {states?.map((item) => (
                  <Option
                    key={item.stateId}
                    name="stateId"
                    value={item.stateId}
                  >
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} lg={8} className="mb-2">
            <label className="mb-1 d-block">City </label>

            <Form.Item
              name="cityId"
              rules={[{ required: false, message: "Please input your City!" }]}
            >
              <Select
                onClear={() => {
                  selectHandleValues("", { name: "cityId", value: "" });
                }}
                onChange={(e, value) => {
                  selectHandleValues(e, value);
                }}
                placeholder="Select City"
                allowClear
              >
                {cities?.map((item) => (
                  <Option key={item.cityId} name="cityId" value={item.cityId}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} sm={12} lg={8} className="mb-2">
            <label className="mb-1 d-block">Risk Class </label>

            <Form.Item
              name="riskClassId"
              rules={[
                { required: false, message: "Please input your RiskClassId!" },
              ]}
            >
              <Select
                onChange={(e, value) => {
                  selectHandleValues(e, value);
                }}
                onClear={() => {
                  selectHandleValues("", { name: "riskClassId", value: "" });
                }}
                placeholder="Risk Class"
                allowClear
              >
                {riskClassType?.map((item) => (
                  <Option
                    key={item.lookUpId}
                    name="riskClassId"
                    value={item.lookUpId}
                  >
                    {item.text}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} lg={8} className="mb-2">
            <label className="mb-1 d-block">Up side Potential </label>

            <Form.Item
              name="upsidePotentialId"
              rules={[
                {
                  required: false,
                  message: "Please input your UpsidePotentialId!",
                },
              ]}
            >
              <Select
                onChange={(e, value) => {
                  selectHandleValues(e, value);
                }}
                onClear={() => {
                  selectHandleValues("", {
                    name: "upsidePotentialId",
                    value: "",
                  });
                }}
                placeholder="Upside Potential"
                allowClear
              >
                {priorityType?.map((item) => (
                  <Option
                    name="upsidePotentialId"
                    key={item.lookUpId}
                    value={item.lookUpId}
                  >
                    {item.text}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} lg={8} className="mb-2">
            <label className="mb-1 d-block">Capex Requirement</label>

            <Form.Item
              name="capexRequirementId"
              rules={[
                {
                  required: false,
                  message: "Please input your CapexRequirementId!",
                },
              ]}
            >
              <Select
                onChange={(e, value) => {
                  selectHandleValues(e, value);
                }}
                onClear={() => {
                  selectHandleValues("", {
                    name: "capexRequirementId",
                    value: "",
                  });
                }}
                placeholder="Capex Requirement"
                allowClear
              >
                {priorityType?.map((item) => (
                  <Option
                    name="capexRequirementId"
                    key={item.lookUpId}
                    value={item.lookUpId}
                  >
                    {item.text}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} lg={8} className="mb-2">
            <label className="mb-1 d-block">Expected Liquidity</label>

            <Form.Item
              name="expectedLiquidityId"
              rules={[
                {
                  required: false,
                  message: "Please input your ExpectedLiquidityId!",
                },
              ]}
            >
              <Select
                onChange={(e, value) => {
                  selectHandleValues(e, value);
                }}
                onClear={() => {
                  selectHandleValues("", {
                    name: "expectedLiquidityId",
                    value: "",
                  });
                }}
                placeholder="Expected Liquidity"
                allowClear
              >
                {priorityType?.map((item) => (
                  <Option
                    name="expectedLiquidityId"
                    key={item.lookUpId}
                    value={item.lookUpId}
                  >
                    {item.text}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} lg={8} className="mb-2">
            <label className="mb-1 d-block">Asset management Requirement</label>

            <Form.Item
              name="managementRequirementId"
              rules={[
                {
                  required: false,
                  message: "Please input your ManagementRequirementId!",
                },
              ]}
            >
              <Select
                onChange={(e, value) => {
                  selectHandleValues(e, value);
                }}
                onClear={() => {
                  selectHandleValues("", {
                    name: "managementRequirementId",
                    value: "",
                  });
                }}
                placeholder="Management Requirement"
                allowClear
              >
                {priorityType?.map((item) => (
                  <Option
                    name="managementRequirementId"
                    key={item.lookUpId}
                    value={item.lookUpId}
                  >
                    {item.text}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} sm={12} lg={8} className="mb-2">
            <label className="mb-1 d-block">Ownership</label>

            <Form.Item
              name="ownershipId"
              rules={[
                {
                  required: false,
                  message: "Please input your OwnershipId!",
                },
              ]}
            >
              <Select
                onChange={(e, value) => {
                  selectHandleValues(e, value);
                }}
                onClear={() => {
                  selectHandleValues("", { name: "ownershipId", value: "" });
                }}
                placeholder="Ownership"
                allowClear
              >
                {ownershipType?.map((item) => (
                  <Option
                    name="ownershipId"
                    key={item.lookUpId}
                    value={item.lookUpId}
                  >
                    {item.text}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} lg={8} className="mb-2">
            <label className="mb-1 d-block">Inspection Type</label>

            <Form.Item
              name="inspectionTypeId"
              rules={[
                {
                  required: false,
                  message: "Please input your InspectionTypeId!",
                },
              ]}
            >
              <Select
                onChange={(e, value) => {
                  selectHandleValues(e, value);
                }}
                onClear={() => {
                  selectHandleValues("", {
                    name: "inspectionTypeId",
                    value: "",
                  });
                }}
                placeholder="Inspection Type"
                allowClear
              >
                {InspectionType?.map((item) => (
                  <Option
                    name="inspectionTypeId"
                    key={item.lookUpId}
                    value={item.lookUpId}
                  >
                    {item.text}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {/* <Col xs={24} sm={12} lg={8} className="mb-2">
            <label className="mb-1 d-block">Selection</label>

            <Form.Item
              name="selection"
              rules={[
                {
                  required: false,
                  message: "Please input your selection!",
                },
              ]}
            >
              <Select
                onChange={(e, value) => {
                  selectHandleValues(e, value);
                }}
                onClear={() => {
                  selectHandleValues("", { name: "selection", value: "" });
                }}
                placeholder="selection"
                allowClear
              >
                {selectionType?.map((item) => (
                  <Option
                    name="selection"
                    key={item.lookUpId}
                    value={item.lookUpId}
                  >
                    {item.text}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col> */}
        </Row>
        <Row gutter={24}>
          <Col
            xs={24}
            className="align-items-center d-flex justify-content-end mt-2"
            style={{ gap: "16px" }}
          >
            <Button className="btn-cancel" onClick={handleCancel}>
              Cancel
            </Button>
            {/* <Button className="btn-cancel" onClick={handleReset}>
              Reset
            </Button> */}
            <Button className="btn-primary" htmlType="button" onClick={onReset}>
              Reset
            </Button>
            <Button
              key="submit"
              type="default"
              htmlType="submit"
              className="btn-primary"
              // disabled={isLoaded}
            >
              Search
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default SearchForm;
SearchForm.propTypes = {
  visible: PropTypes.bool,
  handleCancel: PropTypes.func,
  setSearchAsset: PropTypes.node.isRequired,
};
