import { Card, Checkbox, Col, Row, notification, Button, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import IncDecCounter from "../../../../../../../../components/Layout/IncDecCounter";
import { getSettingsByCategory } from "../../../../../../../../app/features/settings/settings.slice";
import { getDefaultNotifyToaster } from "../../../../../../../../utils/helpers";
import { getFormattedDecimalNumber, getFormattedEnglishNumber, getFormattedInt } from "../../../../../../../../utils/digitHelper";
import { DownloadOutlined, UndoOutlined } from "@ant-design/icons";
import { CiUndo } from "react-icons/ci";
import { AssetDto } from "reia-rest-client";
import { getPerSpaceValue } from "../../../../../../../../utils/dcfHelper";

function VeAcquisitionCosts({ extraPadding = false }) {
  const dispatch = useDispatch();
  const [bpDiscountRate, setBpDiscountRate] = useState({ land: 50, agent: 50, notary: 50, });
  const { landTaxes, acquisitionCostsDefaults } = useSelector(state => state.settings)
  const calculation = useSelector((state) => state.calculation);
  const { landTransferTax, agentCosts_Sale, notaryCosts } = calculation.assetDCFParams;
  const assetValue = calculation.assetDCFResult?.dcfResult?.assetCashFlow?.grossAssetValue;
  const landTransferValue = calculation.assetDCFResult?.dcfResult?.assetCashFlow?.landTranferTax;
  const agentCostsValue = calculation.assetDCFResult?.dcfResult?.assetCashFlow?.agentCostsAsset;
  const notaryCostsValue = calculation.assetDCFResult?.dcfResult?.assetCashFlow?.notaryCosts;
  const totalAccCostsValue = calculation.assetDCFResult?.dcfResult?.assetCashFlow?.totalAcquisitionCosts;
  const { asset }: { asset: AssetDto } = useSelector((state) => state.assets);
  const notifyToaster = getDefaultNotifyToaster(notification);

  const setAssetDCFParams = (params) => {
    dispatch({ type: "calculation/setAssetDCFParams", payload: params })
  }

  useEffect(() => {
    const finalData = {
      category: "landTaxes",
    };
    dispatch(getSettingsByCategory({ finalData, notifyToaster }));
  }, []);

  useEffect(() => {
    const finalData = {
      category: "acquisitionCostsDefaults",
    };
    dispatch(getSettingsByCategory({ finalData, notifyToaster }));
  }, []);

  useEffect(() => {
    if (landTaxes && acquisitionCostsDefaults && (!landTransferTax || !agentCosts_Sale || !notaryCosts)) {
      setDefaultParams(landTaxes, acquisitionCostsDefaults)
    }

  }, [landTaxes])

  const setDefaultParams = (landTaxes, acquisitionCostsDefaults) => {
    let _agentCosts, _notaryCosts, _landtrasferTax;

    let assetMount = assetValue;

    if (assetMount && assetMount < 0)
      assetMount = parseInt(assetMount) * -1

    const matchedDefaultCosts = Object.values(acquisitionCostsDefaults).filter(defaults => defaults.min <= assetMount && (defaults.max === "MAX" || defaults.max > assetMount)).pop()
    if (matchedDefaultCosts) {
      _agentCosts = parseFloat(matchedDefaultCosts.Agent.replace(",", "."))
      _notaryCosts = parseFloat(matchedDefaultCosts.Notary.replace(",", "."))
      _landtrasferTax = parseFloat(matchedDefaultCosts.LTT.replace(",", "."))
    }

    const assetRegion = asset?.address?.federalState?.translations?.en
    if (assetRegion && landTaxes[assetRegion]) {
      _landtrasferTax = parseFloat(landTaxes[assetRegion].replace(",", ".")) * 100
    }
    setAssetDCFParams({ landTransferTax: _landtrasferTax, agentCosts_Sale: _agentCosts, notaryCosts: _notaryCosts })
  }

  return (
    <div>
      <Card bordered={false} className={`assets_card ${extraPadding ? "acquisition-cost-card" : ''}`}>
        <div className="pb-3 assets_card__header">
          <Row gutter={24} className="align-items-center">
            <Col xs={10}>
              <h3 className="text-black mb-0">Acquisition Costs @Analysis</h3>
            </Col>
            <Col xs={4}>
              <h3 className="text-black mb-0">{getFormattedDecimalNumber(landTransferTax + agentCosts_Sale + notaryCosts)} %</h3>
            </Col>
            <Col xs={7}>
              <h4 className="mb-0 font-weight-normal" style={{
                color: '#797979'
              }}>Steps</h4>
            </Col>
            <Col xs={3} className="d-flex justify-content-end">
              <Button className="d-flex justify-content-center align-items-center" style={{ width: "40px", height: '36px', padding: 0 }} onClick={(e) => setDefaultParams(landTaxes, acquisitionCostsDefaults)}>
                <CiUndo size={24} />
              </Button>
            </Col>
          </Row>
        </div>

        <Row gutter={24} className="pt-4 d-flex justify-content-between align-items-center">
          <Col xs={8} className="align-items-center">
            <h4 className="mb-0 text-black-50 inner_progress">Land Transfer Tax in %</h4>
            {/* <h6 style={{ color: '#797979' }}>In %</h6> */}
          </Col>
          <Col xs={6}>
            <IncDecCounter
              value={getFormattedDecimalNumber(landTransferTax) || 0}
              incDecStep={bpDiscountRate.land / 100}
              setIncDecValue={(e) => setAssetDCFParams({ landTransferTax: e })}
              className="m-0"
              // label="%"
              min={0} max={100}
            // incDecStep={0.5}
            />
          </Col>
          <Col xs={6}>
            {/* <h4 className="holding_text">Steps BP</h4> */}
            {/* <div className="">
              <div> */}
            <Input
              style={{
                border: "1px solid #B7B7B7",
                padding: "12px",
                width: '110px'
              }}
              type='number'
              value={getFormattedEnglishNumber(bpDiscountRate.land)}
              onChange={(e) => setBpDiscountRate(prev => ({ ...prev, land: e.target.value }))}
            />
            {/* </div>
            </div> */}
          </Col>
          <Col
            xs={4}
            className="d-flex justify-content-end"
          >
            <h4 className="mb-0 text-black-50 inner_price">{getFormattedEnglishNumber(landTransferValue)} €</h4>
          </Col>

        </Row>
        <Row gutter={24} className="pt-3 d-flex justify-content-between align-items-center">
          <Col xs={8} className="align-items-center">
            <h4 className="mb-0 text-black-50 inner_progress">Agent Costs in %</h4>
            {/* <h6 style={{ color: '#797979' }}>In %</h6> */}
          </Col>
          <Col xs={6}>
            <IncDecCounter
              value={getFormattedDecimalNumber(agentCosts_Sale) || 0}
              incDecStep={bpDiscountRate.agent / 100}
              setIncDecValue={(e) => setAssetDCFParams({ agentCosts_Sale: e })}
              className="m-0"
              // label="%"
              min={0} max={100}
            // incDecStep={0.5}
            />
          </Col>

          <Col xs={6}>
            {/* <h4 className="holding_text">Steps BP</h4> */}
            {/* <div className="">
              <div> */}
            <Input
              style={{
                border: "1px solid #B7B7B7",
                padding: "12px",
                width: '110px'
              }}
              type='number'
              value={getFormattedEnglishNumber(bpDiscountRate.agent)}
              onChange={(e) => setBpDiscountRate(prev => ({ ...prev, agent: e.target.value }))}
            />
            {/* </div>
            </div> */}
          </Col>
          <Col
            xs={4}
            className="d-flex justify-content-end"
          >
            <h4 className="mb-0 text-black-50 inner_price">{getFormattedEnglishNumber(agentCostsValue)} €</h4>
          </Col>
        </Row>
        <Row gutter={24} className="pt-3 d-flex justify-content-between align-items-center">
          <Col xs={8} className="align-items-center my-2">
            <h4 className="mb-0 text-black-50 inner_progress">Notary/ Other Costs in %</h4>
            {/* <h6 style={{ color: '#797979' }}>In %</h6> */}
          </Col>
          <Col xs={6}>
            <IncDecCounter
              value={getFormattedDecimalNumber(notaryCosts) || 0}
              incDecStep={bpDiscountRate.notary / 100}
              setIncDecValue={(e) => setAssetDCFParams({ notaryCosts: e })}
              className="m-0"
              // label="%"
              min={0} max={100}
            // incDecStep={0.5}
            />
          </Col>
          <Col xs={6}>
            {/* <h4 className="holding_text">Steps BP</h4> */}
            {/* <div className="">
              <div> */}
            <Input
              style={{
                border: "1px solid #B7B7B7",
                padding: "12px",
                width: '110px'
              }}
              type='number'
              value={getFormattedEnglishNumber(bpDiscountRate.notary)}
              onChange={(e) => setBpDiscountRate(prev => ({ ...prev, notary: e.target.value }))}
            />
            {/* </div>
            </div> */}
          </Col>
          <Col
            xs={4}
            className="d-flex justify-content-end"
          >
            <h4 className="mb-0 text-black-50 inner_price">{getFormattedEnglishNumber(notaryCostsValue)} €</h4>
          </Col>

        </Row>
        {/* <Row gutter={24} className="pt-2 mb-2 d-flex justify-content-between">
          <Col xs={9} className="align-items-center my-2">
            <h4 className="mb-0 text-black">Total acuisition Costs</h4>
          </Col>
          <Col
            xs={8}
            className="d-flex my-2 justify-content-center"
          >
            <h4 className="mb-0 text-black">{landTransferTax+agentCosts_Sale+notaryCosts} %</h4>
          </Col>
          <Col
            xs={7}
            className="d-flex align-items-center my-2 justify-content-end"
          >
            <h4 className="mb-0 text-black">{getFormattedInt(totalAccCostsValue)} €</h4>
          </Col>
        </Row>
        <Row
          gutter={24}
          className="d-flex justify-content-between"
          style={{ "background-color": "#F2F4F7", borderRadius: "5px" }}
        >
          <Col xs={9} className="justify-content-center my-2">
            <h4 className="mb-0 text-blue">Gross Asset Value</h4>
          </Col>
          <Col
            xs={8}
            className="d-flex my-2 justify-content-center"
          >
            <h4 className="mb-0 text-blue">{getPerSpaceValue(assetValue, calculation.assetHVLParams,Object.values(calculation.calculationDetail.rentRolls))}  €/sqm</h4>
          </Col>
          <Col
            xs={7}
            className="d-flex align-items-center my-2 justify-content-end"
          >
            <h4 className="mb-0 text-blue">{getFormattedInt(assetValue)} €</h4>
          </Col>
        </Row> */}
      </Card>
    </div>
  );
}

export default VeAcquisitionCosts;
