import React, { useEffect, useState } from "react";
import { Card, Col, Row, Select, Input, Switch } from "antd";
import { FaPlus } from "react-icons/fa6";
import { AiOutlinePercentage } from "react-icons/ai";
import IMAGES from "../../../../../../../../assets/images";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import IncDecCounter from "../../../../../../../../components/Layout/IncDecCounter";
import { getByTypeDropdown } from "../../../../../../../../app/features/Lookups/lookup.slice";
import { AssetDto, I18n } from "reia-rest-client";
import { defaultNotifyToaster, getDefaultNotifyToaster } from "../../../../../../../../utils/helpers";
import { DCFParameters } from "reia-rest-client";
import {
  DCF_FINANCING_AMORTIZATION,
  DCF_FINANCING_INTEREST_RATE,
  DCF_FINANCING_PREPAYMENT_FEE,
  DCF_FINANCING_SWAP_RATE, DCF_FINANCING_TARGET_VALUE, DCF_FINANCING_TYPE_I18N_KEY
} from "../../../../../../../../utils/dcfDefaults";
import {
  getFinancingAllInterestRate,
  getFinancingEquity,
  getFinancingLoan, getFinancingLTV, getFinancingTypeByParams
} from "../../../../../../../../utils/dcfHelper";
import { CalculationDetailDto } from "reia-rest-client";
import { getFormattedDecimalNumber, getFormattedEnglishNumber } from "../../../../../../../../utils/digitHelper";

const VeFinancingAssumption = ({ fullWidth }) => {
  const dispatch = useDispatch();
  const calculation: CalculationDetailDto = useSelector((state) => state.calculation);
  const { assetDCFParams, assetDCFResult } = calculation;
  const { financingTypes } = useSelector((state) => state.lookUps);
  const assetParams: DCFParameters = assetDCFParams;

  const setAssetDCFParams = (params) => {
    dispatch({ type: "calculation/setAssetDCFParams", payload: params })
  }

  useEffect(() => {
    const finalData = {
      catalog: "core-data",
      context: "financingTypes",
    };
    dispatch(getByTypeDropdown({ finalData, defaultNotifyToaster }));
  }, [dispatch]);

  const financingTypesMap = {};
  financingTypes?.forEach((item: I18n) => (financingTypesMap[item.key] = item))

  const financingLoanPlaceholder = getFinancingLoan(assetDCFParams, assetDCFResult?.dcfResult);
  return (
    <div>
      <Card bordered={false} className={`assets_card`}>
        <div className="assets_card__header d-flex justify-content-between align-items-center" style={{ paddingTop: '8px', paddingBottom: '0.5rem' }}>
          <h3 className="text-black">Financing Assumptions</h3>
          <Switch className="ml-3 my-auto" checked={assetParams.triggerFinancingCosts} onChange={() => setAssetDCFParams({ triggerFinancingCosts: !assetParams.triggerFinancingCosts })} />
        </div>
        <div className="d-flex justify-content-between align-items-center pt-3" style={{ flexWrap: 'wrap', gap: '20px' }}>
          <div className="d-flex align-items-center" style={{ flexBasis: fullWidth ? '20%' : '45%', gap: '10px' }}>
            <h4 className="mb-0 text-black-50 inner_progress" style={{ flexBasis: '45%', flexShrink: 0 }}>Loan Type</h4>
            <Select
              placeholder="Loan Type"
              disabled={!assetParams.triggerFinancingCosts}
              style={{ width: "100%" }}
              allowClear
              options={financingTypes.map(item => ({ label: item.translations.en, value: item.key }))}
              value={
                assetParams.financingType
                  ? assetParams.financingType.key
                  : DCF_FINANCING_TYPE_I18N_KEY
              }
              className="w-100"
              onChange={(e) => setAssetDCFParams({ financingType: financingTypesMap[e] })}
            />
          </div>
          <div className="d-flex align-items-center" style={{ flexBasis: fullWidth ? '20%' : '45%', gap: '10px' }}>
            <h4 className="mb-0 text-black-50 inner_progress" style={{ flexBasis: '45%', flexShrink: 0 }}>
              Target Value
            </h4>
            {/* <Input addonAfter={<img src={IMAGES.POUND} />} defaultValue="26,840,000" /> */}
            <Input type="number"
              value={assetParams.financingTargetValue ? getFormattedEnglishNumber(assetParams.financingTargetValue) : undefined}
              onChange={(event) => setAssetDCFParams({ financingTargetValue: event.target.value })}
              className="assumptions-input-filed"
              addonAfter={<img src={IMAGES.POUND} />}
              placeholder={DCF_FINANCING_TARGET_VALUE}
              disabled={!assetParams.triggerFinancingCosts || getFinancingTypeByParams(assetParams) !== 2}
            />
          </div>
          <div className="d-flex align-items-center" style={{ flexBasis: fullWidth ? '20%' : '45%', gap: '10px' }}>
            <h4 className="mb-0 text-black-50 inner_progress" style={{ flexBasis: '45%', flexShrink: 0 }}>Loan Amount</h4>
            <Input
              addonAfter={<img src={IMAGES.POUND} />}
              value={assetParams.financingLoan ? getFormattedEnglishNumber(assetParams.financingLoan) : undefined}
              onChange={(event) => setAssetDCFParams({ financingLoan: event.target.value })}
              disabled={!assetParams.triggerFinancingCosts}
              placeholder={getFormattedEnglishNumber(financingLoanPlaceholder)}
            />
          </div>
          <div className="d-flex align-items-center" style={{ flexBasis: fullWidth ? '20%' : '45%', gap: '10px' }}>
            <h4 className="mb-0 text-black-50 inner_progress" style={{ flexBasis: '45%', flexShrink: 0 }}>
              Interest Rate
            </h4>
            {/* <Input addonAfter="%" defaultValue="2.50" /> */}
            <IncDecCounter
              className="m-0"
              incDecStep={5}
              placeholder={DCF_FINANCING_INTEREST_RATE}
              value={getFormattedDecimalNumber(assetParams.financingInterestRate)}
              setIncDecValue={(newValue) => setAssetDCFParams({ financingInterestRate: newValue })}
              disabled={!assetParams.triggerFinancingCosts}
              label={"%"}
            />
          </div>
          {/* <div className="d-flex align-items-center" style={{ flexBasis: fullWidth ? '20%' : '45%', gap: '10px' }}>
            <h4 className="mb-0 text-black-50 inner_progress" style={{ flexBasis: '45%', flexShrink: 0 }}>LTC</h4>
            <Input addonAfter="%" defaultValue="1.00" />
          </div> */}
          <div className="d-flex align-items-center" style={{ flexBasis: fullWidth ? '20%' : '45%', gap: '10px' }}>
            <h4 className="mb-0 text-black-50 inner_progress" style={{ flexBasis: '45%', flexShrink: 0 }}>
              Amortization %
            </h4>
            {/* <Input addonAfter="%" defaultValue="60.00" /> */}
            <IncDecCounter
              className="m-0"
              incDecStep={5}
              placeholder={DCF_FINANCING_AMORTIZATION}
              value={getFormattedDecimalNumber(assetParams.financingAmortization)}
              setIncDecValue={(newValue) => setAssetDCFParams({ financingAmortization: newValue })}
              disabled={!assetParams.triggerFinancingCosts || getFinancingTypeByParams(assetParams) !== 1}
            // label={"%"}
            />
          </div>
          <div className="d-flex align-items-center" style={{ flexBasis: fullWidth ? '20%' : '45%', gap: '10px' }}>
            <h4 className="mb-0 text-black-50 inner_progress" style={{ flexBasis: '45%', flexShrink: 0 }}>LTV %</h4>
            {/* <Input addonAfter="%" defaultValue="55.00" /> */}
            <IncDecCounter
              className="m-0"
              disabled={!assetParams.triggerFinancingCosts}
              incDecStep={5}
              placeholder={getFinancingLTV(assetDCFParams, assetDCFResult?.dcfResult) * 100}
              value={getFormattedDecimalNumber(assetParams.financingLTV)}
              setIncDecValue={(newValue) => setAssetDCFParams({ financingLTV: newValue })}
            // label={"%"}
            />
          </div>
          <div className="d-flex align-items-center" style={{ flexBasis: fullWidth ? '20%' : '45%', gap: '10px' }}>
            <h4 className="mb-0 text-black-50 inner_progress" style={{ flexBasis: '45%', flexShrink: 0 }}>
              Set-Up Fee %
            </h4>
            {/* <Input addonAfter="%" defaultValue="2.00" /> */}
            <IncDecCounter
              className="m-0"
              incDecStep={5}
              value={getFormattedDecimalNumber(assetParams.financingPrepaymentFee)}
              placeholder={DCF_FINANCING_PREPAYMENT_FEE}
              setIncDecValue={(newValue) => setAssetDCFParams({ financingPrepaymentFee: newValue })}
              disabled={!assetParams.triggerFinancingCosts}
            // label={"%"}
            />
          </div>
          <div className="d-flex align-items-center" style={{ flexBasis: fullWidth ? '20%' : '45%', gap: '10px' }}></div>
        </div>
      </Card>
    </div>
  );
}
export default VeFinancingAssumption;
