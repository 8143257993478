import { Button, Col, Form, Row, Spin, Tooltip, notification } from "antd";
import { EditAssetSection } from "./component/EditAsset";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import Sticky from "react-stickynode";
import { updateAsset } from "../../../../../app/features/projectDetails/projectDetail.slice";
import { getAssetByID } from "../../../../../app/features/assets/assets.slice";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import IMAGES from "../../../../../assets/images";
import { LuRefreshCw } from "react-icons/lu";
import { useEffect, useState } from "react";
import { getFormattedEnglishNumber } from "../../../../../utils/digitHelper";

function EditAsset({ asset, assetId, setAssetId, isLoading, handleToggleDrawer, showDrawer, AssetSelect }) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { assetDCFResult } = useSelector(state => state.calculation)
  const newNetAssetValue = assetDCFResult?.dcfResult?.assetCashFlow?.netAssetValue;

  function notifyToaster(data, condition) {
    notification.config({
      maxCount: 1,
    });
    if (condition === "success") {
      notification.success({
        message: data,
      });
    } else {
      notification.error({
        message: data,
      });
    }
  }

  const moveToNext = () => {
    console.log('move to next')
    const finalData = {
      assetId,
    };
    dispatch(getAssetByID({ finalData, notifyToaster }));
  };

  const onFinish = (values) => {
    // console.log(values, 'submit values')
    const finalData: AssetDto = {
      ...asset,
      assetName: values.assetName,
      assetType: { id: values.assetType },
      // businessKey: values.businessKey,
      analysisDate: moment(values.analysisDate).toISOString(),
      yearOfConstruction: moment(values.yearOfConstruction).toISOString(),
      yearOfRefurbishment: moment(values.yearOfRefurbishment).toISOString(),
      address: {
        ...asset.address,
        country: { id: values.country },
        federalState: { id: values.state },
        city: { id: values.city },
        zipCode: values.zipCode,
        street: values.street,
        streetNo: values.houseNo
      },
      assetDetails: {
        ...asset.assetDetails,
        esgSelection: values.selection,
        esgComments: values.esgComments,
        inspectionType: { id: values.type },
        inspectionDate: moment(values.inspectionDate).toISOString(),
        inspectionNotes: values.notes,
        riskClass: { id: values.riskClass },
        upsidePriority: { id: values.upsidePriority },
        capexPriority: { id: values.capexPriority },
        assetManagementPriority: { id: values.assetManagementPriority },
        exLiquidityPriority: { id: values.exLiquidityPriority },
        ownershipType: { id: values.ownershipType },
        sectionii: values.sectionii,
        totalSiteArea: values.totalSiteArea,
        locationRating: { id: values.locationRating },
        situationRating: { id: values.situationRating },
        buildingRating: { id: values.buildingRating },
        tenancyRating: { id: values.tenancyRating },
        developmentStatus: { id: values.developmentStatus },
        otherComments: values.otherComments
      }
    }
    console.log(finalData, 'finalData')
    dispatch(updateAsset({ finalData, moveToNext, notifyToaster }))
  };
  console.log(asset, 'asset')
  return (
    <div className="cashFlowsComponent">
      <Form
        form={form}
        name="editAssetDetails"
        layout="vertical"
        initialValues={{
          assetName: asset?.assetName,
          assetType: asset?.assetType?.id,
          analysisDate: moment(asset.analysisDate, 'YYYY-MM-DD'),
          country: asset?.address?.country?.id,
          state: asset?.address?.federalState?.id,
          city: asset?.address?.city?.id,
          zipCode: asset?.address?.zipCode,
          street: asset?.address?.street,
          houseNo: asset?.address?.streetNo,
          developmentStatus: asset?.assetDetails?.developmentStatus?.id,
          yearOfConstruction: moment(asset.yearOfConstruction, 'YYYY'),
          yearOfRefurbishment: moment(asset.yearOfRefurbishment, 'YYYY'),
          locationRating: asset?.assetDetails?.locationRating?.id,
          situationRating: asset?.assetDetails?.situationRating?.id,
          buildingRating: asset?.assetDetails?.buildingRating?.id,
          tenancyRating: asset?.assetDetails?.tenancyRating?.id,
          riskClass: asset?.assetDetails?.riskClass?.id,
          upsidePriority: asset?.assetDetails?.upsidePriority?.id,
          capexPriority: asset?.assetDetails?.capexPriority?.id,
          assetManagementPriority: asset?.assetDetails?.assetManagementPriority?.id,
          exLiquidityPriority: asset?.assetDetails?.exLiquidityPriority?.id,
          ownershipType: asset?.assetDetails?.ownershipType?.id,
          sectionii: asset?.assetDetails?.sectionii,
          totalSiteArea: asset?.assetDetails?.totalSiteArea,
          type: asset?.assetDetails?.inspectionType?.id,
          inspectionDate: moment(asset?.assetDetails?.inspectionDate, 'YYYY-MM-DD'),
          notes: asset?.assetDetails?.inspectionNotes,
          selection: asset?.assetDetails?.esgSelection,
          esgComments: asset?.assetDetails?.esgComments,
          otherComments: asset?.assetDetails?.otherComments,
        }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Sticky enabled={true} top={109} innerZ={10} innerActiveClass="stickyTabs">
          <div className="d-flex justify-content-between align-items-center" style={{ background: '#fff', padding: '10px 15px', border: '1.5px solid #B0BACD', borderRadius: '8px' }}>
            <div className="d-flex align-items-center" style={{gap:'10px'}}>
              <h3 className="mb-0">Edit Asset</h3>
              {!showDrawer &&
                <h4 className="mb-0"> Asset Value: {getFormattedEnglishNumber(newNetAssetValue)} €</h4>
              }
            </div>
            <div className="d-flex justify-content-end align-items-center">
              <Tooltip title="Save changes" color="#00215B" key="save-changes">
                <div>
                  <Button
                    icon={< IMAGES.SAVE_ICON />}
                    className="save-icon-btn mr-2 cursor-pointer"
                    htmlType="submit"
                  // onClick={onFinish}
                  // disabled={!disableActions}
                  />
                </div>
              </Tooltip>

              <Tooltip title="Reset changes" color="#00215B" key="reset-changes">
                <div>
                  <Button
                    icon={<LuRefreshCw size={24} />}
                    className="reset-icon-btn mr-2 cursor-pointer"
                  // onClick={resetCalculationDetail}
                  // disabled={!disableActions}
                  />
                </div>
              </Tooltip>
              {AssetSelect && <AssetSelect />}
              <Tooltip
                title="show asset detail"
                color="#00215B"
                key="#00215B"
              >
                {/* drawer-close-btn */}
                <span
                  className={`project-drawer-close-btn cursor-pointer mr-2 ${showDrawer ? "d-none" : ""
                    }`}
                  role="button"
                  tabIndex="0" style={{ marginLeft: '20px' }} onClick={handleToggleDrawer}
                >
                  <MdOutlineKeyboardArrowLeft
                    size={25}
                    fill="#fff"

                  />
                </span>
              </Tooltip>
            </div>
          </div>
        </Sticky>
        <Row style={{ paddingTop: '24px' }}>
          <Col xs={24}>
            <div style={{ background: '#fff', padding: '32px', border: '1px solid #D0D5DD', borderRadius: '8px' }}>
              <Spin
                size="large"
                spinning={isLoading}
              >

                <EditAssetSection />
                {/* </Form> */}
              </Spin>
            </div >
          </Col>
        </Row>
      </Form>
    </div >
  );
}

export default EditAsset;
