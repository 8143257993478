/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Dropdown,
  Form,
  Input,
  InputNumber,
  Menu,
  Row,
  Select,
  Tooltip,
  notification
} from "antd";
import debounce from "lodash.debounce";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { AiOutlineDelete, AiOutlinePlus } from "react-icons/ai";
import { FiSearch } from "react-icons/fi";
import { IoIosArrowDown } from "react-icons/io";
import { LuRefreshCw } from "react-icons/lu";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { RiFileExcel2Line, RiFilter2Line } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getByTypeDropdown } from "../../../../../app/features/Lookups/lookup.slice";
import { deleteRentRolls, getRentRollsByAsset } from "../../../../../app/features/RentRoll/rentRoll.slice";
import { getCalculationDetail } from "../../../../../app/features/calculation/calculationAsset.slice";
import { importExcelFileRentRolls } from "../../../../../app/features/settings/settings.slice";
import IMAGES from "../../../../../assets/images";
import DeleteUserModal from "../../../../../components/Layout/DeleteModel/DeleteUserModal";
import { RENT_ROLLS_PAGE_SIZE, getDefaultNotifyToaster } from "../../../../../utils/helpers";
import CreateUpdateRentRoll from "./CreateUpdateRentRoll";
import RentRollsTable from "./component/RentRoll/RentRollsTable";
import SearchForm from "./component/RentRoll/SearchForm";
import { getFormattedDecimalNumber, getFormattedEnglishNumber } from "../../../../../utils/digitHelper";
import Sticky from "react-stickynode";
// import IndividualIndexation from "./component/RentRoll/IndividualIndexation";

const columnSelection = [
  { label: "All", value: 'all' },
  { label: "Current & Market Rent", value: 'currentRentColumn' },
  { label: "Lease Term & Renewal", value: 'leseTermRenewalColumn' },
  { label: "Indexation", value: 'indexationColumn' },
  { label: "Cost & Voids", value: 'costVoidsColumn' }
]

function RentRoll({ AssetSelect, showDrawer, handleToggleDrawer }) {

  const dispatch = useDispatch();
  const param = useParams();
  const assetId = param.assetDetailId;
  const [activeKey, setActiveKey] = useState(1);
  const [columnSelect, setColumnSelect] = useState("currentRentColumn");
  const [current, setCurrent] = useState("mail");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [addUpdateNewRentRoll, setAddUpdateNewRentRoll] = useState(false);
  const [deleteUserModal, setDeleteUserModal] = useState(false);
  const notifyToaster = getDefaultNotifyToaster(notification);
  const assetDetails = useSelector((state) => state.rentRoll);
  const { isCalculationDetailLoading, calculationDetail, calculationModified, assetDCFResult } = useSelector((state) => state.calculation);
  const rentRollsByAsset = calculationDetail ? calculationDetail?.rentRolls ? Object.values(calculationDetail?.rentRolls) : [] : null;
  const [rentRollsDataByAsset, setRentRollsDataByAsset] = useState(rentRollsByAsset)
  const newNetAssetValue = assetDCFResult?.dcfResult?.assetCashFlow?.netAssetValue;
  // useEffect(() => {
  //   console.log("LOAD RentRoll 1")
  //   loadRentRollsByAsset();
  // }, [dispatch]);
  //
  // const loadRentRollsByAsset = () => {
  //   const finalData = {
  //     assetId: assetId,
  //     page: 1,
  //     size: RENT_ROLLS_PAGE_SIZE
  //   };
  //   dispatch(getCalculationDetail({finalData,notifyToaster}));
  // }

  const AddUpdateNewRentRollcloseModal = () => {
    setAddUpdateNewRentRoll(false);
  };
  const { Search } = Input;
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const onFileUpload = (event) => {
    const finalData = { file: event.target.files[0], projectId: param.projectId, assetId: assetId }
    dispatch(importExcelFileRentRolls({ finalData, notifyToaster, moveToNext: loadRentRollsByAsset }))
    event.target.value = "";
  };


  const addNewDropdown = (
    <Menu
      className="projects-add-new-drop-down p-0 py-2"
      items={[
        {
          key: "1",
          type: "group",
          label: "Create New",
          children: [
            {
              key: "1-1",
              label: (
                <div
                  className="d-flex align-items-center change-text-color"
                  role="button"
                  tabIndex="0"
                  onClick={() => setAddUpdateNewRentRoll(true)}
                  onKeyDown={() => setAddUpdateNewRentRoll(true)}
                >
                  <AiOutlinePlus className="mb-0 change-icon-color" size={18} />
                  <p className="mb-0 text-style">Rent Roll</p>
                </div>
              ),
            },
            // {
            //   key: "1-2",
            //   label: (
            //     <div
            //       className="d-flex align-items-center change-text-color"
            //       role="button"
            //       tabIndex="0"
            //       onClick={() => setAddUpdateNewRentRoll(true)}
            //       onKeyDown={() => setAddUpdateNewRentRoll(true)}
            //     >
            //       <AiOutlinePlus className="mb-0 change-icon-color" size={18} />
            //       <p className="mb-0 text-style">Portfolio</p>
            //     </div>
            //   ),
            // },
          ],
        },
        {
          key: "2",
          type: "group",
          label: "Upload from Excel",
          children: [
            {
              key: "2-1",
              label: (
                <label
                  htmlFor="xls-upload"
                  className="rentroll-upload cursor-pointer"
                >
                  <div className="d-flex align-items-center change-text-color">
                    <RiFileExcel2Line
                      className="mb-0 change-icon-color"
                      size={18}
                    />
                    <p className="mb-0 text-style">Rent Roll</p>
                  </div>
                  <input
                    type="file"
                    id="xls-upload"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    onChange={onFileUpload}
                  />
                </label>
              ),
            },
            // {
            //   key: "2-2",
            //   label: (
            //     <div
            //       className="d-flex align-items-center change-text-color"
            //       role="button"
            //       tabIndex="0"
            //     >
            //       <RiFileExcel2Line className="mb-0 change-icon-color" size={18} />
            //       <p className="mb-0 text-style">Portfolio</p>
            //     </div>
            //   ),
            // },
          ],
        },
      ]}
    />
  );

  const onClick = (e) => {
    setCurrent(e.key);
  };

  const handleChange = (key) => {
    setActiveKey(key);
  };

  // Hide Table Column Dropdown
  const initialHideTableColumns = (
    <Menu
      items={[
        {
          key: "1",
          type: "group",
          label: (
            <div>
              <h4 style={{ color: "#000" }}>Hide table columns</h4>
            </div>
          ),
        },
        {
          key: "2",
          label: <Checkbox>Column 1</Checkbox>,
        },
        {
          key: "3",
          label: <Checkbox>Column 2</Checkbox>,
        },
        {
          key: "4",
          label: <Checkbox>Column 3</Checkbox>,
        },
        {
          key: "5",
          label: <Checkbox>Column 4</Checkbox>,
        },
        {
          key: "6",
          label: <Checkbox>Column 5</Checkbox>,
        },
        {
          key: "7",
          label: <Checkbox>Column 6</Checkbox>,
        },
        {
          key: "8",
          label: (
            <div>
              <hr style={{ border: "1px solid #F2F3F5" }} />
              <Button className="reset-dropdown-btn">Reset</Button>
            </div>
          ),
        },
      ]}
    />
  );

  const [hideTableColumns, sethideTableColumns] = useState(
    initialHideTableColumns
  );
  const [open, setOpen] = useState(false);
  const handleOpenChange = (flag) => {
    setOpen(flag);
  };



  //*********Delete rentrolls */
  const { isLoading } = assetDetails;
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [editingKey, setEditingKey] = useState("");


  const closeDeleteUserModal = () => {
    setDeleteUserModal(false);
  };

  const loadRentRolls = (changedRentRollFilter) => {
    // setRentRollFilter(changedRentRollFilter);
    const finalData = {
      assetId: +assetId,
      page: changedRentRollFilter?.pagination?.page,
      size: changedRentRollFilter?.pagination.size,
    };
    dispatch(getRentRollsByAsset({ finalData, notifyToaster }));
  }

  const moveToNextDelete = () => {
    closeDeleteUserModal();
    const filters = {
      Search: "",
      pagination: {
        page: 1,
        size: RENT_ROLLS_PAGE_SIZE,
      },
    };
    loadRentRolls(filters);
    setSelectedRowKeys([]);
    setEditingKey("");
    const finalData = {
      assetId: +assetId
    };
    dispatch(getCalculationDetail({ finalData, notifyToaster, autoSaveCalc: true }));
  };

  const deleteProjectDetail = () => {
    const finalData = selectedRowKeys;
    dispatch(deleteRentRolls({ finalData, moveToNextDelete, notifyToaster }));
  };

  //********* */

  const renderTabBar: TabsProps["renderTabBar"] = (props, DefaultTabBar) => (
    <div className="d-flex justify-content-center align-items-center">
      <DefaultTabBar {...props} style={{ paddingBlock: '16px', margin: 0 }} />
    </div>
  );

  const [toggle, setToggle] = useState([]);

  const { rentRollStatusTypes, rentRollUseGroupTypes, rentRollUseTypes, indexationTypes, cpiTypes } = useSelector((state) => state.lookUps);

  const [dates, setDates] = useState({
    leaseStartDate: null,
    leaseEndDate: null,
    option1Date: null,
    option2Date: null,
    indexationFirstDate: null,
    indexationLastDate: null,
    indexationNextDate: null,
    type: '',
    isValid: true,
  });

  const dateChange = (value, field) => {
    setDates({
      ...dates,
      [field]: value?._d ? moment.utc(value?._d) : null,
    });
  };

  function EditableCell({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) {
    const inputNode =
      inputType === "statusDropdown" ? (
        <Select placeholder="Select" allowClear>
          {rentRollStatusTypes?.map((item: I18n) => {
            return (
              <Select.Option key={item.id} value={item.id}>
                {item.translations.en}
              </Select.Option>
            );
          })}
        </Select>
      ) : inputType === "groupDropdown" ? (
        <Select placeholder="Select" allowClear>
          {rentRollUseGroupTypes?.map((item: I18n) => {
            return (
              <Select.Option key={item.id} value={item.id}>
                {item.translations.en}
              </Select.Option>
            );
          })}
        </Select>
      ) : inputType === "useDropdown" ? (
        <Select placeholder="Select" allowClear>
          {rentRollUseTypes?.map((item: I18n) => {
            return (
              <Select.Option key={item.id} value={item.id}>
                {item.translations.en}
              </Select.Option>
            );
          })}
        </Select>
      ) : inputType === "typeDropdown" ? (
        <Select placeholder="Selection" allowClear>
          {indexationTypes?.map((item: I18n) => {
            return (
              <Select.Option key={item.id} value={item.id}>
                {item.translations.en}
              </Select.Option>
            );
          })}
        </Select>
      ) : inputType === "indexDropdown" ? (
        <Select placeholder="Selection" allowClear>
          {cpiTypes?.map((item: I18n) => {
            return (
              <Select.Option key={item.id} value={item.id}>
                {item.translations.en}
              </Select.Option>
            );
          })}
        </Select>
      ) : inputType === "number" ? (
        <InputNumber maxLength={10} />
      ) : (
        <Input type={inputType} />
      );
    return (
      <td {...restProps}>
        {editing && inputType !== "date" ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={
              inputType === "number"
                ? [
                  {
                    required: true,
                    message: `Please Input ${title}!`,
                  },
                ]
                : inputType === "dropdown"
                  ? [
                    {
                      required: false,
                      message: `Please Input ${title}!`,
                    },
                  ]
                  : inputType === "text"
                    ? [
                      {
                        max: 50,
                        message: "The max lenght of 50 Characters is reached!",
                      },
                    ]
                    : []
            }
          >
            {inputNode}
          </Form.Item>
        ) : editing && inputType === "date" ? (
          <>
            <DatePicker
              onClick={(e) => {
                e.stopPropagation();
              }}
              onKeyDown={(e) => {
                e.stopPropagation();
              }}
              onChange={(e) => dateChange(e, dataIndex)}
              className="w-100 rounded-sm"
              defaultValue={
                dates?.[dataIndex] && moment(dates?.[dataIndex])
              }
              dateFormat="YYYY-MM-DD"
            />

            {(dataIndex === "leaseStartDate"
              // || dataIndex === "leaseEndDate"
            ) &&
              dates.isValid === false && dates.type === "leaseDate" && (
                <p className="text-danger mb-0">
                  Lease Start Date Can't be greater than Lease End Date
                </p>
              )}
            {dataIndex === "option1Date" && dates.option1Date === null && (
              <p className="text-danger mb-0">Option 1 is required</p>
            )}
            {(dataIndex === "indexationFirstDate"
              // || dataIndex === "indexationLastDate"
            ) &&
              dates.isValid === false && dates.type === "indexationDate" && (
                <p className="text-danger mb-0">
                  {/* First Date Can't be greater than Last Date */}
                  1st Adjustment Date Can't be greater than Last Index Date
                </p>
              )}
          </>) : (
          children
        )}
      </td>
    );
  }

  const prepareEditValues = (record) => {
    setDates({
      leaseStartDate: moment(record.leaseStartDate),
      leaseEndDate: moment(record.leaseEndDate),
      option1Date: moment(record.option1Date),
      option2Date: moment(record.option2Date),
      indexationFirstDate: moment(record.indexationFirstDate),
      indexationLastDate: moment(record.indexationLastDate),
      indexationNextDate: moment(record.indexationNextDate),
    });
    // console.log(dates)
  }

  const prepareUpdatedRentRoll = (record, row) => {
    const validateDate = validateStartDate();
    // console.log(validateDate, 'validateDate')
    if (!validateDate) {
      return null;
    }

    const finalData: RentRollDto = {
      ...record,
      rentalSpace: +row.rentalSpace,
      rentRollStatusType: row.rentRollStatusType,
      tenantName: row.tenantName,
      currentRentPerSqm: row.currentRentPerSqm,
      currentRentPerMonth: row.currentRentPerSqm * record.rentalSpace,
      currentRentPerYear: row.currentRentPerSqm * record.rentalSpace * 12,
      marketRentPerSqm: row.marketRentPerSqm,
      marketRentPerMonth: row.marketRentPerSqm * record.rentalSpace,
      marketRentPerYear: row.marketRentPerSqm * record.rentalSpace * 12,
      useType: row.useType,
      leaseStartDate: dates.leaseStartDate,
      leaseEndDate: dates.leaseEndDate,
      option1Date: dates.option1Date,
      option2Date: dates.option2Date,
      leaseDurationOpenEndInMonths: row.leaseDurationOpenEndInMonths,
      leaseDurationInMonths: row.leaseDurationInMonths,
      initialRenewalProbability: row.initialRenewalProbability,
      continuingRenewalProbability: row.continuingRenewalProbability,
      nrctOtherCosts: row.nrctOtherCosts,
      nrctMaintenance: row.nrctMaintenance,
      nrctManagement: row.nrctManagement,
      nrcrOtherCosts: row.nrcrOtherCosts,
      nrcrMaintenance: row.nrcrMaintenance,
      nrcrManagement: row.nrcrManagement,
      initialVoidPeriod: row.initialVoidPeriod,
      continuingVoidPeriod: row.continuingVoidPeriod,
      vacancyCosts: row.vacancyCosts,
      relettingCostsInitialTIs: row.relettingCostsInitialTIs,
      relettingCostsContinuingTIs: row.relettingCostsContinuingTIs,
      relettingCostsAgent: row.relettingCostsAgent,
      indexationFirstDate: dates.indexationFirstDate,
      indexationLastDate: dates.indexationLastDate,
      indexationNextDate: dates.indexationNextDate,
      indexationValue: row.indexationValue,
      indexationHurdle: row.indexationHurdle,
      indexationAdjustmentFactor: row.indexationAdjustmentFactor,
      indexationCycleInMonths: row.indexationCycleInMonths,
      indexationType: row.indexationType,
      cpiType: row.cpiType
      // description1: row.description1,
      // description2: row.description2,
      // location: row.location,
      //useGroupType: row.useGroupType,
    };
    return finalData;
  }
  const validateStartDate = () => {
    let isValidDate = false;
    if (dates.leaseStartDate > dates.leaseEndDate) {
      setDates({ ...dates, isValid: false, type: 'leaseDate' });
      return isValidDate = false;
    } else if (dates.indexationFirstDate > dates.indexationLastDate) {
      setDates({ ...dates, isValid: false, type: 'indexationDate' });
      return isValidDate = false;
    } else {
      return isValidDate = true;
    }
  };


  const isEditing = (record) => record.id === editingKey;

  const uniqueFilterData = (data) => {
    const filteredData = Array.from(new Set(data
      .map(item => item.value)
      .filter(value => value !== '' && value !== null && value !== undefined) // Exclude empty values
    )).map(value => {
      return data.find(item => item.value === value);
    });

    return filteredData;
  }

  const tenantNameFilter = rentRollsByAsset?.map(item => ({ text: item.tenantName, value: item.tenantName }))
  const useTypeFilter = rentRollsByAsset?.map(item => ({ text: item?.useType?.translations?.en, value: item?.useType?.translations?.en }))

  const initialColState = [
    {
      id: 1,
      title: () => <p className="mb-0" style={{ color: '#01215d', fontSize: '16px' }}>PID</p>,
      hidden: false,
      dataIndex: "id",
      align: 'left',
      fixed: 'left',
      sorter: (a, b) => a.id - b.id,
      filter: ['all', 'currentRentColumn', 'leseTermRenewalColumn', 'indexationColumn', 'costVoidsColumn'],
      children: [{
        title: '',
        children: [{
          id: 1,
          width: '80px',
          fixed: 'left',
          title: '',
          dataIndex: "id",
          align: 'left',
        }]
      }]
    },
    {
      id: 2,
      title: () => <p className="mb-0" style={{ color: '#01215d', fontSize: '16px' }}>Status</p>,
      dataIndex: ["rentRollStatusType", "translations", "en"],
      hidden: false,
      align: 'left',
      sorter: (a, b) => a.rentRollStatusType.translations.en?.localeCompare(b.rentRollStatusType.translations.en),
      filter: ['all', 'currentRentColumn', 'leseTermRenewalColumn', 'indexationColumn', 'costVoidsColumn'],
      filters: [
        { text: 'Let', value: 'Let' },
        { text: 'Vacant', value: 'Vacant' },
      ],
      onFilter: (value, record) => record.rentRollStatusType.translations.en.includes(value),
      children: [{
        title: '',
        children: [{
          id: 1,
          title: '',
          align: 'left',
          editable: true,
          width: '180px',
          fixed: 'left',
          dataIndex: ["rentRollStatusType", "translations", "en"],
          render: (text) => (text ? text : "N/A"),
          onCell: (record) => ({
            record,
            inputType: "statusDropdown",
            dataIndex: ["rentRollStatusType", "id"],
            title: "Status",
            editing: isEditing(record),
          }),
        }]
      }],
    },
    {
      id: 3,
      title: () => <p className="mb-0" style={{ color: '#01215d', fontSize: '16px' }}>Tenant Name</p>,
      dataIndex: "tenantName",
      hidden: toggle.includes("tenantName"),
      align: 'left',
      sorter: (a, b) => a.tenantName?.localeCompare(b.tenantName),
      filter: ['all', 'currentRentColumn', 'leseTermRenewalColumn', 'indexationColumn', 'costVoidsColumn'],
      filters: uniqueFilterData(tenantNameFilter),
      onFilter: (value, record) => record?.tenantName?.includes(value),
      children: [{
        title: '',
        children: [{
          id: 1,
          title: '',
          align: 'left',
          dataIndex: "tenantName",
          hidden: toggle.includes("tenantName"),
          render: (text) => (text ? text : "N/A"),
          editable: true,
          width: '200px',
          onCell: (record) => ({
            record,
            inputType: "text",
            dataIndex: "tenantName",
            title: "Tenant Name",
            editing: isEditing(record),
          }),
        }]
      }],
    },
    {
      id: 4,
      title: () => <p className="mb-0" style={{ color: '#01215d', fontSize: '16px' }}>Use</p>,
      hidden: toggle.includes("useTypeId"),
      dataIndex: ["useType", "translations", "en"],
      align: 'left',
      sorter: (a, b) => a.useType.translations.en?.localeCompare(b.useType.translations.en),
      filter: ['all', 'currentRentColumn', 'leseTermRenewalColumn', 'indexationColumn', 'costVoidsColumn'],
      filters: uniqueFilterData(useTypeFilter),
      onFilter: (value, record) => record?.useType?.translations?.en?.includes(value),
      children: [{
        title: '',
        children: [{
          id: 1,
          title: '',
          align: 'left',
          dataIndex: ["useType", "translations", "en"],
          hidden: toggle.includes("useTypeId"),
          editable: true,
          width: '150px',
          render: (text) => (text ? text : "N/A"),
          onCell: (record) => ({
            record,
            inputType: "useDropdown",
            dataIndex: ["useType", "id"],
            title: "Use",
            editing: isEditing(record),
          }),
        }]
      }],
    },
    {
      id: 5,
      title: () => <p className="mb-0" style={{ color: '#01215d', fontSize: '16px' }}>Area/Unit</p>,
      hidden: toggle.includes("area"),
      align: 'center',
      sorter: (a, b) => a.rentalSpace - b.rentalSpace,
      filter: ['all', 'currentRentColumn', 'leseTermRenewalColumn', 'indexationColumn', 'costVoidsColumn'],
      children: [{
        title: "",
        hidden: toggle.includes("area"),
        children: [
          {
            id: 1,
            title: () => <p className="mb-0 font-weight-normal" style={{ color: '#797979' }}>SQM / UNIT</p>,
            dataIndex: "rentalSpace",
            align: 'right',
            hidden: toggle.includes("area"),
            editable: true,
            width: '150px',
            render: (text) => (text ? getFormattedEnglishNumber(text) : "N/A"),
            onCell: (record) => ({
              record,
              inputType: "number",
              dataIndex: "rentalSpace",
              title: "SQM / UNIT",
              editing: isEditing(record),
            })
          },
        ],
      }],
    },
    {
      id: 6,
      title: () => <p className="mb-0" style={{ color: '#01215d', fontSize: '16px' }}>Passing Rent</p>,
      hidden: toggle.includes("passingRent"),
      align: 'center',
      filter: ['all', 'currentRentColumn'],
      children: [{
        title: '',
        children: [
          {
            id: 1,
            title: () => <p className="mb-0 font-weight-normal" style={{ color: '#797979' }}>€ / SQ M</p>,
            dataIndex: "currentRentPerSqm",
            align: 'right',
            hidden: toggle?.includes("perSqMeter"),
            editable: true,
            width: '150px',
            sorter: (a, b) => a.currentRentPerSqm - b.currentRentPerSqm,
            render: (text) => (text ? getFormattedEnglishNumber(text) : "N/A"),
            onCell: (record) => ({
              record,
              inputType: "number",
              dataIndex: "currentRentPerSqm",
              title: "€ / SQ M",
              editing: isEditing(record),
            }),
          },
          {
            id: 2,
            title: () => <p className="mb-0 font-weight-normal" style={{ color: '#797979' }}>€ / Month</p>,
            dataIndex: "currentRentPerMonth",
            align: 'right',
            hidden: toggle?.includes("perMonth"),
            editable: true,
            width: '150px',
            render: (text) => (text ? getFormattedEnglishNumber(text) : "N/A"),
            sorter: (a, b) => a.currentRentPerMonth - b.currentRentPerMonth,
          },
          {
            id: 3,
            title: () => <p className="mb-0 font-weight-normal" style={{ color: '#797979' }}>€ / Year</p>,
            dataIndex: "currentRentPerYear",
            align: 'right',
            hidden: toggle?.includes("perYear"),
            editable: true,
            width: '150px',
            render: (text) => (text ? getFormattedEnglishNumber(text) : "N/A"),
            sorter: (a, b) => a.currentRentPerYear - b.currentRentPerYear,
          },
        ],
      }]
    },
    {
      id: 7,
      title: () => <p className="mb-0" style={{ color: '#01215d', fontSize: '16px' }}>Market Rent</p>,
      hidden: toggle.includes("marketRent"),
      align: 'center',
      filter: ['all', 'currentRentColumn'],
      children: [
        {
          title: '',
          children: [
            {
              id: 1,
              title: () => <p className="mb-0 font-weight-normal" style={{ color: '#797979' }}>€ / SQ M</p>,
              dataIndex: "marketRentPerSqm",
              align: 'right',
              hidden: toggle?.includes("perSqMeter"),
              editable: true,
              width: '150px',
              sorter: (a, b) => a.marketRentPerSqm - b.marketRentPerSqm,
              render: (text) => (text ? getFormattedEnglishNumber(text) : "N/A"),
              onCell: (record) => ({
                record,
                inputType: "number",
                dataIndex: "marketRentPerSqm",
                title: "€ / SQ M",
                editing: isEditing(record),
              }),
            },
            {
              id: 4,
              title: () => <p className="mb-0 font-weight-normal" style={{ color: '#797979' }}>€ / Month</p>,
              dataIndex: "marketRentPerMonth",
              align: 'right',
              hidden: toggle?.includes("marketPerMonth"),
              editable: true,
              width: '150px',
              render: (text) => (text ? getFormattedEnglishNumber(text) : "N/A"),
              sorter: (a, b) => a.marketRentPerMonth - b.marketRentPerMonth,
            },
            {
              id: 5,
              title: () => <p className="mb-0 font-weight-normal" style={{ color: '#797979' }}>€ / Year</p>,
              dataIndex: "marketRentPerYear",
              align: 'right',
              hidden: toggle?.includes("marketPerYear"),
              editable: true,
              width: '150px',
              sorter: (a, b) => a.marketRentPerYear - b.marketRentPerYear,
              render: (text) => (text ? getFormattedEnglishNumber(text) : "N/A"),
            },
          ],
        }
      ]
    },
    {
      id: 8,
      title: () => <p className="mb-0" style={{ color: '#01215d', fontSize: '16px' }}>Lease Start</p>,
      hidden: toggle.includes("startDate"),
      align: 'center',
      width: '160px',
      sorter: (a, b) => new Date(a.leaseStartDate) - new Date(b.leaseStartDate),
      filter: ['all', 'leseTermRenewalColumn'],
      children: [
        {
          title: '',
          children: [
            {
              title: () => <p className="mb-0 font-weight-normal" style={{ color: '#797979' }}>Date</p>,
              dataIndex: "leaseStartDate",
              align: 'right',
              hidden: toggle.includes("startDate"),
              editable: true,
              width: '160px',
              render: (text) => (text && moment(text).format("YYYY-MM-DD")) || "N/A",
              onCell: (record) => ({
                record,
                inputType: "date",
                dataIndex: "leaseStartDate",
                title: "Start",
                editing: isEditing(record),
              }),
            }
          ]
        }]
    },
    {
      id: 9,
      title: () => <p className="mb-0" style={{ color: '#01215d', fontSize: '16px' }}>Lease End</p>,
      hidden: toggle.includes("endDate"),
      align: 'center',
      width: '150px',
      sorter: (a, b) => new Date(a.leaseEndDate) - new Date(b.leaseEndDate),
      filter: ['all', 'leseTermRenewalColumn'],
      children: [
        {
          title: '',
          children: [{
            title: () => <p className="mb-0 font-weight-normal" style={{ color: '#797979' }}>Date</p>,
            dataIndex: "leaseEndDate",
            align: 'right',
            hidden: toggle.includes("endDate"),
            editable: true,
            width: '150px',
            render: (text) => (text && moment(text).format("YYYY-MM-DD")) || "N/A",
            onCell: (record) => ({
              record,
              inputType: "date",
              dataIndex: "leaseEndDate",
              title: "End",
              editing: isEditing(record),
            }),
          }]
        }]
    },
    {
      id: 10,
      title: () => <p className="mb-0" style={{ color: '#01215d', fontSize: '16px' }}>Option 1</p>,
      hidden: toggle.includes("option1"),
      align: 'center',
      width: '150px',
      sorter: (a, b) => new Date(a.option1Date) - new Date(b.option1Date),
      filter: ['all', 'leseTermRenewalColumn'],
      children: [
        {
          title: '',
          children: [
            {
              title: () => <p className="mb-0 font-weight-normal" style={{ color: '#797979' }}>Date</p>,
              dataIndex: "option1Date",
              align: 'right',
              hidden: toggle.includes("option1"),
              editable: true,
              width: '150px',
              render: (text) => (text && moment(text).format("YYYY-MM-DD")) || "N/A",
              onCell: (record) => ({
                record,
                inputType: "date",
                dataIndex: "option1Date",
                title: "Option 1",
                editing: isEditing(record),
              }),
            }
          ]
        }]
    },
    {
      id: 11,
      title: () => <p className="mb-0" style={{ color: '#01215d', fontSize: '16px' }}>Option 2</p>,
      hidden: toggle.includes("option2"),
      align: 'center',
      width: '150px',
      sorter: (a, b) => new Date(a.option2Date) - new Date(b.option2Date),
      filter: ['all', 'leseTermRenewalColumn'],
      children: [
        {
          title: '',
          children: [
            {
              title: () => <p className="mb-0 font-weight-normal" style={{ color: '#797979' }}>Date</p>,
              dataIndex: "option2Date",
              align: 'right',
              hidden: toggle.includes("option2"),
              editable: true,
              width: '150px',
              render: (text) => (text && moment(text).format("YYYY-MM-DD")) || "N/A",
              onCell: (record) => ({
                record,
                inputType: "date",
                dataIndex: "option2Date",
                title: "Option 2",
                editing: isEditing(record),
              }),
            }
          ]
        }]
    },
    {
      id: 12,
      title: () => <p className="mb-0" style={{ color: '#01215d', fontSize: '16px' }}>Open-End</p>,
      hidden: toggle.includes("openEnded"),
      align: 'center',
      width: "150px",
      sorter: (a, b) => a.leaseDurationOpenEndInMonths - b.leaseDurationOpenEndInMonths,
      filter: ['all', 'leseTermRenewalColumn'],
      children: [
        {
          title: '',
          children: [{
            title: () => <p className="mb-0 font-weight-normal" style={{ color: '#797979' }}>Months</p>,
            hidden: toggle.includes("openEnded"),
            align: 'right',
            dataIndex: "leaseDurationOpenEndInMonths",
            editable: true,
            width: "150px",
            render: (text) => (text ? getFormattedEnglishNumber(text) : "N/A"),
            onCell: (record) => ({
              record,
              inputType: "number",
              dataIndex: "leaseDurationOpenEndInMonths",
              title: "Open Ended",
              editing: isEditing(record),
            }),
          }]
        }]
    },
    {
      id: 13,
      title: () => <p className="mb-0" style={{ color: '#01215d', fontSize: '16px' }}>Reletting</p>,
      hidden: toggle.includes("reLetting"),
      align: 'center',
      width: "150px",
      sorter: (a, b) => a.leaseDurationInMonths - b.leaseDurationInMonths,
      filter: ['all', 'leseTermRenewalColumn'],
      children: [
        {
          title: '',
          children: [{
            title: () => <p className="mb-0 font-weight-normal" style={{ color: '#797979' }}>Months</p>,
            hidden: toggle.includes("reLetting"),
            dataIndex: "leaseDurationInMonths",
            align: 'right',
            editable: true,
            render: (text) => (text ? getFormattedEnglishNumber(text) : "N/A"),
            width: "150px",
            onCell: (record) => ({
              record,
              inputType: "number",
              dataIndex: "leaseDurationInMonths",
              title: "Re-Letting",
              editing: isEditing(record),
            }),
          }]
        }]
    },
    {
      id: 14,
      title: () => <p className="mb-0" style={{ color: '#01215d', fontSize: '16px' }}>Initial Renewal</p>,
      hidden: toggle.includes("initialRenewalProbability"),
      align: 'center',
      width: '250px',
      sorter: (a, b) => a.initialRenewalProbability - b.initialRenewalProbability,
      filter: ['all', 'leseTermRenewalColumn'],
      children: [
        {
          title: '',
          children: [{
            title: () => <p className="mb-0 font-weight-normal" style={{ color: '#797979' }}>%</p>,
            hidden: toggle.includes("initialRenewalProbability"),
            dataIndex: "initialRenewalProbability",
            align: 'right',
            editable: true,
            render: (text) => (text ? getFormattedDecimalNumber(text) : "N/A"),
            width: '250px',
            onCell: (record) => ({
              record,
              inputType: "number",
              dataIndex: "initialRenewalProbability",
              title: "Initial Renewal",
              editing: isEditing(record),
            }),
          }]
        }]
    },
    {
      id: 15,
      title: () => <p className="mb-0" style={{ color: '#01215d', fontSize: '16px' }}>Continuing Renewal</p>,
      hidden: toggle.includes("continuingRenewalProbability"),
      align: 'center',
      width: '250px',
      sorter: (a, b) => a.continuingRenewalProbability - b.continuingRenewalProbability,
      filter: ['all', 'leseTermRenewalColumn'],
      children: [
        {
          title: '',
          children: [{
            title: () => <p className="mb-0 font-weight-normal" style={{ color: '#797979' }}>%</p>,
            hidden: toggle.includes("continuingRenewalProbability"),
            dataIndex: "continuingRenewalProbability",
            align: 'right',
            editable: true,
            render: (text) => (text ? getFormattedDecimalNumber(text) : "N/A"),
            width: '250px',
            onCell: (record) => ({
              record,
              inputType: "number",
              dataIndex: "continuingRenewalProbability",
              title: "Continuing Renewal",
              editing: isEditing(record),
            }),
          }]
        }]
    },
    {
      id: 16,
      title: () => <p className="mb-0" style={{ color: '#01215d', fontSize: '16px' }}>Type</p>,
      hidden: toggle.includes("indexationTypeId"),
      align: 'center',
      sorter: (a, b) => a.indexationType.translations.en?.localeCompare(b.indexationType.translations.en),
      filter: ['all', 'indexationColumn'],
      children: [
        {
          title: '',
          children: [{
            title: () => <p className="mb-0 font-weight-normal" style={{ color: '#797979' }}>Select</p>,
            hidden: toggle.includes("indexationTypeId"),
            align: 'right',
            dataIndex: ["indexationType", "translations", "en"],
            editable: true,
            width: '200px',
            render: (text) => (text ? text : "N/A"),
            onCell: (record) => ({
              record,
              inputType: "typeDropdown",
              dataIndex: ["indexationType", "id"],
              title: "Type",
              editing: isEditing(record),
            }),
          }]
        }]
    },
    {
      id: 17,
      title: () => <p className="mb-0" style={{ color: '#01215d', fontSize: '16px' }}>Index</p>,
      hidden: toggle.includes("indexTypeId"),
      align: 'center',
      sorter: (a, b) => a.cpiType.translations.en?.localeCompare(b.cpiType.translations.en),
      filter: ['all', 'indexationColumn'],
      children: [
        {
          title: '',
          children: [{
            title: () => <p className="mb-0 font-weight-normal" style={{ color: '#797979' }}>Select</p>,
            dataIndex: ["cpiType", "translations", "en"],
            hidden: toggle.includes("indexTypeId"),
            align: 'right',
            editable: true,
            width: '200px',
            render: (text) => (text ? text : "N/A"),
            onCell: (record) => ({
              record,
              inputType: "indexDropdown",
              dataIndex: ["cpiType", "id"],
              title: "Index",
              editing: isEditing(record),
            }),
          }]
        }]
    },
    {
      id: 18,
      title: () => <p className="mb-0" style={{ color: '#01215d', fontSize: '16px' }}>Last Index Value</p>,
      filter: ['all', 'indexationColumn'],
      dataIndex: "indexationValue",
      align: 'center',
      hidden: toggle.includes("indexationValue"),
      sorter: (a, b) => a.indexationValue - b.indexationValue,
      children: [{
        title: '',
        children: [{
          id: 1,
          title: '',
          dataIndex: "indexationValue",
          align: 'right',
          hidden: toggle.includes("indexationValue"),
          render: (text) => (text ? getFormattedEnglishNumber(text) : "N/A"),
          editable: true,
          width: '230px',
          render: (text) => (text && text) || "N/A",
          onCell: (record) => ({
            record,
            inputType: "number",
            dataIndex: "indexationValue",
            title: "Last Index Value",
            editing: isEditing(record),
          }),
        }]
      }]
    },
    {
      id: 19,
      title: () => <p className="mb-0" style={{ color: '#01215d', fontSize: '16px' }}>Last Index Date</p>,
      hidden: toggle.includes("lastDate"),
      align: 'center',
      sorter: (a, b) => new Date(a.indexationLastDate) - new Date(b.indexationLastDate),
      filter: ['all', 'indexationColumn'],
      children: [
        {
          title: '',
          children: [{
            title: () => <p className="mb-0 font-weight-normal" style={{ color: '#797979' }}>Date</p>,
            dataIndex: "indexationLastDate",
            align: 'right',
            hidden: toggle.includes("lastDate"),
            editable: true,
            width: '240px',
            render: (text) => (text && moment(text).format("YYYY-MM-DD")) || "N/A",
            onCell: (record) => ({
              record,
              inputType: "date",
              dataIndex: "indexationLastDate",
              title: "Last Index Date",
              editing: isEditing(record),
            }),
          }]
        }]
    },
    {
      id: 20,
      title: () => <p className="mb-0" style={{ color: '#01215d', fontSize: '16px' }}>1st Adjustment</p>,
      hidden: toggle.includes("firstDate"),
      align: 'center', // Align right
      sorter: (a, b) => new Date(a.indexationFirstDate) - new Date(b.indexationFirstDate),
      filter: ['all', 'indexationColumn'],
      children: [
        {
          title: '',
          children: [{
            title: () => <p className="mb-0 font-weight-normal" style={{ color: '#797979' }}>Date</p>,
            dataIndex: "indexationFirstDate",
            align: 'right', // Align right
            hidden: toggle.includes("firstDate"),
            editable: true,
            width: '240px',
            render: (text) => (text && moment(text).format("YYYY-MM-DD")) || "N/A",
            onCell: (record) => ({
              record,
              inputType: "date",
              dataIndex: "indexationFirstDate",
              title: "First",
              editing: isEditing(record),
            }),
          }]
        }]
    },
    {
      id: 21,
      title: () => <p className="mb-0" style={{ color: '#01215d', fontSize: '16px' }}>Next Adjustment</p>,
      hidden: toggle.includes("nextDate"),
      align: 'center', // Align right
      sorter: (a, b) => new Date(a.indexationNextDate) - new Date(b.indexationNextDate),
      filter: ['all', 'indexationColumn'],
      children: [
        {
          title: '',
          children: [{
            title: () => <p className="mb-0 font-weight-normal" style={{ color: '#797979' }}>Date</p>,
            dataIndex: "indexationNextDate",
            align: 'right', // Align right
            hidden: toggle.includes("nextDate"),
            editable: true,
            width: '240px',
            render: (text) => (text && moment(text).format("YYYY-MM-DD")) || "N/A",
            onCell: (record) => ({
              record,
              inputType: "date",
              dataIndex: "indexationNextDate",
              title: "Next Adjustment",
              editing: isEditing(record),
            }),
          }]
        }]
    },
    {
      id: 22,
      title: () => <p className="mb-0" style={{ color: '#01215d', fontSize: '16px' }}>Cycle</p>,
      hidden: toggle.includes("cycle"),
      align: 'center', // Align right
      sorter: (a, b) => a.indexationCycleInMonths - b.indexationCycleInMonths,
      filter: ['all', 'indexationColumn'],
      children: [
        {
          title: '',
          children: [{
            title: () => <p className="mb-0 font-weight-normal" style={{ color: '#797979' }}>Months</p>,
            dataIndex: "indexationCycleInMonths",
            align: 'right', // Align right
            hidden: toggle.includes("cycle"),
            editable: true,
            width: '120px',
            render: (text) => (text ? getFormattedEnglishNumber(text) : "N/A"),
            onCell: (record) => ({
              record,
              inputType: "number",
              dataIndex: "indexationCycleInMonths",
              title: "Cycle",
              editing: isEditing(record),
            }),
          }]
        }]
    },
    {
      id: 23,
      title: () => <p className="mb-0" style={{ color: '#01215d', fontSize: '16px' }}>Hurdle</p>,
      dataIndex: "indexationHurdle",
      align: 'center', // Align right
      sorter: (a, b) => a.indexationHurdle - b.indexationHurdle,
      filter: ['all', 'indexationColumn'],
      hidden: toggle.includes("hurdle"),
      children: [{
        title: '',
        children: [{
          id: 1,
          title: '',
          align: 'right', // Align right
          dataIndex: "indexationHurdle",
          hidden: toggle.includes("hurdle"),
          editable: true,
          width: '120px',
          render: (text) => (text ? getFormattedEnglishNumber(text) : "N/A"),
          onCell: (record) => ({
            record,
            inputType: "number",
            dataIndex: "indexationHurdle",
            title: "Hurdle %/pts",
            editing: isEditing(record),
          }),
        }]
      }]
    },
    {
      id: 24,
      title: () => <p className="mb-0" style={{ color: '#01215d', fontSize: '16px' }}>Factor</p>,
      hidden: toggle.includes("factor"),
      align: 'center',
      sorter: (a, b) => a.indexationAdjustmentFactor - b.indexationAdjustmentFactor,
      filter: ['all', 'indexationColumn'],
      children: [
        {
          title: '',
          children: [{
            title: () => <p className="mb-0 font-weight-normal" style={{ color: '#797979' }}>%</p>,
            dataIndex: "indexationAdjustmentFactor",
            hidden: toggle.includes("factor"),
            align: 'right',
            editable: true,
            width: '120px',
            render: (text) => (text ? getFormattedDecimalNumber(text) : "N/A"),
            onCell: (record) => ({
              record,
              inputType: "number",
              dataIndex: "indexationAdjustmentFactor",
              title: "Factor",
              editing: isEditing(record),
            }),
          }]
        }]
    },
    {
      id: 25,
      title: () => <p className="mb-0" style={{ color: '#01215d', fontSize: '16px' }}>Non Recoverable Costs - Terms</p>,
      hidden: toggle.includes("nonRecoverableCosts"),
      align: 'center',
      filter: ['all', 'costVoidsColumn'],
      children: [
        {
          id: 1,
          title: () => <p className="mb-0 font-weight-normal" style={{ color: '#1F1F1F' }}>Maint.</p>,
          hidden: toggle.includes("maintenance"),
          align: 'center',
          children: [{
            title: () => <p className="mb-0 font-weight-normal" style={{ color: '#797979' }}>€ / SQ M</p>,
            hidden: toggle.includes("maintenance"),
            dataIndex: "nrctMaintenance",
            align: 'right',
            editable: true,
            width: '150px',
            sorter: (a, b) => a.nrctMaintenance - b.nrctMaintenance,
            render: (text) => (text ? getFormattedEnglishNumber(text) : "N/A"),
            onCell: (record) => ({
              record,
              inputType: "number",
              dataIndex: "nrctMaintenance",
              title: "Maintenance",
              editing: isEditing(record),
            }),
          }]
        },
        {
          id: 2,
          title: () => <p className="mb-0 font-weight-normal" style={{ color: '#1F1F1F' }}>Mgmt.</p>,
          hidden: toggle.includes("perMonth"),
          align: 'center',
          children: [{
            title: () => <p className="mb-0 font-weight-normal" style={{ color: '#797979' }}>%</p>,
            hidden: toggle.includes("perMonth"),
            dataIndex: "nrctManagement",
            align: 'right',
            editable: true,
            width: '120px',
            sorter: (a, b) => a.nrctManagement - b.nrctManagement,
            render: (text) => (text ? getFormattedDecimalNumber(text) : "N/A"),
            onCell: (record) => ({
              record,
              inputType: "number",
              dataIndex: "nrctManagement",
              title: "Management",
              editing: isEditing(record),
            }),
          }]
        },
        {
          id: 3,
          title: () => <p className="mb-0 font-weight-normal" style={{ color: '#1F1F1F' }}>Other</p>,
          hidden: toggle.includes("otherCosts"),
          align: 'center',
          children: [{
            title: () => <p className="mb-0 font-weight-normal" style={{ color: '#797979' }}>%</p>,
            hidden: toggle.includes("otherCosts"),
            dataIndex: "nrctOtherCosts",
            align: 'right',
            editable: true,
            width: '120px',
            sorter: (a, b) => a.nrctOtherCosts - b.nrctOtherCosts,
            render: (text) => (text ? getFormattedDecimalNumber(text) : "N/A"),
            onCell: (record) => ({
              record,
              inputType: "number",
              dataIndex: "nrctOtherCosts",
              title: "Other Costs",
              editing: isEditing(record),
            }),
          }]
        }
      ]
    },
    {
      id: 26,
      title: () => <p className="mb-0" style={{ color: '#01215d', fontSize: '16px' }}>Non Recoverable Costs - Reletting</p>,
      hidden: toggle.includes("nonRecoverableCosts"),
      align: 'center',
      filter: ['all', 'costVoidsColumn'],
      children: [
        {
          id: 1,
          title: () => <p className="mb-0 font-weight-normal" style={{ color: '#1F1F1F' }}>Maint.</p>,
          hidden: toggle.includes("maintenance"),
          align: 'center',
          children: [{
            title: () => <p className="mb-0 font-weight-normal" style={{ color: '#797979' }}>€ / SQ M</p>,
            hidden: toggle.includes("maintenance"),
            dataIndex: "nrcrMaintenance",
            align: 'right',
            editable: true,
            width: '160px',
            sorter: (a, b) => a.nrcrMaintenance - b.nrcrMaintenance,
            render: (text) => (text ? getFormattedEnglishNumber(text) : "N/A"),
            onCell: (record) => ({
              record,
              inputType: "number",
              dataIndex: "nrcrMaintenance",
              title: "Maintenance",
              editing: isEditing(record),
            }),
          }]
        },
        {
          id: 2,
          title: () => <p className="mb-0 font-weight-normal" style={{ color: '#1F1F1F' }}>Mgmt.</p>,
          hidden: toggle.includes("perMonth"),
          align: 'center',
          children: [{
            title: () => <p className="mb-0 font-weight-normal" style={{ color: '#797979' }}>%</p>,
            hidden: toggle.includes("perMonth"),
            dataIndex: "nrcrManagement",
            align: 'right',
            editable: true,
            width: '120px',
            sorter: (a, b) => a.nrcrManagement - b.nrcrManagement,
            render: (text) => (text ? getFormattedDecimalNumber(text) : "N/A"),
            onCell: (record) => ({
              record,
              inputType: "number",
              dataIndex: "nrcrManagement",
              title: "Management",
              editing: isEditing(record),
            }),
          }]
        },
        {
          id: 3,
          title: () => <p className="mb-0 font-weight-normal" style={{ color: '#1F1F1F' }}>Other</p>,
          hidden: toggle.includes("otherCosts"),
          align: 'center',
          children: [{
            title: () => <p className="mb-0 font-weight-normal" style={{ color: '#797979' }}>%</p>,
            hidden: toggle.includes("otherCosts"),
            dataIndex: "nrcrOtherCosts",
            align: 'right',
            editable: true,
            width: '150px',
            sorter: (a, b) => a.nrcrOtherCosts - b.nrcrOtherCosts,
            render: (text) => (text ? getFormattedDecimalNumber(text) : "N/A"),
            onCell: (record) => ({
              record,
              inputType: "number",
              dataIndex: "nrcrOtherCosts",
              title: "Other Costs",
              editing: isEditing(record),
            }),
          }]
        }
      ]
    },
    {
      id: 27,
      title: () => <p className="mb-0" style={{ color: '#01215d', fontSize: '16px' }}>Void Periods</p>,
      hidden: toggle.includes("voidPeriods"),
      align: 'center',
      filter: ['all', 'costVoidsColumn'],
      children: [
        {
          id: 1,
          title: () => <p className="mb-0 font-weight-normal" style={{ color: '#1F1F1F' }}>Initials</p>,
          hidden: toggle.includes("maintenance"),
          align: 'center',
          children: [{
            title: () => <p className="mb-0 font-weight-normal" style={{ color: '#797979' }}>Months</p>,
            hidden: toggle.includes("maintenance"),
            dataIndex: "initialVoidPeriod",
            align: 'right',
            editable: true,
            width: '150px',
            sorter: (a, b) => a.initialVoidPeriod - b.initialVoidPeriod,
            render: (text) => (text ? getFormattedEnglishNumber(text) : "N/A"),
            onCell: (record) => ({
              record,
              inputType: "number",
              dataIndex: "initialVoidPeriod",
              title: "Initials",
              editing: isEditing(record),
            }),
          }]
        },
        {
          id: 2,
          title: () => <p className="mb-0 font-weight-normal" style={{ color: '#1F1F1F' }}>Cont.</p>,
          hidden: toggle.includes("perMonth"),
          align: 'center',
          children: [{
            title: () => <p className="mb-0 font-weight-normal" style={{ color: '#797979' }}>Months</p>,
            hidden: toggle.includes("perMonth"),
            dataIndex: "continuingVoidPeriod",
            align: 'right',
            editable: true,
            width: '150px',
            sorter: (a, b) => a.continuingVoidPeriod - b.continuingVoidPeriod,
            render: (text) => (text ? getFormattedEnglishNumber(text) : "N/A"),
            onCell: (record) => ({
              record,
              inputType: "number",
              dataIndex: "continuingVoidPeriod",
              title: "Cont.",
              editing: isEditing(record),
            }),
          }]
        }
      ]
    },
    {
      id: 28,
      title: () => <p className="mb-0" style={{ color: '#01215d', fontSize: '16px' }}>Vacancy</p>,
      hidden: toggle.includes("vancancy"),
      align: 'center',
      filter: ['all', 'costVoidsColumn'],
      children: [
        {
          id: 1,
          title: () => <p className="mb-0 font-weight-normal" style={{ color: '#1F1F1F' }}>Costs</p>,
          hidden: toggle.includes("maintenance"),
          align: 'center',
          children: [{
            title: () => <p className="mb-0 font-weight-normal" style={{ color: '#797979' }}>€ / SQ M / Y</p>,
            hidden: toggle.includes("maintenance"),
            align: 'right',
            dataIndex: "vacancyCosts",
            editable: true,
            width: '160px',
            sorter: (a, b) => a.vacancyCosts - b.vacancyCosts,
            render: (text) => (text ? getFormattedEnglishNumber(text) : "N/A"),
            onCell: (record) => ({
              record,
              inputType: "number",
              dataIndex: "vacancyCosts",
              title: "Initials",
              editing: isEditing(record),
            }),
          }]
        }
      ]
    },
    {
      id: 29,
      title: () => <p className="mb-0" style={{ color: '#01215d', fontSize: '16px' }}>Reletting Costs</p>,
      hidden: toggle.includes("relettingCosts"),
      align: 'center',
      filter: ['all', 'costVoidsColumn'],
      children: [
        {
          id: 1,
          title: () => <p className="mb-0 font-weight-normal" style={{ color: '#1F1F1F' }}>Initial TIC</p>,
          hidden: toggle.includes("maintenance"),
          align: 'center',
          children: [{
            title: () => <p className="mb-0 font-weight-normal" style={{ color: '#797979' }}>€</p>,
            hidden: toggle.includes("maintenance"),
            dataIndex: "relettingCostsInitialTIs",
            align: 'right',
            editable: true,
            width: '150px',
            sorter: (a, b) => a.relettingCostsInitialTIs - b.relettingCostsInitialTIs,
            render: (text) => (text ? getFormattedEnglishNumber(text) : "N/A"),
            onCell: (record) => ({
              record,
              inputType: "number",
              dataIndex: "relettingCostsInitialTIs",
              title: "Maintenance",
              editing: isEditing(record),
            }),
          }]
        },
        {
          id: 2,
          title: () => <p className="mb-0 font-weight-normal" style={{ color: '#1F1F1F' }}>Cont. TIC</p>,
          hidden: toggle.includes("perMonth"),
          align: 'center',
          children: [{
            title: () => <p className="mb-0 font-weight-normal" style={{ color: '#797979' }}>€</p>,
            hidden: toggle.includes("perMonth"),
            dataIndex: "relettingCostsContinuingTIs",
            align: 'right',
            editable: true,
            width: '150px',
            sorter: (a, b) => a.relettingCostsContinuingTIs - b.relettingCostsContinuingTIs,
            render: (text) => (text ? getFormattedEnglishNumber(text) : "N/A"),
            onCell: (record) => ({
              record,
              inputType: "number",
              dataIndex: "relettingCostsContinuingTIs",
              title: "Management",
              editing: isEditing(record),
            }),
          }]
        },
        {
          id: 3,
          title: () => <p className="mb-0 font-weight-normal" style={{ color: '#1F1F1F' }}>Agent C.</p>,
          hidden: toggle.includes("otherCosts"),
          align: 'center',
          children: [{
            title: () => <p className="mb-0 font-weight-normal" style={{ color: '#797979' }}>Months</p>,
            hidden: toggle.includes("otherCosts"),
            dataIndex: "relettingCostsAgent",
            align: 'right',
            editable: true,
            width: '150px',
            sorter: (a, b) => a.relettingCostsAgent - b.relettingCostsAgent,
            render: (text) => (text ? getFormattedEnglishNumber(text) : "N/A"),
            onCell: (record) => ({
              record,
              inputType: "number",
              dataIndex: "relettingCostsAgent",
              title: "Other Costs",
              editing: isEditing(record),
            }),
          }]
        }
      ]
    }
  ];

  const [rentRollsColumns, setRentRollsColumns] = useState(initialColState)

  useEffect(() => {
    setRentRollsColumns(initialColState?.filter(item => item?.filter?.includes(columnSelect)))
  }, [columnSelect])

  const CBData = [
    {
      key: 1,
      value: "group",
      filter: ['all', 'currentRentColumn', 'leseTermRenewalColumn', 'indexationColumn', 'costVoidsColumn'],
    },
    {
      key: 2,
      value: "tenantName",
      name: "Tanent Name",
      isChecked: false,
      filter: ['all', 'currentRentColumn', 'leseTermRenewalColumn', 'indexationColumn', 'costVoidsColumn'],
    },
    {
      key: 3,
      value: "useType",
      name: "Use",
      isChecked: false,
      filter: ['all', 'currentRentColumn', 'leseTermRenewalColumn', 'indexationColumn', 'costVoidsColumn'],
    },
    {
      key: 4,
      value: "rentalSpace",
      name: "Area/Unit",
      isChecked: false,
      filter: ['all', 'currentRentColumn', 'leseTermRenewalColumn', 'indexationColumn', 'costVoidsColumn'],
    },
    {
      key: 5,
      value: "currentRentPerSqm",
      name: "Passing Rent €/SQM",
      isChecked: false,
      filter: ['all', 'currentRentColumn'],
    },
    {
      key: 6,
      value: "currentRentPerMonth",
      name: "Passing Rent €/Month",
      isChecked: false,
      filter: ['all', 'currentRentColumn'],
    },
    {
      key: 7,
      value: "currentRentPerYear",
      name: "Passing Rent €/Year",
      isChecked: false,
      filter: ['all', 'currentRentColumn'],
    },
    {
      key: 8,
      value: "marketRentPerSqm",
      name: "Market Rent €/SQM",
      isChecked: false,
      filter: ['all', 'currentRentColumn'],
    },
    {
      key: 9,
      value: "marketRentPerMonth",
      name: "Market Rent €/Month",
      isChecked: false,
      filter: ['all', 'currentRentColumn'],
    },
    {
      key: 10,
      value: "marketRentPerYear",
      name: "Market Rent €/Year",
      isChecked: false,
      filter: ['all', 'currentRentColumn'],
    },
    {
      key: 11,
      value: "leaseStartDate",
      name: "Lease Start",
      isChecked: false,
      filter: ['all', 'leseTermRenewalColumn'],
    },
    {
      key: 12,
      value: "leaseEndDate",
      name: "Lease End",
      isChecked: false,
      filter: ['all', 'leseTermRenewalColumn'],
    },
    {
      key: 13,
      value: "option1Date",
      name: "Option 1",
      isChecked: false,
      filter: ['all', 'leseTermRenewalColumn'],
    },
    {
      key: 14,
      value: "option2Date",
      name: "Option 2",
      isChecked: false,
      filter: ['all', 'leseTermRenewalColumn'],
    },
    {
      key: 15,
      value: "leaseDurationOpenEndInMonths",
      name: "Open End",
      isChecked: false,
      filter: ['all', 'leseTermRenewalColumn'],
    },
    {
      key: 16,
      value: "leaseDurationInMonths",
      name: "Reletting",
      isChecked: false,
      filter: ['all', 'leseTermRenewalColumn'],
    },
    {
      key: 17,
      value: "initialRenewalProbability",
      name: "Initial Renewal",
      isChecked: false,
      filter: ['all', 'leseTermRenewalColumn'],
    },
    {
      key: 18,
      value: "continuingRenewalProbability",
      name: "Continuing Renewal",
      isChecked: false,
      filter: ['all', 'leseTermRenewalColumn'],
    },
    {
      key: 19,
      value: "indexationType",
      name: "Type",
      isChecked: false,
      filter: ['all', 'indexationColumn'],
    },
    {
      key: 20,
      value: "cpiType",
      name: "Index",
      isChecked: false,
      filter: ['all', 'indexationColumn'],
    },
    {
      key: 21,
      value: "indexationValue",
      name: "Last Index Value",
      isChecked: false,
      filter: ['all', 'indexationColumn'],
    },
    {
      key: 22,
      value: "indexationLastDate",
      name: "Last Index Date",
      isChecked: false,
      filter: ['all', 'indexationColumn'],
    },
    {
      key: 23,
      value: "indexationFirstDate",
      name: "1st Adjustment",
      isChecked: false,
      filter: ['all', 'indexationColumn'],
    },
    {
      key: 24,
      value: "indexationNextDate",
      name: "Next Adjustment",
      isChecked: false,
      filter: ['all', 'indexationColumn'],
    },
    {
      key: 25,
      value: "indexationCycleInMonths",
      name: "Cycle",
      isChecked: false,
      filter: ['all', 'indexationColumn'],
    },
    {
      key: 26,
      value: "indexationHurdle",
      name: "Hurdle",
      isChecked: false,
      filter: ['all', 'indexationColumn'],
    },
    {
      key: 27,
      value: "indexationAdjustmentFactor",
      name: "Factor",
      isChecked: false,
      filter: ['all', 'indexationColumn'],
    },
    {
      key: 28,
      value: "nrctMaintenance",
      name: "Non Recoverable Costs -Terms Maintainance",
      isChecked: false,
      filter: ['all', 'costVoidsColumn'],
    },
    {
      key: 29,
      value: "nrctManagement",
      name: "Non Recoverable Costs -Terms Management",
      isChecked: false,
      filter: ['all', 'costVoidsColumn'],
    },
    {
      key: 30,
      value: "nrctOtherCosts",
      name: "Non Recoverable Costs -Terms Other",
      isChecked: false,
      filter: ['all', 'costVoidsColumn'],
    },
    {
      key: 31,
      value: "nrcrMaintenance",
      name: "Non Recoverable Costs -Reletting Maintainance",
      isChecked: false,
      filter: ['all', 'costVoidsColumn'],
    },
    {
      key: 32,
      value: "nrcrManagement",
      name: "Non Recoverable Costs -Reletting Management",
      isChecked: false,
      filter: ['all', 'costVoidsColumn'],
    },
    {
      key: 33,
      value: "nrcrOtherCosts",
      name: "Non Recoverable Costs -Reletting Other",
      isChecked: false,
      filter: ['all', 'costVoidsColumn'],
    },
    {
      key: 34,
      value: "initialVoidPeriod",
      name: "Void Period Initials",
      isChecked: false,
      filter: ['all', 'costVoidsColumn'],
    },
    {
      key: 35,
      value: "continuingVoidPeriod",
      name: "Void Period Cont",
      isChecked: false,
      filter: ['all', 'costVoidsColumn'],
    },
    {
      key: 36,
      value: "vacancyCosts",
      name: "Vacancy Cost",
      isChecked: false,
      filter: ['all', 'costVoidsColumn'],
    },
    {
      key: 37,
      value: "relettingCostsInitialTIs",
      name: "Reletting Costs Initial TIC",
      isChecked: false,
      filter: ['all', 'costVoidsColumn'],
    },
    {
      key: 38,
      value: "relettingCostsContinuingTIs",
      name: "Reletting Costs Cont. TIC",
      isChecked: false,
      filter: ['all', 'costVoidsColumn'],
    },
    {
      key: 39,
      value: "relettingCostsAgent",
      name: "Reletting Costs Agent C.",
      isChecked: false,
      filter: ['all', 'costVoidsColumn'],
    },
  ];

  const handleSearch = (e) => {
    const { value } = e.target
    // setRentRollsDataByAsset(rentRollsByAsset?.filter(item => item.tenantName?.toLowerCase()?.includes(value?.toLowerCase()))) // will filter/ search from BE side
    // Memoize the filtered results
    const lowerCaseValue = value?.toLowerCase();
    const filteredData = rentRollsByAsset?.filter(item => {
      // Check if tenantName, use, or status includes the search value
      const tenantNameMatch = item.tenantName?.toLowerCase()?.includes(lowerCaseValue);
      const useMatch = item?.useType?.translations?.en?.toLowerCase()?.includes(lowerCaseValue);
      const statusMatch = item?.rentRollStatusType?.translations?.en?.toLowerCase()?.includes(lowerCaseValue);

      return tenantNameMatch || useMatch || statusMatch;
    });

    setRentRollsDataByAsset(filteredData);
  }

  const debouncedResults = useMemo(() => {
    return debounce(handleSearch, 500);
  }, []);

  useEffect(() => {
    const finalData = {
      catalog: "core-data",
      context: "indexationTypes",
    }
    dispatch(
      getByTypeDropdown({ finalData })
    );
  }, []);

  useEffect(() => {
    const finalData = {
      catalog: "core-data",
      context: "cpiTypes",
    }
    dispatch(
      getByTypeDropdown({ finalData })
    );
  }, [dispatch]);
  useEffect(() => {
    const finalData = {
      catalog: "core-data",
      context: "rentRollUseGroupTypes",
    }
    dispatch(
      getByTypeDropdown({ finalData })
    );
  }, [dispatch]);

  useEffect(() => {
    const finalData = {
      catalog: "core-data",
      context: "rentRollUseTypes",
    }
    dispatch(getByTypeDropdown({ finalData }));
  }, [dispatch]);

  useEffect(() => {
    const finalData = {
      catalog: "core-data",
      context: "rentRollStatusTypes",
    };
    dispatch(getByTypeDropdown({ finalData: finalData }));
  }, [dispatch]);
  return (
    <div className="rentRoll">
      <Menu
        onClick={onClick}
        selectedKeys={[current]}
        mode="horizontal"
      // items={items}
      />
      {/* <Sticky enabled={true} top={109} innerZ={10} innerActiveClass="stickyTabs"> */}
      <Row
        gutter={24}
        className="d-flex justify-content-between align-items-center button-search"
        style={{ margin: "0 !important" }}
      >
        <Col xs={12} lg={showDrawer ? 4 : 8} className="d-flex align-items-center" style={{ gap: '10px' }}>
          <div>
            <Dropdown
              overlay={addNewDropdown}
              trigger={["click"]}
              direction="vertical"
            >
              <Button
                type="default"
                className="add-project-btn d-flex justify-content-between align-items-center"
              >
                <h5 className="mb-0 ml-0">Add New</h5>
                <span className="project-btn-span asset-details-add-new-btn">
                  <IoIosArrowDown size={30} />
                </span>
              </Button>
            </Dropdown>
          </div>
          {!showDrawer && <h4 className="mb-0"> Asset Value: {getFormattedEnglishNumber(newNetAssetValue)} €</h4>}
        </Col>

        {/* {!showDrawer &&
          <Col xs={24} lg={4}>
            <h4 className="mb-0"> Asset Value: {getFormattedEnglishNumber(newNetAssetValue)} €</h4>
          </Col>
        } */}
        <Col xs={24} lg={showDrawer ? 20 : 16}>
          <div className="d-flex justify-content-end searchbar-project" style={{ flexWrap: 'wrap', gap: '0.5rem' }}>
            <Input
              size="large"
              style={{ width: "287px", height: '44px' }}
              prefix={
                <FiSearch size={20} style={{ color: "rgba(0, 0, 0, 0.45)" }} />
              }
              onChange={debouncedResults}
              // onKeyDown={(e) => {
              //   if (e.key === "Enter") {
              //     setSearchValue(e.target.value);
              //   }
              // }}
              // onChange={(e) => {
              //   if (e.key === "Enter") {
              //     setSearchValue(e.target.value);
              //   }
              // }}
              className=""
              placeholder="Search a Rent roll"
            />
            <Tooltip title="Save changes" color="#00215B" key="#00215B">
              <div>
                <Button
                  icon={< IMAGES.SAVE_ICON />}
                  className="save-icon-btn  cursor-pointer"
                  // onClick={saveCalculationDetails}
                  disabled={true}
                />  </div>
            </Tooltip>
            {/* </Tooltip>*/}

            <Tooltip title="Reset changes" color="#00215B" key="#00215B">
              <div>
                <Button
                  icon={<LuRefreshCw size={24} />}
                  className="reset-icon-btn  cursor-pointer"
                  // onClick={resetCalculationDetail}
                  disabled={true}
                /></div>
            </Tooltip>
            <Tooltip title="Copy Rentroll" color="#00215b" key="#00215b">
              <span className="filter-icon-span  cursor-pointer">
                <img src={IMAGES.COPY} alt="add-row-down" style={{ width: '24px', height: "24px" }} />
              </span>
            </Tooltip>
            {/* <Tooltip title="Filter" color="#00215b" key="#00215b">
              <span
                onClick={() => setIsModalOpen(true)}
                className="filter-icon-span  cursor-pointer"
              >
                <RiFilter2Line size={24} />
              </span>
            </Tooltip> */}
            {/* <span className="filter-icon-span  cursor-pointer">
              <img src={IMAGES.ADD_ROW_DOWN} alt="add-row-down" style={{ width: '24px', height: "28px" }} />
            </span>
            <span className="filter-icon-span  cursor-pointer">
              <img src={IMAGES.ADD_ROW_UP} alt="add-row-up" style={{ width: '24px', height: "28px" }} />
            </span> */}
            <Dropdown
              overlay={hideTableColumns}
              trigger={["click"]}
              onOpenChange={handleOpenChange}
              className="custom-scroll"
              open={open}
            >
              <Tooltip title="Hide/Show columns" color="#00215b" key="#00215b">
                <span className="filter-icon-span  cursor-pointer">
                  <img src={IMAGES.TABLE_ICON} alt="Table Icon" />
                </span>
              </Tooltip>
            </Dropdown>
            {/* <span
              className="filter-icon-span  cursor-pointer"
            >
              <SettingOutlined style={{ fontSize: '20px' }} />
            </span>
            <span
              className="filter-icon-span  cursor-pointer"
            >
              <IMAGES.IMPORT_ICON />
            </span>
            <span
              className="filter-icon-span  cursor-pointer"
            >
              <AiOutlineDownload size={24} />
            </span> */}
            <Tooltip title="Delete Rentroll" color="#00215b" key="#00215b">
              <div>
                <Button
                  className="reset-icon-btn cursor-pointer"
                  // style={{ color: "#FC0606" }}
                  onClick={() => setDeleteUserModal(true)}
                  disabled={selectedRowKeys?.length === 0 ? true : false}
                  role="button"
                  tabIndex="0"
                >
                  <AiOutlineDelete size={24} />
                </Button>
              </div>
            </Tooltip>
            <Select
              placeholder="Select"
              optionFilterProp="children"
              className="custom-select"
              options={columnSelection}
              value={columnSelect} onChange={(newValue) => setColumnSelect(newValue)}
              style={{
                width: "190px",
                "& .ant-select-selector": {
                  border: "1px solid #00215b !important",
                },
              }}
            />
            {AssetSelect && <AssetSelect />}
            <Tooltip
              title="show asset detail"
              color="#00215B"
              key="#00215B"
            >
              {/* drawer-close-btn */}
              <span
                className={`project-drawer-close-btn cursor-pointer   ${showDrawer ? "d-none" : ""
                  }`}
                role="button"
                tabIndex="0" onClick={handleToggleDrawer}
              >
                <MdOutlineKeyboardArrowLeft
                  size={25}
                  fill="#fff"

                />
              </span>
            </Tooltip>
          </div>
        </Col>
      </Row>
      {/* </Sticky> */}
      <div style={{ paddingTop: '20px' }}>
        <RentRollsTable
          sethideTableColumns={sethideTableColumns}
          activeKey={activeKey}
          closeModal={closeModal}
          assetDetails={assetDetails}
          rentRollsColumns={rentRollsColumns}
          setRentRollsColumns={setRentRollsColumns}
          tableColumns={initialColState}
          columnSelect={columnSelect}
          hideableColumns={CBData}
          editableCell={EditableCell}
          notifyToaster={notifyToaster}
          editingKey={editingKey}
          setEditingKey={setEditingKey}
          isEditing={isEditing}
          prepareUpdatedRentRoll={prepareUpdatedRentRoll}
          prepareEditValues={prepareEditValues}
          selectedRowKeys={selectedRowKeys} setSelectedRowKeys={setSelectedRowKeys}
          rentRollsByAsset={rentRollsDataByAsset} isCalculationDetailLoading={isCalculationDetailLoading} calculationDetail={calculationDetail}
        />
      </div>

      {/* {isModalOpen &&  */}
      <SearchForm
        visible={isModalOpen}
        handleCancel={closeModal}
        rentRollsByAsset={rentRollsByAsset}
        setRentRollsDataByAsset={setRentRollsDataByAsset}
      />
      {/* } */}
      {
        addUpdateNewRentRoll && <CreateUpdateRentRoll
          visible={addUpdateNewRentRoll}
          handleCancel={AddUpdateNewRentRollcloseModal}
        />
      }
      {
        deleteUserModal && <DeleteUserModal
          visible={deleteUserModal}
          handleCancel={closeDeleteUserModal}
          user="Asset"
          onSubmit={deleteProjectDetail}
          isLoaded={isLoading}
        />
      }
    </div >
  );
}

export default RentRoll;
