/* eslint-disable */
import {
  Button,
  // Input,
  Dropdown,
  Form,
  Menu,
  Tooltip,
  // Tooltip,
  Typography,
  notification
} from "antd";
import { useEffect, useMemo, useState } from "react";
import debounce from "lodash.debounce";
import {
  AiOutlineDelete,
  AiOutlinePlus
} from "react-icons/ai";
import { FaRegCopy, FaShareSquare } from "react-icons/fa";
import { FiSearch } from "react-icons/fi";
import { RiEditLine, RiFilter2Line } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
// import { CheckCircleFilled } from "@ant-design/icons";
import { CheckCircleFilled } from "@ant-design/icons";
import { Input } from "antd-v5";
import {
  BsFillEyeFill,
  BsThreeDotsVertical
} from "react-icons/bs";
import {
  MdArchive,
  MdModeEdit,
  MdOutlineAssignmentTurnedIn
} from "react-icons/md";
import {
  archiveProject,
  completeProject,
  deleteProject,
  getAllArchivedProjects,
  getAllCompletedProjects,
  getAllProjectList,
} from "../../../app/features/Projects/project.slice";
import ArchiveImg from "../../../assets/images/archive-img";
import DeleteUserModal from "../../../components/Layout/DeleteModel/DeleteUserModal";
import DataTable from "../../../components/Layout/Table/DataTable";
import PATH from "../../../utils/path";
import CreateUpdate from "./CreateUpdate";
import ArchiveProjects from "./component/Projects/ArchiveProjects";
import CompleteProjects from "./component/Projects/CompleteProjects";
import SearchForm from "./component/SearchForm";
import UserList from "./component/UserList";
import { Project } from "reia-rest-client";
import { PROJECTS_PAGE_SIZE } from "../../../utils/helpers";

const { Search } = Input;

function Index() {
  const dispatch = useDispatch();
  const location = useLocation();
  // const [cheacky, setCheacky] = useState([]);
  const navigate = useNavigate();
  const { Text } = Typography;
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [addUpdateUserModal, setAddUpdateUserModal] = useState(false);
  const [addUpdateNewProjectModal, setAddUpdateNewProjectModal] =
    useState(false);
  const [flag, setFlag] = useState(false);
  const [deleteUserModal, setDeleteUserModal] = useState(false);
  const [active, setActive] = useState(true);
  const [showNewProject, setShowNewProject] = useState(true);
  const [showCompletedProject, setShowCompletedProject] = useState(false);
  const [showProject, setShowProject] = useState('newProject'); // may change in the future when lookups are functional
  const [showArchiveProject, setShowArchiveProject] = useState(false);
  const { projects, isLoading } = useSelector((state) => state.projects);
  const { user } = useSelector((state) => state.auth);
  const [projectsData, setProjectsData] = useState(projects?.content)
  const [currentPage, setCurrentPage] = useState(1)
  const [currentPageSize, setCurrentPageSize] = useState(PROJECTS_PAGE_SIZE)

  const onNewProjects = () => {
    setShowNewProject(true);
    setShowCompletedProject(false);
    setShowArchiveProject(false);
    setActive(false);
  };
  const onCompletedProjects = () => {
    setShowCompletedProject(true);
    setShowNewProject(false);
    setShowArchiveProject(false);
    setActive(false);
  };
  const onArchiveProjects = () => {
    setShowProject('archiveProject')
    setShowArchiveProject(true);
    setShowCompletedProject(false);
    setShowNewProject(false);
  };

  const closeAddUpdateNewProjectModal = () => {
    setAddUpdateNewProjectModal(false);
  };

  const closeAddUpdateUserModal = () => {
    setAddUpdateUserModal(false);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    form.resetFields();
  };
  const closeDeleteUserModal = () => {
    setDeleteUserModal(false);
  };
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const pageNumbers =
    //   projectFilter.pagination.page * projectFilter.pagination.size -
    //   projectFilter.pagination.size +
    //   1;

    // const nextPageNumber =
    //   projectFilter.pagination.page * projectFilter.pagination.size >
    //     projects?.totalElements
    //     ? projects?.totalElements
    //     : projectFilter.pagination.page * projectFilter.pagination.size;

    // // const handleSearch = (e) => {
    // //   setSearchValue(e.target.value);
    // // };
    // // const handleSearchProjectManagerIdValue = (e) => {
    // //   setSearchProjectManagerIdValue(e);
    // // };
    // // const handleSearchProjectTypeIdValue = (e) => {
    // //   setSearchProjectTypeIdValue(e);
    // // };
    // // const handleSearchProjectStatusIdValue = (e) => {
    // //   setSearchProjectStatusIdValue(e);
    // // };
    // useEffect(() => {
    //   if (
    //     searchValue?.length > 3 ||
    //     searchValue?.length == 0 ||
    //     searchValue?.length == "" ||
    //     searchProjectManagerIdValue ||
    //     searchProjectStatusIdValue ||
    //     searchProjectTypeIdValue ||
    //     projectFilter
    //   )
    //     if (showProject === 'newProject') {
    //       // if (showNewProject) {
    //       dispatch(
    //         getAllProjectList({
    //           search: searchValue ? searchValue : "",
    //           projectManagerId: searchProjectManagerIdValue
    //             ? searchProjectManagerIdValue
    //             : null,
    //           ProjectStatusId: searchProjectStatusIdValue
    //             ? searchProjectStatusIdValue
    //             : null,
    //           ProjectTypeId: searchProjectTypeIdValue
    //             ? searchProjectTypeIdValue
    //             : null,
    //           page: projectFilter.pagination.page,
    //           size: projectFilter.pagination.size,
    //         })
    //       );
    //     } else if (showProject === 'completedProject') {
    //       console.log(showProject)
    //       // } else if (showCompletedProject) {
    //       dispatch(
    //         getAllCompletedProjects({
    //           search: searchValue ? searchValue : "",
    //           projectManagerId: searchProjectManagerIdValue
    //             ? searchProjectManagerIdValue
    //             : null,
    //           ProjectStatusId: searchProjectStatusIdValue
    //             ? searchProjectStatusIdValue
    //             : null,
    //           ProjectTypeId: searchProjectTypeIdValue
    //             ? searchProjectTypeIdValue
    //             : null,
    //           page: projectFilter.pagination.page,
    //           size: projectFilter.pagination.size,
    //         })
    //       );
    //     } else if (showProject === 'archiveProject') {
    //       dispatch(
    //         getAllArchivedProjects({
    //           search: searchValue ? searchValue : "",
    //           projectManagerId: searchProjectManagerIdValue
    //             ? searchProjectManagerIdValue
    //             : null,
    //           ProjectStatusId: searchProjectStatusIdValue
    //             ? searchProjectStatusIdValue
    //             : null,
    //           ProjectTypeId: searchProjectTypeIdValue
    //             ? searchProjectTypeIdValue
    //             : null,
    //           page: projectFilter.pagination.page,
    //           size: projectFilter.pagination.size,
    //         })
    //       );
    //     }
    // }, [
    //   dispatch,
    //   showProject,
    //   searchValue,
    //   searchProjectManagerIdValue,
    //   searchProjectStatusIdValue,
    //   searchProjectTypeIdValue,
    //   projectFilter,
    currentPage * currentPageSize - currentPageSize + 1;

  const nextPageNumber =
    currentPage * currentPageSize > projects?.totalElements ? projects?.totalElements
      : currentPage * currentPageSize

  useEffect(() => {
    loadProjects()
  }, [
    dispatch,
    showNewProject,
    showArchiveProject,
    showCompletedProject,
  ]);

  const loadProjects = () => {
    if (showNewProject) {
      dispatch(
        getAllProjectList({
          page: currentPage,
          size: -1,
        })
      );
    } else if (showCompletedProject) {
      dispatch(
        getAllCompletedProjects({
          page: currentPage,
          size: -1,
        })
      );
    } else if (showArchiveProject) {
      dispatch(
        getAllArchivedProjects({
          page: currentPage,
          size: -1,
        })
      );
    }
  }

  const uniqueFilterData = (data) => {
    const filteredData = Array.from(new Set(data?.map(item => item.value)?.filter(value => value !== '' && value !== null && value !== undefined) // Exclude empty values
    ))?.map(value => {
      return data.find(item => item.value === value);
    });

    return filteredData;
  }

  const projectNameFilter = projects?.content?.map(item => ({ text: item.projectName, value: item.projectName }))
  const clientNameFilter = projects?.content?.map(item => ({ text: item.clientName, value: item.clientName }))
  const projectStatusFilter = projects?.content?.map(item => ({ text: item?.projectStatus?.translations?.en, value: item?.projectStatus?.translations?.en }))
  const projectTypeFilter = projects?.content?.map(item => ({ text: item?.projectType?.translations?.en, value: item?.projectType?.translations?.en }))

  const initialColState = [
    {
      id: 0,
      hidden: false,
      title: "Project Reference",
      dataIndex: "id",
      align: "center",
      width: "200px",
      fixed: 'left',
      sorter: (a, b) => a.id - b.id
    },
    {
      id: 11,
      hidden: false,
      title: "Project Name",
      dataIndex: "projectName",
      render: (text) => (text ? text : "N/A"),
      sorter: (a, b) => a.projectName?.localeCompare(b.projectName),
      filters: uniqueFilterData(projectNameFilter),
      onFilter: (value, record) => record?.projectName?.includes(value),
    },
    {
      id: 1,
      //hidden:  user?.roleId === 3 ? true : false,
      hidden: true,
      title: "Project Manager",
      dataIndex: "projectManager",
      render: (text) => (text ? text : null),
      sorter: (a, b) => a.projectManager?.localeCompare(b.projectManager),
    },
    {
      id: 2,
      hidden: false,
      title: "Client Name",
      dataIndex: "clientName",
      render: (text) => (text ? text : "N/A"),
      sorter: (a, b) => a.clientName?.localeCompare(b.clientName),
      filters: uniqueFilterData(clientNameFilter),
      onFilter: (value, record) => record?.clientName?.includes(value),
    },
    {
      id: 3,
      hidden: false,
      title: "Project Type",
      dataIndex: ["projectType", "translations", "en"],
      sorter: (a, b) => a.projectType?.translations?.en?.localeCompare(b.projectType?.translations?.en),
      render: (text) => (text ? text : "N/A"),
      filters: uniqueFilterData(projectTypeFilter),
      onFilter: (value, record) => record?.projectType?.translations?.en?.includes(value),
    },
    {
      id: 4,
      hidden: false,
      title: "Project Status",
      dataIndex: ["projectStatus", "translations", "en"],
      sorter: (a, b) => a.projectStatus?.translations?.en?.localeCompare(b.projectStatus?.translations?.en),
      render: (text) => (text ? text : "N/A"),
      filters: uniqueFilterData(projectStatusFilter),
      onFilter: (value, record) => record?.projectStatus?.translations?.en?.includes(value),
    },
    {
      id: 8,
      hidden: false,
      title: "Notes",
      dataIndex: "description",
      sorter: (a, b) => a.description?.localeCompare(b.description),
      render: (text) => (
        <Text
          style={{
            width: 200,
          }}
          ellipsis={{ tooltip: { text } }}
        >
          {text ? text : "N/A"}
        </Text>
      ),
    },
    {
      id: 7,
      hidden: false,
      title: "Actions",
      dataIndex: "description",
      width: "80px",
      align: "center",
      fixed: 'right',
      render: (text, row) => (
        <div
          className="cursor-pointer"
          onClick={(e) => {
            e.stopPropagation();
          }}
          role="button"
          tabIndex="0"
          onKeyDown={(e) => {
            e.stopPropagation();
          }}
        >
          <Dropdown
            overlay={
              <Menu
                items={[
                  {
                    key: "1",
                    label: (
                      <div
                        className="d-flex justify-content-left align-items-center table-dropdown"
                        role="button"
                        tabIndex="0"
                        onClick={() =>
                          navigate(
                            PATH.PROJECT_DETAILS.replace(":projectId", row.id)
                          )
                        }
                        onKeyDown={() =>
                          navigate(
                            PATH.PROJECT_DETAILS.replace(":projectId", row.id)
                          )
                        }
                      >
                        <BsFillEyeFill />
                        <h5 className="mb-0 ml-3">View project</h5>
                      </div>
                    ),
                  },
                  {
                    key: "10",
                    label: (
                      <div
                        className="d-flex justify-content-left align-items-center table-dropdown"
                        role="button"
                        tabIndex="0"
                        onClick={() => completeProjectFunction(row)}
                        onKeyDown={() => completeProjectFunction(row)}
                      >
                        <CheckCircleFilled />
                        <h5 className="mb-0 ml-3">Complete Project</h5>
                      </div>
                    ),
                  },
                  {
                    key: "4",
                    label: (
                      <div
                        className="d-flex justify-content-left align-items-center table-dropdown"
                        role="button"
                        tabIndex="0"
                        onClick={() => archiveProjectFunction(row)}
                        onKeyDown={() => archiveProjectFunction(row)}
                      >
                        <MdArchive />
                        <h5 className="mb-0 ml-3">Archive Project</h5>
                      </div>
                    ),
                  },
                  {
                    key: "5",
                    label: (
                      <div className="d-flex justify-content-left align-items-center table-dropdown">
                        <FaShareSquare />
                        <h5 className="mb-0 ml-3">Export Project</h5>
                      </div>
                    ),
                  },
                  {
                    key: "6",
                    label: (
                      <div className="d-flex justify-content-left align-items-center table-dropdown">
                        <FaRegCopy />
                        <h5 className="mb-0 ml-3">Clone Project</h5>
                      </div>
                    ),
                  },
                ]}
              />
            }
            trigger={["click"]}
          >
            <BsThreeDotsVertical />
          </Dropdown>
        </div>
      ),
    },
  ];

  // const [columns, setColumns] = useState(initialColState);

  // const handleReset = () => {
  //   setColumns(initialColState);
  // };

  // const handleChange = (e) => {
  //   if (e.target.checked) {
  //     setColumns((current) =>
  //       current.filter((val) => val.dataIndex !== e.target.value)
  //     );
  //   } else {
  //     setColumns((current) =>
  //       [
  //         ...current,
  //         { id: e.target.id, title: e.target.name, dataIndex: e.target.value },
  //       ].sort((a, b) => a.id - b.id)
  //     );
  //   }
  // };

  // Hide Table Column Dropdown

  // const initialHideTableColumns = (
  //   <Menu
  //     items={[
  //       {
  //         key: 1,
  //         type: "group",
  //         label: (
  //           <div>
  //             <h4 style={{ color: "#000" }}>Hide table columns</h4>
  //           </div>
  //         ),
  //       },
  //       {
  //         key: 3,
  //         label: (
  //           <Checkbox
  //             value="projectName"
  //             name="Project Name"
  //             id={1}
  //             // checked={cheacky.includes("projectName")}
  //             onChange={handleChange}
  //           >
  //             Project Name
  //           </Checkbox>
  //         ),
  //       },
  //       {
  //         key: 4,
  //         label: (
  //           <Checkbox
  //             value="projectManager"
  //             name="Project Manager"
  //             id={2}
  //             // checked={cheacky.includes("projectManager")}
  //             onChange={handleChange}
  //           >
  //             Project Manager
  //           </Checkbox>
  //         ),
  //       },
  //       {
  //         key: 5,
  //         label: (
  //           <Checkbox
  //             value="clientName"
  //             name="Client Name"
  //             id={3}
  //             // checked={cheacky.includes("clientName")}
  //             onChange={handleChange}
  //           >
  //             Client Name
  //           </Checkbox>
  //         ),
  //       },
  //       {
  //         key: 6,
  //         label: (
  //           <Checkbox
  //             value="projectType"
  //             name="Project Type"
  //             id={4}
  //             // checked={cheacky.includes("projectType")}
  //             onChange={handleChange}
  //           >
  //             Project Type
  //           </Checkbox>
  //         ),
  //       },
  //       {
  //         key: 7,
  //         label: (
  //           <Checkbox
  //             value="projectStatus"
  //             name="Project Status"
  //             id={5}
  //             // checked={cheacky.includes("projectStatus")}
  //             onChange={handleChange}
  //           >
  //             Project Status
  //           </Checkbox>
  //         ),
  //       },
  //       {
  //         key: 8,
  //         label: (
  //           <Checkbox
  //             value="description"
  //             name="Notes"
  //             id={5}
  //             // checked={cheacky.includes("description")}
  //             onChange={handleChange}
  //           >
  //             Notes
  //           </Checkbox>
  //         ),
  //       },
  //       {
  //         key: "11",
  //         label: (
  //           <div>
  //             <hr style={{ border: "1px solid #F2F3F5" }} />
  //             <Button className="reset-dropdown-btn" onClick={handleReset}>
  //               Reset
  //             </Button>
  //           </div>
  //         ),
  //       },
  //     ]}
  //   />
  // );

  // const [hideTableColumns, sethideTableColumns] = useState(
  //   initialHideTableColumns
  // );

  const navigateRow = (record) => {
    const path = record; // to avoid es list error
    return {
      onClick: () => {
        navigate(PATH.PROJECT_DETAILS.replace(":projectId", path.id)); // to avoid es lint error
      }, // click row
    };
  };

  function notifyToaster(data, condition) {
    notification.config({
      maxCount: 1,
    });
    if (condition === "success") {
      notification.success({
        message: data,
      });
    } else {
      notification.error({
        message: data,
      });
    }
  }
  const moveToNextDeleteProject = () => {
    closeDeleteUserModal();
    loadProjects()
    setSelectedRowKeys([]);
  };
  const deleteProjects = () => {
    let finalData = [];
    finalData = selectedRowKeys.map((item) => item.id);
    dispatch(
      deleteProject({ finalData, moveToNextDeleteProject, notifyToaster })
    );
  };
  const moveToNextArchived = () => {
    // dispatch(
    //   getAllProjectList({
    //     search: searchValue ? searchValue : "",
    //     projectManagerId: searchProjectManagerIdValue
    //       ? searchProjectManagerIdValue
    //       : null,
    //     ProjectStatusId: searchProjectStatusIdValue
    //       ? searchProjectStatusIdValue
    //       : null,
    //     ProjectTypeId: searchProjectTypeIdValue
    //       ? searchProjectTypeIdValue
    //       : null,
    //     page: projectFilter.pagination.page,
    //     size: projectFilter.pagination.size,
    //   })
    // );
    loadProjects()
  };
  const archiveProjectFunction = (row) => {
    const finalData = row;
    dispatch(archiveProject({ finalData, moveToNextArchived, notifyToaster }));
  };
  const completeProjectFunction = (row) => {
    const finalData = row;
    dispatch(completeProject({ finalData, moveToNextArchived, notifyToaster }));
  };
  const assetDetailUpdate = () => {
    // dispatch(
    //   getAllProjectList({
    //     search: searchValue ? searchValue : "",
    //     projectManagerId: searchProjectManagerIdValue
    //       ? searchProjectManagerIdValue
    //       : null,
    //     ProjectStatusId: searchProjectStatusIdValue
    //       ? searchProjectStatusIdValue
    //       : null,
    //     ProjectTypeId: searchProjectTypeIdValue
    //       ? searchProjectTypeIdValue
    //       : null,
    //     page: projectFilter.pagination.page,
    //     size: projectFilter.pagination.size,
    //   })
    // );
    loadProjects()
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    const lowerCaseValue = value?.toLowerCase() || '';
    // Memoize the filtered results
    // Memoize the filtered results
    const filteredData = projects?.content.filter(item => {
      return ['projectName', 'clientName', 'description'].some(key =>
        String(item[key] || '').toLowerCase().includes(lowerCaseValue)
      ) ||
        String(item.projectType?.translations?.en || '').toLowerCase().includes(lowerCaseValue) ||
        String(item.projectStatus?.translations?.en || '').toLowerCase().includes(lowerCaseValue);
    });
    setProjectsData(filteredData);
  };

  const debouncedResults = useMemo(() => {
    return debounce(handleSearch, 500);
  }, []);

  useEffect(() => { if (projects) setProjectsData(projects.content) }, [projects])

  return (
    <div className="project" key="Projects">
      {/* <Row
        gutter={24}
        className="d-flex justify-content-between align-items-center button-search"
      >
        <Col sm={24} md={7} className="d-flex"> */}
      <div
        className="button-search"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "20px",
          flexWrap: "wrap",
        }}
      >
        {user && (
          <div className="ml-2">
            <Button
              type="default"
              className="add-project-btn d-flex justify-content-between align-items-center new-user"
              onClick={() => setAddUpdateNewProjectModal(true)}
            >
              <span className="project-btn-span">
                <AiOutlinePlus size={24} />
              </span>
              <h5 className="mb-0">Add New Project</h5>
            </Button>
          </div>
        )}
        {/* <div>
            <div className="table-footer-btn d-flex align-items-center ml-2 mt-0">
              <div className="align-items-center bg-white d-flex justify-content-between rounded-sm btn-action-div">
                <Button
                  className="btn-action d-flex justify-content-center align-items-center asset-btn"
                  onClick={onNewProjects}
                >
                  <div
                    className="active-action"
                    style={{
                      backgroundColor: active === true ? "#f2f3f5" : " ",
                    }}
                  >
                    <div
                      className="active-action d-flex align-items-center"
                      style={{
                        Color: active === true ? "#000" : "#eee",
                      }}
                    >
                      <h4 className="mb-0 ml-2 text-black">New</h4>
                    </div>
                  </div>
                </Button>
                <Divider type="vertical" />
                <Button
                  className="btn-action d-flex justify-content-center align-items-center portofolio-btn"
                  onClick={onCompletedProjects}
                >
                  <div className="active-action d-flex align-items-center">
                    <h4 className="mb-0 ml-2 text-black">Completed</h4>
                  </div>
                </Button>
              </div>
            </div>
          </div> */}
        {/* </Col>
        <Col sm={24} md={7} className="w-100 mt-2 mt-sm-2 mt-md-0"> */}
        <div className="d-flex searchbar-project" style={{ flexWrap: "wrap" }}>
          <Input
            size="large"
            style={{ width: "287px" }}
            prefix={
              <FiSearch size={20} style={{ color: "rgba(0, 0, 0, 0.45)" }} />
            }
            onChange={debouncedResults}
            className="mr-2"
            placeholder="Search a project"
          />

          <Tooltip title="Filter" color="#00215b" key="filter">
            <span
              onClick={() => setIsModalOpen(true)}
              className="filter-icon-span mr-2 cursor-pointer"
            >
              {/* <FaFilter /> */}
              <RiFilter2Line size={24} />
            </span>
          </Tooltip>
          {/* <Tooltip title="Archive Projects" color="#00215b" key="#00215b">
            <span
              className="filter-icon-span mr-2 cursor-pointer"
              onClick={() => {
                onArchiveProjects();
              }}
              role="button"
              tabIndex="0"
              onKeyDown={() => {
                onArchiveProjects();
              }}
            >
              <ArchiveImg />
            </span>
          </Tooltip> */}
          {user?.roleId === 3 ||
            user?.roleId === 4 ||
            (location.pathname.startsWith("user-management") ? (
              ""
            ) : (
              <Tooltip title="Archive Projects" color="#00215b" key="archive-project">
                <div>
                  <Button
                    className="filter-icon-btn mr-2 cursor-pointer"
                    onClick={() => setAddUpdateUserModal(true)}
                    disabled={
                      selectedRowKeys?.length === 0 ? true : false //false
                    }
                    role="button"
                    tabIndex="0"
                  >
                    {/* <AiOutlineCopy size={24} /> */}
                    <MdOutlineAssignmentTurnedIn size={24} />
                  </Button>
                </div>
              </Tooltip>
            ))}
          {user?.roleId === 1 ||
            location.pathname.startsWith(`project`) ||
            user?.roleId === 3 ||
            user?.roleId === 4 ? (
            <>
              {selectedRowKeys?.length > 1 ? (
                ""
              ) : (
                <Tooltip title="Edit Project" color="#00215b" key="edit-project">
                  <div>
                    <Button
                      className="filter-icon-btn mr-2 cursor-pointer"
                      onClick={() => {
                        setFlag(true);
                        setAddUpdateNewProjectModal(true);
                      }}
                      disabled={(selectedRowKeys?.length === 0 && selectedRowKeys?.length > 1) ? true : false}
                      role="button"
                      tabIndex="0"
                    >
                      {/* <MdModeEdit size={24} /> */}
                      <RiEditLine size={24} />
                    </Button>
                  </div>
                </Tooltip>
              )}
            </>
          ) : (
            ""
          )}
          <Tooltip title="Delete Project's" color="#00215b" key="delete-project">
            <div>
              <Button
                className="reset-icon-btn mr-2 cursor-pointer"
                // style={{ color: "#FC0606" }}
                onClick={() => setDeleteUserModal(true)}
                disabled={selectedRowKeys?.length === 0 ? "true" : false}
                role="button"
                tabIndex="0"
              >
                <AiOutlineDelete size={24} />
              </Button>
            </div>
          </Tooltip>
          {/* <Dropdown
              overlay={hideTableColumns}
              trigger={["click"]}
              onOpenChange={handleOpenChange}
              open={open}
            >
              <span className="filter-icon-span cursor-pointer">
                <img src={IMAGES.TABLE_ICON} alt="Table Icon" />
              </span>
            </Dropdown> */}
        </div>
      </div >
      {/* </Col>
      </Row> */}
      {/* Data Table */}
      {
        showProject === "newProject" && (
          <>
            <div className="mt-3">
              <DataTable
                columns={initialColState.filter((item) => item.hidden === false)}
                dataSource={projectsData ?? []}
                pagination={{
                  current: currentPage,
                  pageSize: currentPageSize,
                  total: projects?.totalElements,
                  showSizeChanger: false,
                  disabled: false,
                  onChange: (page, pageSize) => {
                    setCurrentPage(page);
                    setCurrentPageSize(pageSize);
                  },
                }}
                className="antd-table-striped"
                onRow={navigateRow}
                loading={isLoading}
                rowSelection={rowSelection}
                rowKey={(record) => record}
                scroll={{
                  x: "1100px",
                  y: "calc(100vh - 372px)",
                }}
              />
            </div>
            {projects?.content?.length > 0 && (
              <>
                <div className="table-count">
                  <h5 className="table-count-content">
                    {/* Page {pageNumbers} of {projects?.totalPages} */}
                    Showing {pageNumbers} to {nextPageNumber} of{" "}
                    {projects?.totalElements} entries
                  </h5>
                </div>
              </>
            )}
          </>
        )
      }
      {
        showProject === "completedProject" && (
          <CompleteProjects
            showCompletedProject={showCompletedProject}
            // sethideTableColumns={sethideTableColumns}
            // columns={columns}
            setShowNewProject={setShowNewProject}
            setAddUpdateNewProjectModal={setAddUpdateNewProjectModal}
            setActive={setActive}
          // projectFilter={projectFilter}
          // searchProjectTypeIdValue={searchProjectTypeIdValue}
          // searchProjectStatusIdValue={searchProjectStatusIdValue}
          // searchProjectManagerIdValue={searchProjectManagerIdValue}
          // searchValue={searchValue}
          />
        )
      }
      {
        showProject === "archiveProject" && (
          <ArchiveProjects
            showArchiveProject={showArchiveProject}
            // sethideTableColumns={sethideTableColumns}
            // columns={columns}
            // projectFilter={projectFilter}
            setShowNewProject={setShowNewProject}
            setAddUpdateNewProjectModal={setAddUpdateNewProjectModal}
            setActive={setActive}
          />
        )
      }

      {
        addUpdateUserModal && (
          <UserList
            visible={addUpdateUserModal}
            handleCancel={closeAddUpdateUserModal}
            selectedRowKeys={selectedRowKeys}
            setSelectedRowKeys={setSelectedRowKeys}
          />
        )
      }
      <SearchForm
        visible={isModalOpen}
        handleCancel={closeModal}
        setIsModalOpen={setIsModalOpen}
        form={form}
        showProject={showProject}
        setShowProject={setShowProject}
      // setSearchProjectManagerIdValue={setSearchProjectManagerIdValue}
      // setSearchProjectTypeIdValue={setSearchProjectTypeIdValue}
      // setSearchProjectStatusIdValue={setSearchProjectStatusIdValue}
      />
      <CreateUpdate
        visible={addUpdateNewProjectModal}
        handleCancel={closeAddUpdateNewProjectModal}
        selectedRowKeys={selectedRowKeys?.[0]}
        isLoaded={isLoading}
        setSelectedRowKeys={setSelectedRowKeys}
        modalHeading="Create New Project"
        flag={flag}
        // projectFilter={projectFilter}
        showProject={showProject}
        setFlag={setFlag}
        assetDetailUpdate={assetDetailUpdate}
        user={user}
      />
      <DeleteUserModal
        visible={deleteUserModal}
        handleCancel={closeDeleteUserModal}
        user="Project"
        onSubmit={deleteProjects}
        isLoaded={isLoading}
      />
    </div >
  );
}

export default Index;
