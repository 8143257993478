import { Card, Col, Input, Row, Slider, Switch } from 'antd';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IMAGES from '../../../../../../../../assets/images';
import { getFormattedDecimalNumber, getFormattedEnglishNumber } from '../../../../../../../../utils/digitHelper';

const VhlAreaLeaseInformation = () => {
    const dispatch = useDispatch();
    const {
        totalLettableArea,
        currentRentSQM,
        currentRentMonthly,
        currentRentYearly,
        occupiedArea,
        leaseDuration,
        option1InYears,
        marketLease,
        marketRentMonthly,
        marketRentSQM,
        marketRentYearly,
        renewalProbability,
        indexationAdjustmentFactor,
        indexationType
    } = useSelector((state) => state.calculation.assetHVLParams);

    const [isPassingRentSwitch, setPassingRentIsSwitch] = useState(false)
    const [isMarketRentSwitch, setMarketRentIsSwitch] = useState(false)

    const setAssetHVLParams = (params) => {
        dispatch({ type: "calculation/setAssetHVLParams", payload: params })
    }

    const passingRentSwitchChange = (checked) => {
        setPassingRentIsSwitch(checked);
    };
    const marketRentSwitchChange = (checked) => {
        setMarketRentIsSwitch(checked);
    };

    const getOccupiedAreaInSQM = (occupiedAreaInPercentage, newTotalLettableArea) => {
        if (newTotalLettableArea) {
            return newTotalLettableArea * occupiedAreaInPercentage / 100
        }
        else {
            return totalLettableArea * occupiedAreaInPercentage / 100
        }

    }


    const setCurrentRentSQM = (rentSQM) => {
        const rentMonthly = getOccupiedAreaInSQM(occupiedArea) * rentSQM;
        const rentYearly = rentMonthly * 12;
        setAssetHVLParams({
            currentRentSQM: rentSQM,
            currentRentMonthly: rentMonthly,
            currentRentYearly: rentYearly
        });
    }

    const setCurrentRentMonthly = (rentMonthly) => {
        const rentSQM = rentMonthly / getOccupiedAreaInSQM(occupiedArea);
        const rentYearly = rentMonthly * 12;
        setAssetHVLParams({
            currentRentSQM: rentSQM,
            currentRentMonthly: rentMonthly,
            currentRentYearly: rentYearly
        });
    }

    const setCurrentRentYearly = (rentYearly) => {
        const rentMonthly = rentYearly / 12;
        const rentSQM = rentMonthly / getOccupiedAreaInSQM(occupiedArea);
        setAssetHVLParams({
            currentRentSQM: rentSQM,
            currentRentMonthly: rentMonthly,
            currentRentYearly: rentYearly
        });
    }

    const setMRentSQM = (mRentSQM) => {
        const mRentMonthly = getOccupiedAreaInSQM(occupiedArea) * mRentSQM;
        const mRentYearly = mRentMonthly * 12;
        setAssetHVLParams({
            marketRentSQM: mRentSQM,
            marketRentMonthly: mRentMonthly,
            marketRentYearly: mRentYearly
        });
    }

    const setMRentMonthly = (mRentMonthly) => {
        const mRentSQM = mRentMonthly / getOccupiedAreaInSQM(occupiedArea);
        const mRentYearly = mRentMonthly * 12;
        setAssetHVLParams({
            marketRentSQM: mRentSQM,
            marketRentMonthly: mRentMonthly,
            marketRentYearly: mRentYearly
        });
    }

    const setMRentYearly = (mRentYearly) => {
        const mRentMonthly = mRentYearly / 12;
        const mRentSQM = mRentMonthly / getOccupiedAreaInSQM(occupiedArea);
        setAssetHVLParams({
            marketRentSQM: mRentSQM,
            marketRentMonthly: mRentMonthly,
            marketRentYearly: mRentYearly
        });
    }

    const setOccupiedArea = (occupiedArea) => {
        const rentMonthly = getOccupiedAreaInSQM(occupiedArea) * currentRentSQM;
        const rentYearly = rentMonthly * 12;
        const mRentMonthly = getOccupiedAreaInSQM(occupiedArea) * marketRentSQM;
        const mRentYearly = mRentMonthly * 12;
        setAssetHVLParams({
            occupiedArea: occupiedArea,
            currentRentMonthly: rentMonthly,
            currentRentYearly: rentYearly,
            marketRentMonthly: mRentMonthly,
            marketRentYearly: mRentYearly
        });
    }

    const setTotalLettAbleArea = (totalLettAbleArea) => {
        console.log(totalLettableArea, 'totalLettableArea')
        const rentMonthly = getOccupiedAreaInSQM(occupiedArea, totalLettAbleArea) * currentRentSQM;
        const rentYearly = rentMonthly * 12;
        const mRentMonthly = getOccupiedAreaInSQM(occupiedArea, totalLettAbleArea) * marketRentSQM;
        const mRentYearly = mRentMonthly * 12;
        setAssetHVLParams({
            totalLettableArea: totalLettAbleArea,
            currentRentMonthly: rentMonthly,
            currentRentYearly: rentYearly,
            marketRentMonthly: mRentMonthly,
            marketRentYearly: mRentYearly
        });
    }

    return (
        <Card bordered={false} className="assets_card">
            <div className="assets_card__header">
                <h3 className="text-black">Area & Lease Information</h3>
            </div>
            <Row gutter={20} className='pt-3'>
                <Col xs={24} md={16}>
                    <div className='area-lease-info' style={{ background: '#FAFAFA', border: '1px solid #C6C6C6', padding: '12px', borderRadius: "12px" }}>
                        <Row gutter={20}>
                            <Col xs={6} className='d-flex flex-column justify-content-center align-items-center' style={{ gap: '6px' }}>
                                <h5 className='mb-0 font-weight-normal' style={{ color: '#01215D' }}>Total Area</h5>
                                <h6 className='mb-0 font-weight-normal' style={{ color: '#797979' }}>SQM</h6>
                                <div style={{ height: "132px" }}>
                                    <Slider vertical step={1000} min={0} max={100000} value={totalLettableArea} onChange={(value) => setTotalLettAbleArea(value)} style={styles.slider} />
                                </div>
                                <Input min={0} max={100000} className="w-100" value={getFormattedEnglishNumber(totalLettableArea)} onChange={(e) => setTotalLettAbleArea(e.target.value)} />
                            </Col>
                            <Col xs={6} className='d-flex flex-column justify-content-center align-items-center' style={{ gap: '6px' }}>
                                <h5 className='mb-0 font-weight-normal' style={{ color: '#01215D' }}>Occupancy</h5>
                                <h6 className='mb-0 font-weight-normal' style={{ color: '#797979' }}>%</h6>
                                <div style={{ height: "132px" }}>
                                    <Slider vertical step={10} min={0} max={100} value={occupiedArea} onChange={(value) => setOccupiedArea(value)} style={styles.slider} />
                                </div>
                                <Input type='number' className="w-100" min={0} max={100} label="%" value={getFormattedDecimalNumber(occupiedArea)} onChange={e => setOccupiedArea(e.target.value)} />
                            </Col>
                            <Col xs={6} className='d-flex flex-column justify-content-center align-items-center' style={{ gap: '6px' }}>
                                <h5 className='mb-0 font-weight-normal' style={{ color: '#01215D' }}>WALT</h5>
                                {/* // lease duration */}
                                <h6 className='mb-0 font-weight-normal' style={{ color: '#797979' }}>Years</h6>
                                <div style={{ height: "132px" }}>
                                    <Slider vertical step={1} min={0} max={30} value={leaseDuration} onChange={(value) => setAssetHVLParams({ leaseDuration: value })} style={styles.slider} />
                                </div>
                                <Input type='number' min={0} max={30} className="w-100" value={getFormattedEnglishNumber(leaseDuration)} onChange={(e) => setAssetHVLParams({ leaseDuration: e.target.value })} />
                            </Col>
                            <Col xs={6} className='d-flex flex-column justify-content-center align-items-center' style={{ gap: '6px' }}>
                                {/* // lease duration extension */}
                                <h5 className='mb-0 font-weight-normal' style={{ color: '#01215D' }}>Prolongation</h5>
                                <h6 className='mb-0 font-weight-normal' style={{ color: '#797979' }}>Years</h6>
                                <div style={{ height: "132px" }}>
                                    <Slider
                                        vertical min={0} max={30}
                                        value={option1InYears}
                                        step={1}
                                        style={styles.slider}
                                        onChange={(value) => setAssetHVLParams({ option1InYears: value })}
                                    />
                                </div>
                                <Input type='number' min={0} max={30} className="w-100" value={getFormattedEnglishNumber(option1InYears)} onChange={(e) => setAssetHVLParams({ option1InYears: e.target.value })} />
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col xs={24} md={8}>
                    <div style={{ background: '#FAFAFA', border: '1px solid #C6C6C6', padding: '12px', borderRadius: "12px" }}>
                        <div>
                            <h4 className='text-black text-center mb-0'>Passing Rent</h4>
                            <div className='d-flex align-items-center justify-content-center' style={{ gap: '2px', paddingBlock: '12px' }}>
                                <h5 className='mb-0'>Per Year</h5>
                                <Switch size="small"
                                    checked={isPassingRentSwitch}
                                    onChange={passingRentSwitchChange}
                                />
                                <h5 className='mb-0'>Per sqm</h5>
                            </div>
                            <Input addonAfter={<img src={IMAGES.POUND} />} value={isPassingRentSwitch ? getFormattedEnglishNumber(currentRentSQM) : getFormattedEnglishNumber(currentRentYearly)}
                                onChange={e => isMarketRentSwitch ? setCurrentRentSQM(e.target.value) : setCurrentRentYearly(e.target.value)} />
                        </div>
                        <div>
                            <h4 className='text-black text-center mb-0 pt-1'>Market Rent</h4>
                            <div className='d-flex align-items-center justify-content-center' style={{ gap: '2px', paddingBlock: '12px' }}>
                                <h5 className='mb-0'>Per Year</h5>
                                <Switch size="small"
                                    checked={isMarketRentSwitch}
                                    onChange={marketRentSwitchChange}
                                />
                                <h5 className='mb-0'>Per sqm</h5>
                            </div>
                            <Input addonAfter={<img src={IMAGES.POUND} />} value={isMarketRentSwitch ? getFormattedEnglishNumber(marketRentSQM) : getFormattedEnglishNumber(marketRentYearly)}
                                onChange={e => isMarketRentSwitch ? setMRentSQM(e.target.value) : setMRentYearly(e.target.value)}
                            />
                        </div>
                    </div>
                </Col>
            </Row>
        </Card>
    )
}

export default VhlAreaLeaseInformation


const styles = {
    slider: {
        height: '95%',
        "& .ant-slider-rail": {
            width: '10% !important',
            background: '#D8D8D8',
            borderRadius: '20px'
        },
        "& .ant-slider-track": {
            width: '10%',
            background: '#01215D',
            borderRadius: '20px'
        },
        "& .ant-slider-step": {
            width: '10%'
        },
        "& .ant-slider-handle": {
            marginLeft: '-5px',
            width: '20px',
            height: '12px',
            border: '1.45px solid #01215D'
        },
    }
}